import mailchimpLogo from 'assets/mailchimp_logo.png'
import klaviyoLogo from 'assets/klaviyo_logo.png'
import emarsysLogo from 'assets/emarsys_logo.png'
import shopifyLogo from 'assets/shopify_logo.png'
import checkIcon from 'assets/green_check_icon.png'
import GALogo from 'assets/ga_logo.png'
import { IntegrationMetas } from 'data/IntegrationMetas';
import { ActivationLogosSection } from './ActivationLogosSection';
import Button from 'components/Button'
import { Transition } from '@headlessui/react'

export const ActivationSuccessContainer = (props: any) => {

    const { integrationName, features } = props;

    const getLogo = () => {
        if (integrationName === IntegrationMetas.Mailchimp.name) {
            return mailchimpLogo
        } else if (integrationName === IntegrationMetas.Klaviyo.name) {
            return klaviyoLogo
        } else if (integrationName === IntegrationMetas.Emarsys.name) {
            return emarsysLogo
        } else if (integrationName === IntegrationMetas.Shopify.name) {
            return shopifyLogo
        } else if (integrationName === IntegrationMetas.GoogleAnalytics.name) {
            return GALogo
        } else {
            return ""
        }
    }

    return (
        <Transition.Root show={true} appear={true}>
            <div className="h-screen w-screen flex items-center justify-center bg-whiteSmoke">
                <Transition
                    show={true}
                    appear={true}
                    as="div"
                    enterFrom="opacity-0 "
                    enterTo="opacity-100 "
                    enter="transition-all duration-[700ms] ease-out"
                    className={"min-h-[80%] min-w-min h-[80%] w-[50rem]"}
                >
                <div className="h-full w-full bg-white rounded-default border border-granitine p-16 justify-center drop-shadow-xl relative">
                    <div className='flex flex-col p-8 h-full'>
                        <div className='flex items-center justify-center'>
                            <h2 className='text-lg font-medium font-bold self-center '>Success</h2>
                            <img className="w-10 h-10 object-contain ml-2" src={checkIcon} />
                        </div>
                        <br />
                        <hr className="border border-gray" />
                        <br />
                        <br />
                        <ActivationLogosSection integrationLogo={getLogo()} />
                        <br />
                        <br />
                        <hr className="border border-gray" />
                        <br />
                        <br />
                        <h3 className='font-bold text-base'>Unlocked features</h3>
                        <br />
                        <ul>
                            {features.map((feature: string) => (
                                <li className='flex items-center h-8'>
                                    <img className="w-6 h-6 object-contain" src={checkIcon} />
                                    <p className='pl-2 text-base'>{feature}</p>
                                </li>
                            ))}
                        </ul>
                        <br />
                        <br />
                        <div className='absolute bottom-24 left-0 right-0 flex justify-center'>
                            <Button text="Ok" bgColor="bg-decoGray" textColor="white" style="px-8 h-12" handleClick={() => { window.close() }} />
                        </div>
                    </div>
                </div>
            </Transition>
        </div >
        </Transition.Root>
    );
};