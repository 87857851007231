import mailchimpLogo from 'assets/mailchimp_logo.png'
import klaviyoLogo from 'assets/klaviyo_logo.png'
import emarsysLogo from 'assets/emarsys_logo.png'
import shopifyLogo from 'assets/shopify_logo.png'
import { IntegrationMetas } from 'data/IntegrationMetas';
import { ActivationLogosSection } from './ActivationLogosSection';
import { KlaviyoActivationForm } from './KlaviyoActivationForm';
import { EmarsysActivationForm } from './EmarsysActivationForm';
import { ShopifyActivationForm } from './ShopifyActivationForm';

export const ActivationForm = (props: any) => {

    const { integrationName, integrationKey, onStatusChanged } = props;

    const getLogo = () => {
        if (integrationName === IntegrationMetas.Mailchimp.name) {
            return mailchimpLogo
        } else if (integrationName === IntegrationMetas.Klaviyo.name) {
            return klaviyoLogo
        } else if (integrationName === IntegrationMetas.Emarsys.name) {
            return emarsysLogo
        } else if (integrationName === IntegrationMetas.Shopify.name) {
            return shopifyLogo
        } else {
            return ""
        }
    }

    const getActivationForm = () => {

        if (integrationName === IntegrationMetas.Klaviyo.name) {
            return <KlaviyoActivationForm onStatusChanged={onStatusChanged} integrationKey={integrationKey}/>;
        } else if (integrationName === IntegrationMetas.Emarsys.name) {
            return <EmarsysActivationForm onStatusChanged={onStatusChanged} integrationKey={integrationKey} />;
        } else if (integrationName === IntegrationMetas.Shopify.name) {
            return <ShopifyActivationForm onStatusChanged={onStatusChanged} integrationKey={integrationKey}/>;
        } else {
            return null;
        }
    }

    return (
        <>
            <div className="h-screen w-screen flex items-center justify-center bg-whiteSmoke">
                <div className="min-h-[80%] min-w-min w-[50rem] bg-white rounded-default border border-granitine p-16 justify-center drop-shadow-xl">
                    <div className='flex flex-col p-8'>
                        <p className='text-lg font-medium font-bold self-center text-tamahagane'>Activate integration</p>
                        <br />
                        <br />
                        <ActivationLogosSection integrationLogo={getLogo()} />
                        <br />
                        <br />
                        {getActivationForm()}
                    </div>
                </div>
            </div >
        </>
    );
};