import { IntegrationMetas } from "data/IntegrationMetas";
import { IntegrationListItem } from "./IntegrationListItem";
import { DotsLoader } from "../../components/DotsLoader";
import klaviyoLogo from 'assets/klaviyo_logo.png'

export const IntegrationList = (props: any) => {

    const { searchText, selectedFilters, integrations, loading } = props;

    const getIntegrationType = (integration: IntegrationMeta) => {
        if (integration.name === IntegrationMetas.Mailchimp.name) {
            return IntegrationMetas.Mailchimp.type
        } else if (integration.name === IntegrationMetas.Klaviyo.name) {
            return IntegrationMetas.Klaviyo.type
        } else if (integration.name === IntegrationMetas.Emarsys.name) {
            return IntegrationMetas.Emarsys.type
        } else if (integration.name === IntegrationMetas.Shopify.name) {
            return IntegrationMetas.Shopify.type
        } else {
            return ""
        }
    }

    const getMatchingIntegrations = () => {

        if ((!searchText || searchText.trim() === "") && (!selectedFilters || selectedFilters.length === 0)) {
            return integrations;
        }

        if (selectedFilters.length > 0) {
            const filteredIntegrations = (integrations as IntegrationMeta[]).filter(integration =>
                selectedFilters.some((filter: String) =>
                    getIntegrationType(integration) === filter
                )
            );
            return filteredIntegrations.filter((integration) =>
                integration.name.toLowerCase().includes(searchText.toLowerCase())
            );
        } else {
            return (integrations as IntegrationMeta[]).filter((integration) =>
                integration.name.toLowerCase().includes(searchText.toLowerCase())
            );
        }

    };

    return (
        <div className="w-full bg-white rounded-default border border-granitine pl-16 pr-16 pt-12 pb-12 divide-y divide-gray">
            {!loading && (getMatchingIntegrations() as IntegrationMeta[]).map((integration: IntegrationMeta) => {
                if (integration.name === "Klaviyo") {
                    integration.image = klaviyoLogo
                }
                return (<IntegrationListItem integration={integration} />)
            })}
            {loading && <div className="flex justify-center items-center">
                <DotsLoader />
            </div>}

        </div>
    );
};