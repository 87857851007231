import { useContext } from "react";

import MessageContext from "store/message-context";
import CampaignContext from "store/campaign-context";
import AlertContext from "store/alert-context";

import Button from "./Button"
import { getCookie } from "helpers";
import { useSetTargetLink } from "routes/CampaignRoute/sections/section_5/helpers";
import DispatchContext from "../store/dispatch-context";
import {useNavigate} from "react-router-dom";
import {useCampaignTime} from "../store/campaign-time-context";

function SendCampaignBtn(props: any) {
    const messageCtx = useContext(MessageContext);
    const campaignCtx = useContext(CampaignContext);
    const alertCtx = useContext(AlertContext);
    const dispatchCtx = useContext(DispatchContext);
    const {date, dayAndTimeString, utcString} = useCampaignTime();
    const navigate = useNavigate();

    const { bgColor, text, textColor, disabled } = props;

    const sendCampaignClick = async () => {
        let message = messageCtx.message;
        let groupIds = Object.keys(campaignCtx.groupsIds).map(Number);
        let tmpBlock = Object.keys(campaignCtx.tmpBlock).map(Number);

        const token = getCookie("token");

        let errorMessage: string = "";
        let alertType: AlertType = "NO_NAME";
        
        if (!campaignCtx.name) {
            errorMessage = "The campaign must have a name."
            alertType = "NO_NAME";
        }
        else if (!campaignCtx.from) {
            errorMessage = "The campaign must have a sender."
            alertType = "NO_SENDER";
        }
        else if (!groupIds.length) {
            errorMessage = "The campaign must have at least one recipient."
            alertType = "NO_RECIPIENT";
        }
        else if (message.includes('%link%') && !messageCtx.link.number) {
            errorMessage = "No link selected"
            alertType = "NO_CONNECTED_LINK";
        }

        if (errorMessage.length) {
            alertCtx.addAlert("Error", errorMessage, "cruelRuby", alertType)
            return;
        }

        // Sanity check, make sure the date is at least 15 minutes in the future
        if (date < new Date((new Date()).getTime() + (15 * 60000))) {
            throw new Error(`Sanity check failed, invalid date ${date}`)
        }
        
        let dateString = campaignCtx.sendNow? "NOW" : utcString;

        let resultJson: any;

        let groupSizes: any = {};
        groupIds.forEach(id => {
            groupSizes[id] = campaignCtx.groupsIds[id].size;
        });

        let postBody: any = {
            name: campaignCtx.name,
            text: message,
            date: dateString,
            sender: campaignCtx.from,
            group_sizes: groupSizes,
            group_ids: groupIds,
            tmp_blocklist: tmpBlock
        };

        if (campaignCtx.useBatching) {
            postBody["splits"] = campaignCtx.batchCount | 0; // Parse to int
            postBody["split_delay"] = campaignCtx.batchDelay | 0; // Parse to int
        }

        if (messageCtx.link.hasOwnProperty("number") && !isNaN(messageCtx.link.number )) {
            postBody["shortlinkNumber"] = messageCtx.link.number;
            postBody["utm_tagged"] = messageCtx.link.utm_tagged;
        }

        try {
            resultJson = await fetch(process.env.REACT_APP_API_BASE + "/dispatches", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postBody)
            });

        } catch (error) {
            console.log("Error sending dispatch: ", error);
        }

        let res;
        try {
            res = await resultJson.json();
            if ("error" in res) {
                alertCtx.addAlert("Error", res.message, "cruelRuby", "NO_NAME")
            } else {
                if (campaignCtx.sendNow) {
                    alertCtx.addAlert("Campaign created", `The campaign is being sent now.`, "darkAqua", "CAMPAIGN_SUCCESS")
                } else {
                    alertCtx.addAlert("Campaign created", `The campaign will be sent at ${dayAndTimeString}`, "darkAqua", "CAMPAIGN_SUCCESS")

                }
                campaignCtx.resetState();
                messageCtx.resetState();
                dispatchCtx.refreshLatest();
                navigate("/campaign-dispatches");
            }
        } catch (error) {
            console.log("Error parsing json");
        }
    }

    const handleClick = () => {
        sendCampaignClick();
        props.handleClose();
    }

    return (
        <Button text={text} bgColor={bgColor} textColor={textColor} disabled={disabled} handleClick={handleClick}></Button>
    )
}

export default SendCampaignBtn;