export const IntegrationTypes = {
  Email: {
    key: "email",
    name: "Email"
  },
  CRM: {
    key: "crm",
    name: "CRM"
  },
  Ecommerce: {
    key: "ecommerce",
    name: "Ecommerce"
  }
};
