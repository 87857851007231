import klaviyoLogo from 'assets/klaviyo_logo.png'


export const integrationsList: IntegrationMeta[] = [
    {
        active: false,
        name: "Klaviyo",
        "auth-url": "",
        connections: [],
        "description-en": "Create SMS automation magic! Sync your Klaviyo data with Bogio to create automated campaigns for your segments & lists.",
        features: [],
        image: klaviyoLogo,
        notActivatable: true
    },
    {
        active: false,
        name: "Mailchimp",
        "auth-url": "",
        connections: [],
        "description-en": "Improve your reach! Seamlessly integrate Mailchimp with Bogio to automate and combine your SMS and email campaigns.",
        features: [],
        image: "https://segint-mailchimp.jojk.me/static/images/Mailchimp_Logo-Vertical_Black.png",
        notActivatable: true
    },
    {
        active: false,
        name: "Emarsys",
        "auth-url": "",
        connections: [],
        "description-en": "Elevate your segmentation game! Connect Bogio with Emarsys for advanced, laser-targeted SMS campaigns.",
        features: [],
        image: "https://segint-emarsys.jojk.me/static/images/ema_logo_2018_stacked_color_RGB.png",
        notActivatable: true
    },
    {
        active: false,
        name: "Shopify",
        "auth-url": "",
        connections: [],
        "description-en": "Create relations with your customers! Sync your Shopify data for hyper-personalized SMS marketing that drives growth.",
        features: [],
        image: "https://segint-dummy.jojk.me/shopify/shopify_logo_black.png",
        notActivatable: true
    },
    {
        active: false,
        name: "Google Analytics",
        "auth-url": "",
        connections: [],
        "description-en": "Connect your Google Analytics account to see revenue and purchase data for your SMS campaigns.",
        features: [],
        image: "https://segint-ga.jojk.me/static/images/ga_logo.png",
        notActivatable: true
    }
      
]