import { useContext, useEffect, useState } from "react";
import { IntegrationMetas } from "data/IntegrationMetas";
import { IntegrationTypes } from "data/IntegrationTypes";
import FilterDropdown from "../../components/FilterDropdown";
import { IntegrationList } from "./IntegrationList";
import AccountContext from "store/account-context";
import { header } from "helpers";
import { integrationsList } from "./integrationInfo";


const IntegrationContainer = () => {

    const [searchText, setSearchText] = useState<String>("");
    const [selectedFilters, setSelectedFilters] = useState<String[]>([]);
    const [integrations, setIntegrations] = useState<IntegrationMeta[]>([]);
    const accountCtx = useContext(AccountContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function fetchData() {
            const fetchWithTimeout = (url: string, options?: RequestInit, timeout: number = 10000): Promise<Response> => {
                return new Promise(async (resolve, reject) => {
                    const timer = setTimeout(() => {
                        reject(new Error('Request timed out'));
                    }, timeout);

                    try {
                        const response = await fetch(url, options);
                        clearTimeout(timer);

                        if (!response.ok) {
                            reject(new Error(`Failed to fetch for ${url}`));
                        }

                        resolve(response);
                    } catch (error) {
                        clearTimeout(timer);
                        reject(error);
                    }
                });
            };

            setLoading(true)
            try {
                const responses = await Promise.allSettled(accountCtx.integrations.map((integration: IntegrationItem) => {
                    if (integration.integration_id === IntegrationMetas.Shopify.id) {
                        return fetchWithTimeout(IntegrationMetas.Shopify.metaUrl, header(integration.key))
                    } else if (integration.integration_id === IntegrationMetas.GoogleAnalytics.id) {
                        console.log("Integration: ", integration)
                        return fetchWithTimeout(IntegrationMetas.GoogleAnalytics.metaUrl, header(integration.key))
                    } else {
                        return fetchWithTimeout("https://segint-" + integration.integration_id + ".jojk.me/meta", header(integration.key))
                    }
                }))
                const successful = responses.filter(r => r.status === 'fulfilled').map(r => (r as PromiseFulfilledResult<Response>).value);

                if (successful.length > 0) {
                    const data = await Promise.all(successful.map((res) => res.json()));
                    const fieldsToUpdate = ["auth-url", "active", "features", "connections", "pending_connections", "image"]
                    const integrationData = integrationsList.map((existingIntData) => {
                        const existingInt: any = data.find(newIntData => newIntData.name === existingIntData.name); // Find the corresponding object in Array B
                        if (existingInt) {
                            const updatedFields: any = {};
                            fieldsToUpdate.forEach((field: any) => {
                                if (existingInt[field] !== undefined) {
                                    updatedFields[field] = existingInt[field];
                                }
                            });
                            return { ...existingIntData, ...updatedFields, notActivatable: false };
                        }
                        return existingIntData; 
                    });
                    setIntegrations(integrationData);
                }
                setLoading(false);
            } catch (error) {
            }

        }

        fetchData();

    }, []);

    const onFiltersSelected = (newFilterName: String) => {

        const index = selectedFilters.findIndex((selectedFilter) => selectedFilter === newFilterName);

        if (index !== -1) {
            setSelectedFilters([...selectedFilters.slice(0, index), ...selectedFilters.slice(index + 1)])
        } else {
            setSelectedFilters([...selectedFilters, newFilterName])
        }
    }

    return (
        <>
            <div className="flex gap-8 pb-8">
                <div
                    className="bg-white flex items-center w-[12rem] h-full border border-granitine rounded-default pr-3">
                    <input type="text" placeholder="Search" onChange={(e: any) => setSearchText(e.target.value)}
                        className="border-0 focus:ring-0 apperance-none outline-0 text-base py-3 px-3 w-full rounded-default h-12 placeholder:text-slate-400" />
                </div>
                <div className='h-full w-[12rem] bg-white'>
                    <FilterDropdown
                        items={Object.values(IntegrationTypes).map((item: any, i: number) => ({
                            id: i,
                            title: item.name,
                            value: item.name
                        }))}
                        onFiltersSelected={(item: String) => onFiltersSelected(item)}
                        multipleSelection={true} />
                </div>
            </div>
            <IntegrationList searchText={searchText} selectedFilters={selectedFilters} integrations={
                integrations.length === 0 && !loading ?
                    integrationsList :
                    integrations
            }
                loading={loading} />
        </>
    )
}

export default IntegrationContainer;