import React from "react";
import { DateObject } from ".";

interface Day {
    day: number;
    month: number;
    year: number;
}

interface Month {
    month: number;
    year: number;
    days: Day[];
}

export type UpdateRangeFn = ({ startDate, endDate } : { startDate: DateObject | null, endDate: DateObject | null}) => void

export type MonthRefs = { [key: string]: React.RefObject<HTMLDivElement> };

export function generateDateObjects(monthRefs: MonthRefs): Month[] {
    const startDate = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000); // Start 180 days ago
    const currentDate = new Date(); // End at current date

    let currentMonth = startDate.getMonth();
    let currentYear = startDate.getFullYear();

    const dateObjects: Month[] = [];

    while (currentYear < currentDate.getFullYear() ||
        (currentYear === currentDate.getFullYear() && currentMonth <= currentDate.getMonth())) {

        const monthKey = `${currentYear}-${(currentMonth + 1) < 10 ? '0' : ''}${(currentMonth + 1)}`

        monthRefs[monthKey] = React.createRef()
        // Get number of days in the current month
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

        const monthObject: Month = {
            month: currentMonth + 1, // months are 0-indexed in JavaScript Date
            year: currentYear,
            days: []
        };

        // Get the day of the week for the first day of the month (0 - Sunday, 1 - Monday, etc.)
        const firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay() -1;

        // If the first day of the month is not Sunday
        if (firstDayOfWeek !== 0) {
            // Get the number of days in the previous month
            const daysInPreviousMonth = new Date(currentYear, currentMonth, 0).getDate();

            // Get the date for the first offset day in the previous month
            const firstOffsetDay = daysInPreviousMonth - firstDayOfWeek + 1;

            // Add the offset days from the previous month
            for (let i = firstOffsetDay; i <= daysInPreviousMonth; i++) {
                monthObject.days.push({ day: i, month: currentMonth === 0 ? 12 : currentMonth, year: currentMonth === 0 ? currentYear - 1 : currentYear });
            }
        }

        // Add the days for the current month
        for (let i = 1; i <= daysInMonth; i++) {
            monthObject.days.push({ day: i, month: currentMonth + 1, year: currentYear });
        }

        // Get the day of the week for the last day of the month (0 - Sunday, 1 - Monday, etc.)
        const lastDayOfWeek = new Date(currentYear, currentMonth + 1, 0).getDay();

        // If the last day of the month is not Saturday
        if (lastDayOfWeek !== 6) {
            // Add the offset days from the next month
            for (let i = 1; i <= 6 - lastDayOfWeek; i++) {
                monthObject.days.push({ day: i, month: currentMonth === 11 ? 1 : currentMonth + 2, year: currentMonth === 11 ? currentYear + 1 : currentYear });
            }
        }

        dateObjects.push(monthObject);

        // Go to next month
        if (currentMonth < 11) {
            currentMonth++;
        } else {
            currentMonth = 0;
            currentYear++;
        }
    }

    return dateObjects;
}

