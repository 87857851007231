import React from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';

export type GraphDataPoint = {
    date: string,
    data: number
}

export type StatGraphProps = {
    type: "linechart",
    yDataName: string,
    yDataUnit: string,
    data: Array<GraphDataPoint>,
}

const StatGraph = (props: StatGraphProps) => {

    return (
        <ResponsiveContainer
            width={"100%"}
            height={400}
        >
            <ComposedChart
                width={500}
                height={400}
                data={props.data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="category" dataKey="date" name='date' tickFormatter={(str) => {
                    const date = new Date(str);
                    return date.toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: "numeric"  });
                }} />
                <YAxis type="number" dataKey='data' name={props.yDataName} >
                    <Label fontWeight={'bold'} fontSize={14} fill={"#2B303B"} position="insideLeft" angle={-90}>Total</Label>
                </YAxis>
                <Tooltip formatter={(value, name) => [`${value.toString()} ${props.yDataUnit}`]} />
                <Line dataKey='data' stroke="#2B303B" />
            </ComposedChart>
        </ResponsiveContainer>
    )

}

export default StatGraph;