import { useState } from "react";
import { Dropdown, DropdownBody, DropdownItem } from "dropdown"

type DropdownItem = {
    id: number,
    title: string,
    value: string
}

export interface DropdownProps {
    setSelection: Function,
    items: DropdownItem[],
    disabled?: boolean
    loading?: boolean,
    bodyStyles?: string
    headerStyles?: string
}

const CountrycodeDropdown = (props: DropdownProps) => {
    const [selected, setSelected] = useState({} as DropdownItem)

    const selectClick = (newSelection: DropdownItem) => {
        if (newSelection.value === selected.value) {
            setSelected({} as DropdownItem);
            props.setSelection({})
        } else {
            setSelected(newSelection);
            props.setSelection(newSelection)
        }
    }

    return (
        <Dropdown
            placeholder={"Choose list"}
            renderBody={(dropdownSettings: any) =>
                <DropdownBody
                    renderItem={(bodySettings: any) =>
                        <DropdownItem
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={bodySettings.title === selected.title}
                            handleClick={() => {
                                selectClick(bodySettings)
                                dropdownSettings.handleClose()
                            }} />
                    }
                    data={props.items}
                    handleClose={dropdownSettings.handleClose}
                    bodyStyles={props.bodyStyles}
                />
            }
            headerButtonStyles={props.headerStyles}
            selected={selected.title}
            disabled={props.disabled}
            loading={props.loading}
        />
    )


}
export default CountrycodeDropdown