import { createContext, useState } from 'react';

const TemplateContext = createContext({
    templates: [] as Array<object>,
    updateTemplates: (newTemplates: Array<object>) => { }
});

export function TemplateContextProvider(props: any) {
    const [templates, setTemplates] = useState([] as Array<object>);

    const updateTemplates = (newTemplates: Array<object>) => {
        setTemplates(newTemplates);
    }

    const context = {
        templates,
        updateTemplates,
    };
    return (
        <TemplateContext.Provider value={context}>
            {props.children}
        </TemplateContext.Provider>
    )
}

export default TemplateContext;