import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronRight, BsMapFill } from "react-icons/bs";
import NewDropdown from "components/NewDropdown";
import { Dropdown, DropdownBody, DropdownItem } from "dropdown"
import SearchableDropdownBody from "dropdown/SearchableDropdownBody";
import { useGroupData } from "store/group-context";

type GroupItem = {
    id: number,
    title: string,
    value: string
}

export type CountrycodeDropdownProps = {
    setSelection: Function,
    disabled: boolean,
}

const GroupDropdown = (props: CountrycodeDropdownProps) => {
    const [selected, setSelected] = useState({} as GroupItem)
    const [groupItems, setGroupItems] = useState([] as Array<GroupItem>)

    const {groups: groupsData} = useGroupData();


    const selectClick = (newSelection: GroupItem) => {
        if (newSelection.value === selected.value) {
            setSelected({} as GroupItem);
        } else {
            setSelected(newSelection);
        }
    }

    useEffect(() => {
        if (groupsData) setGroupItems(groupsData.map((group: any) => ({id: group.id, title: `${group.id}. ${group.name}`, value: group})))
    }, [groupsData])

    return (
        <Dropdown
            disabled={props.disabled}
            placeholder={"Choose group"}
            bodyEaseIn={false}
            renderBody={(dropdownSettings: any) =>
                <SearchableDropdownBody
                    renderItem={(bodySettings: any) =>
                        <DropdownItem
                            key={bodySettings.id}
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={bodySettings.title === selected.title}
                            handleClick={() => {
                                selectClick(bodySettings)
                                props.setSelection(bodySettings.value)
                                dropdownSettings.handleClose()
                            }} />
                    }
                    data={groupItems}
                    bodyDirection="left"
                    searchPlaceholder="Search for group"
                    maxHeight="max-h-48"
                />
            }
            selected={selected.title}
            headerButtonStyles={"justify-between border-gunMetal/20 bg-white rounded-md"}
            textStyles={"p-2 truncate text-ellipsis text-left"}
        />
    )


}
export default GroupDropdown;