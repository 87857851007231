import { createContext, useContext, useState } from 'react';

import AlertContext from './alert-context';

type Link = {
    "active": boolean,
    "target_url": string,
    "short_domain": string,
    "short_code": string,
    "number": number,
    "clicks": number,
    "text": string, // Not from the server
    "utm_tagged": boolean, // Not from the server
}
const initialState = {
    message: "",
    link: {} as Link,
    parts: 0,
    reset: false,
    updateMessage: (newMessage: string, parts: number) => { },
    updateLink: (newLink: Link) => { },
    resetState: () => { }
}

const MessageContext = createContext({
    ...initialState
});

export function MessageContextProvider(props: any) {
    const [message, setMessage] = useState("");
    const [parts, setParts] = useState(0);
    const [link, setLink] = useState({} as Link);
    const [reset, setReset] = useState(false);

    const alertCtx = useContext(AlertContext);

    const updateLink = (newLink: Link) => {
        setLink(newLink);
    }

    const updateMessage = (newMessage: string, parts: number) => {
        if (parts) {
            alertCtx.discardAlert("NO_MESSAGE");
        }
        setMessage(newMessage);
        setParts(parts);
    }

    const resetState = () => {
        setMessage(initialState.message);
        setParts(initialState.parts);
        setLink(initialState.link);
        setReset(false);
    }

    const context = {
        message,
        link,
        parts,
        reset,
        updateMessage,
        updateLink,
        setParts,
        resetState
    };
    return (
        <MessageContext.Provider value={context}>
            {props.children}
        </MessageContext.Provider>
    )
}

export default MessageContext;