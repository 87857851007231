import activateLogo from 'assets/activate_logo.png';
import bogioLogo from 'bogio_logo.png';

export const ActivationLogosSection = (props: any) => {

    const { integrationLogo } = props;

    return <div className="flex flex-row justify-center items-center">
        <div className="w-40 h-40 border border-gray flex justify-center items-center rounded-md">
            <img className="object-contain" src={bogioLogo} />
        </div>
        <hr className="w-20 border border-gray" />
        <div className="w-40 h-40 p-4 border border-gray flex justify-center items-center rounded-md">
            <img className="object-contain" src={integrationLogo} />
        </div>
    </div>;
};
