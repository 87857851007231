import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "components/Button";
import AccountContext from "store/account-context"

import HeaderBar from "components/HeaderBar";
import MergeModal from "./MergeModal";
import CreateModal from "./CreateModal";
import TableRow from "./TableRow";
import { Dropdown, DropdownBody, DropdownItem } from "dropdown"

import {demoRows, groupTypes} from "./helpers";
import { useGroupAPI, useGroupData } from "store/group-context";
import { useImportAPI, useImportData } from "store/import-context";
import AlertContext from "store/alert-context";
import { getCookie, header, useDelete } from "helpers";
import RemovedAlert from "./RemovedAlert";
import { SimpleDropdown, SingleItem } from "components/SimpleDropdown";

const _idToTimestamp = (_id: any) => {
    if (_id.substring) {
        return parseInt(_id.substring(0, 8), 16)
    } else {
        return parseInt(_id.toString().substring(0, 8), 16)
    }
}

const groupSort = (a: any, b: any) => {
    return _idToTimestamp(b._id) - _idToTimestamp(a._id)
}


type SyncGroup = {
    "group_id": string,
    "group_number": number,
    "sync": SyncObject
}
const GroupRoute = () => {
    const accountCtx = useContext(AccountContext);
    const alertCtx = useContext(AlertContext);

    //const { data: { groups } = [] }: any = useData('/groups?per_page=50');
    const groupsData: any = useGroupData();
    const groupAPICtx = useGroupAPI();
    const importData: any = useImportData();
    const importAPI: any = useImportAPI();

    const token = getCookie("token");
    const deleteData = useDelete(token);

    const [mergeOpen, setMergeOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [mergeGroups, setMergeGroups] = useState([] as any);
    const [sourceFilter, setSourceFilter] = useState("");
    const [sourceTypeFilter, setSourceTypeFilter] = useState("");
    const [nameFilter, setNameFilter] = useState("");
    const [filteredGroupsList, setFilteredGroupsList] = useState([]);
    const [fetchImports, setFetchImports] = useState(false);
    const [groupsList, setGroupsList] = useState([] as any)
    const [loading, setLoading] = useState(true);

    const timeouts = useRef({} as any);
    const [alerts, setAlerts] = useState([] as any);

    const tableBodyRef = useRef(null as any);

    const sourceTypes = (() => {
        let types = [
            { id: 0, title: "Bogio", value: "bogio" }
        ]
        accountCtx.integrations.forEach((x: any) => {
            switch (x.integration_id) {
                case "klaviyo":
                    types.push({ id: 1, title: "Klaviyo", value: "klaviyo" });
                    break;
                case "shopify":
                    types.push({ id: 2, title: "Shopify", value: "shopify" });
                    break;
                case "mailchimp":
                    types.push({ id: 3, title: "Mailchimp", value: "mailchimp" });
                    break;
                case "infobaleen":
                    types.push({ id: 4, title: "Infobaleen", value: "infobaleen" });
                    break;
                case "emarsys":
                    types.push({ id: 5, title: "Emarsys", value: "emarsys" });
                    break;
            }
        })
        return types;
    })();

    useEffect(() => {
        async function fetchAndMerge(integration: any) {
            let initialGroups = [...groupsData.groups]
            // Fetch groups that are syncing from API B
            const response = await fetch(process.env.REACT_APP_KLAVIYO_URL + "/groups", header(integration.key));
            if (response.ok) {
                const syncingGroups = await response.json();
    
                // Merge data from both APIs
                initialGroups.forEach((initialGroup: GroupElement) => {
                    const match = syncingGroups.find((syncGroup: SyncGroup) => syncGroup.group_number === initialGroup.id);
                    if (match) {
                        initialGroup.sync = match.sync;
                    }
                });
            } else {
                console.log("Couldn't fetch Klaviyo groups", response);
            }
            setGroupsList(initialGroups.sort(groupSort));
        }

        if (!groupsData.loading) {
            const klaviyoIntegration = accountCtx.integrations.find((integration: any) => integration.integration_id === "klaviyo");
            if (!klaviyoIntegration) {
                setGroupsList(groupsData.groups.sort(groupSort))
            } else if (!groupsData.loading) {
                (async () => { await fetchAndMerge(klaviyoIntegration) })();
            }
            setLoading(false);
        }
    }, [groupsData.loading])

    useEffect(() => {
        if (importData.imports.length) {
            let finishedImports = importAPI.getFinishedImports()
            setGroupsList((oldList: any) => [...finishedImports, ...oldList])
            groupAPICtx.addGroups(finishedImports)
        }
    }, [importData.imports])

    useEffect(() => {
        if (importData.failedImports.length) {
            let failedImports = importAPI.getFailedImports()
            failedImports.forEach((failedImport: any) => {
                alertCtx.addAlert("Could not import", `An unexpected error happened during import of group: "${failedImport.name}". Try again.`, "cruelRuby", "TEST_SMS_ERROR")
            });
        }
    }, [importData.failedImports])


    useEffect(() => {
        if (!loading) {
            let filteredGroups = groupsList.filter((group: any) => group.size > 0);
            if (sourceFilter) {
                filteredGroups = filteredGroups.filter((group: any) => group.source === sourceFilter)
            }
            if (sourceTypeFilter) {
                filteredGroups = filteredGroups.filter((group: any) => group.source_type === sourceTypeFilter)
            }
            if (nameFilter) {
                filteredGroups = filteredGroups.filter((group: any) => group.name.toLowerCase().includes(nameFilter.toLowerCase()))
            }
            setFilteredGroupsList(filteredGroups);
        }
    }, [sourceFilter, sourceTypeFilter, nameFilter, loading, groupsList])

    useEffect(() => {
        // This will run when the component unmounts
        return () => {
            // Clear all timeouts
            // This could also be turned to finish the removal if user leaves screen
            Object.values(timeouts.current).forEach((timeout: any) => {
                clearTimeout(timeout)
            });
        };
    }, [timeouts]);

    // Make this function update mergeGroups correctly and pass it into MergeModal
    const updateMergeGroups = (mergeGroup: any) => {
        if (mergeGroups.findIndex((group: any) => mergeGroup.id === group.id) === -1) {
            setMergeGroups([...mergeGroups, mergeGroup])
        } else {
            const newMergeGroups = mergeGroups.filter((group: any) => group.id !== mergeGroup.id);
            setMergeGroups(newMergeGroups)
        }
    }

    const handleSourceFilterClick = (newFilter: string) => {
        if (sourceFilter === newFilter) {
            setSourceFilter("")
        } else {
            setSourceFilter(newFilter)
        }
    }

    const tableHeaders = ["Name", "Contacts", "Type", "Source", "Latest Sync", "Created"]

    const removeGroup = async (groupId: any) => {
        try {
            const url = process.env.REACT_APP_API_BASE + "/groups/" + groupId;
            const data = await deleteData(url);
        } catch (error) {
            // Handle your errors here
            console.error('Error deleting:', error);
        }
    }

    const addAlert = (message: string, undoFunction: () => void, removeFunction: () => void) => {
        const alertId = Date.now(); // Unique ID

        const timeout = setTimeout(() => {
            setAlerts((oldAlerts: any) => oldAlerts.filter((alert: any) => alert.id !== alertId));
            removeFunction()
        }, 7000);

        const newAlert = {
            id: alertId,
            message,
            undo: () => {
                clearTimeout(timeouts.current[alertId]); // Clear the timeout
                delete timeouts.current[alertId]; // Remove the timeout reference
                undoFunction();
                setAlerts((oldAlerts: any) => oldAlerts.filter((alert: any) => alert.id !== alertId));
            }
        };

        timeouts.current[alertId] = timeout;

        setAlerts((prevAlerts: any) => [...prevAlerts, newAlert]);
    };

    const removeRows = (groups: Array<any>) => {
        const alertMessage = `Removed ${groups.length} groups.`;
        const undoRemoval = () => {
            groupAPICtx.addGroups(groups);
            setGroupsList((prevList: any) => [...prevList, ...groups].sort(groupSort));
        }
        addAlert(alertMessage, undoRemoval, () => groups.map((group: any) => removeGroup(group.id)))

        groups.forEach((groupData: any) => {
            setGroupsList((oldList: any) => oldList.filter((group: any) => group.id !== groupData.id));
        });

        setMergeGroups([])
    }

    const removeSelectedRow = (groupData: any) => {
        const alertMessage = `Removed "${groupData.name}".`;
        const undoRemoval = () => {
            groupAPICtx.insertGroup(groupData);
            setGroupsList((prevList: any) => [...prevList, groupData].sort(groupSort));
        }
        addAlert(alertMessage, undoRemoval, () => removeGroup(groupData.id))

        setGroupsList((oldList: any) => oldList.filter((group: any) => group.id !== groupData.id));

        setMergeGroups((oldList: any) => oldList.filter((group: any) => group.id !== groupData.id));

    }

    const renderTableRows = () => {
        let tableData = filteredGroupsList;

        if (!showAll && tableData.length > 10) {
            return tableData.slice(0, 10).map((group: any) =>
                <TableRow key={group.id + group.name} checked={mergeGroups.findIndex((mergeGroup: any) => mergeGroup.id === group.id) !== -1} data={group} functions={{ updateMergeGroups, handleRemoveGroup: removeSelectedRow }} />
            )
        } else {
            return tableData.map((group: any) =>
                <TableRow key={group.id + group.name} checked={mergeGroups.findIndex((mergeGroup: any) => mergeGroup.id === group.id) !== -1} data={group} functions={{ updateMergeGroups, handleRemoveGroup: removeSelectedRow }} />
            )
        }
    }

    const checkAllHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tableData = filteredGroupsList.length ? filteredGroupsList : groupsList;
        if (event.target.checked) {
            setMergeGroups(tableData)
        } else {
            setMergeGroups([])
        }
    }

    return (
        <>
            <HeaderBar title="Audience" />
            <CreateModal open={createOpen} setOpen={setCreateOpen} integrations={accountCtx.integrations} shouldFetch={setFetchImports} />
            <div className="h-full w-full min-h-screen pageBeginMargin">
                <div className="w-10/12 mx-auto">
                    <div className="flex items-center">
                        <Button text="Create" handleClick={() => setCreateOpen(true)} bgColor="bg-birchwood" textColor="white" style="px-16 h-12" />
                        <p className="text-base ml-8 font-semibold">Easily organize your subscribers from different sources.</p>
                    </div>
                    <div className="flex mt-12 gap-8 w-full justify-between">
                        <div className="flex gap-8">
                            <div className="bg-white flex items-center w-[12rem] h-12 border border-granitine rounded-default pr-3">
                                <input type="text" onChange={(e: any) => setNameFilter(e.target.value)} className="border-0 focus:ring-0 apperance-none outline-0 text-base py-3 px-3 w-full rounded-default h-full" />
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.3} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                            <div className="w-[12rem]">
                                <SimpleDropdown placeholder={"Type"} textStyles={"font-semibold text-tamahagane"} data={groupTypes} selectFn={(item: SingleItem | null): void => {
                                    if (item) {
                                        setSourceTypeFilter(item.value);
                                    } else {
                                        setSourceTypeFilter("");
                                    }
                                }} />
                            </div>
                            <div className="w-[12rem]">
                                <Dropdown
                                    placeholder={"Source"}
                                    renderBody={(dropdownSettings: any) =>
                                        <DropdownBody
                                            renderItem={(bodySettings: any) =>
                                                <DropdownItem
                                                    id={bodySettings.id}
                                                    title={bodySettings.title}
                                                    checkbox={true}
                                                    checkboxChecked={bodySettings.value === sourceFilter}
                                                    handleClick={() => {
                                                        handleSourceFilterClick(bodySettings.value)
                                                        dropdownSettings.handleClose()
                                                    }} />
                                            }
                                            data={sourceTypes}
                                        />
                                    }
                                    selected={sourceFilter}
                                    textStyles={"capitalize font-semibold"}
                                />
                            </div>
                        </div>
                        <div className={`${mergeGroups.length ? "opacityVisible" : "opacityInvisible"} flex gap-8`}>
                            <Button text="Delete" handleClick={() => removeRows(mergeGroups)} bgColor="bg-briquette" textColor="white" style="px-16 h-12 w-48" />
                        </div>
                    </div>
                    <div className="min-w-[72rem] overflow-auto px-2 mt-8">
                        {!loading && filteredGroupsList.length > 0 &&
                        <table className="table-auto overflow-auto w-full" >
                            <thead className="border-b border-gray">
                                <tr>
                                    <th className="w-4 py-4" >
                                    </th>
                                    {tableHeaders.map((header: string) =>
                                        <th key={header}>
                                            <p className="text-base text-left font-semibold">{header}</p>
                                        </th>
                                    )}
                                    <th>
                                        <p></p>
                                    </th>
                                </tr>
                            </thead>
                                <>
                                    <tbody ref={tableBodyRef}>
                                        {
                                            importData.pendingImports &&
                                            importData.pendingImports.map((pendingImport: any) => {
                                                return (
                                                    <TableRow
                                                        checked={false}
                                                        key={pendingImport.id}
                                                        data={{
                                                            name: pendingImport.name,
                                                            id: pendingImport.id,
                                                            size: "size" in pendingImport ? pendingImport.size : 0,
                                                            progress: { requestAttempts: importData.requestAttempts[pendingImport._id], state: pendingImport.state },
                                                            loading: true,
                                                            source: pendingImport.source,
                                                            source_type: pendingImport.source_type
                                                        }} functions={{ updateMergeGroups: () => undefined, handleRemoveGroup: () => undefined }} />
                                                )
                                            }
                                            )
                                        }
                                        { filteredGroupsList.length > 0 && renderTableRows()}

                                    </tbody>
                                </>
                        </table>
                        }
                        { filteredGroupsList.length === 0 && groupsList.length > 0 && (
                            <p className="text-base font-semibold text-decoGray text-center mt-8">No matches</p>
                        )}
                    </div>
                    {
                        loading &&
                        <div aria-label="Loading..." role="status" className="w-full flex flex-col items-center justify-center mt-16 mx-auto">
                            <svg className="h-12 w-12 animate-spin" viewBox="3 3 18 18">
                                <path
                                    className=" fill-gunMetal25"
                                    d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                <path
                                    className="fill-gunMetal"
                                    d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                            </svg>
                            <p className="text-base">Loading</p>
                        </div>
                    }
                    {
                        (!loading && filteredGroupsList.length && filteredGroupsList.length > 10 ?
                            <div className="flex justify-center w-full mt-4 ">
                                {
                                    showAll && <Button text="Collapse" handleClick={() => setShowAll(false)} bgColor="bg-birchwood" textColor="white" style="px-16 h-12 mr-4" />
                                }
                                {
                                    !showAll && <Button text={`Show all (${filteredGroupsList.length})`} handleClick={() => setShowAll(true)} bgColor="bg-birchwood" textColor="white" style="px-16 h-12 mr-4" />
                                }
                            </div> : null)
                    }
                    {
                        !loading && groupsList.length === 0 ?
                        <p className="text-base font-semibold text-decoGray text-center mt-8">No groups have been imported yet</p>
                        : undefined
                    }
                </div>
            </div>

            {alerts.length ?
                <div className="fixed top-24 right-20 w-1/5">
                    {
                        alerts.map((alert: any) =>
                            <RemovedAlert key={alert.id} undoFn={alert.undo} removeText={alert.message} />
                        )
                    }
                </div>
                :
                null
            }
        </>
    )
}

export default GroupRoute;
