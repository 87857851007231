import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { motion } from "framer-motion"
import { classNames } from "helpers"
import { Fragment, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { ToolbarContext, useToolbarContext } from "store/toolbar-context"
import './index.css'

export type ToolbarBodySettings = {
    handleClose: () => void,
    firstFieldRef: React.RefObject<HTMLInputElement>
}

export type ToolbarProps = {
    renderBody: (toolbarBodySettings: ToolbarBodySettings) => ReactNode,

}

const Toolbar = (props: ToolbarProps) => {
    const { toolbarOpen, setToolbarOpen } = useToolbarContext();
    const firstFieldRef = useRef<HTMLInputElement>(null);
    const componentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setToolbarOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (toolbarOpen && firstFieldRef.current) {
            firstFieldRef.current.focus();
        }
    }, [toolbarOpen]);

    return createPortal(
        <div className={classNames(
            "fixed top-20 right-0 z-10 transform transition ease-in-out duration-500 h-screen w-[36rem] box-border ",
            toolbarOpen ? "translate-x-0" : "translate-x-full"
        )}
            ref={componentRef}
        >
            <div className={
                classNames(
                    "h-full w-full overflow-y-hidden bg-white p-8",
                    toolbarOpen ? "drop-shadow-lg" : ""
                )
            }>
                {props.renderBody({ handleClose: () => setToolbarOpen(false), firstFieldRef })}
            </div>
        </div>
        , document.body);

}

export default Toolbar;