interface IngetrationMetaType {
  id: string;
  metaUrl: string;
  disconnectUrl: string;
  authUrl: string;
  host: string;
  name: string;
  type: string;
}

export const IntegrationMetas: Record<string, IngetrationMetaType> = {
  Mailchimp: {
    id: "mailchimp",
    metaUrl: "https://segint-mailchimp.jojk.me/meta",
    disconnectUrl: "https://segint-mailchimp.jojk.me/disconnect",
    authUrl: "",
    host: "segint-mailchimp.jojk.me",
    name: "Mailchimp",
    type: "Email"
  },
  Klaviyo: {
    id: "klaviyo",
    metaUrl: "https://segint-klaviyo.jojk.me/meta",
    disconnectUrl: "https://segint-klaviyo.jojk.me/disconnect",
    authUrl: "https://segint-klaviyo.jojk.me/auth",
    host: "segint-klaviyo.jojk.me",
    name: "Klaviyo",
    type: "Email"
  },
  Emarsys: {
    id: "emarsys",
    metaUrl: "https://segint-emarsys.jojk.me/meta",
    disconnectUrl: "https://segint-emarsys.jojk.me/disconnect",
    authUrl: "https://segint-emarsys.jojk.me/auth",
    host: "segint-emarsys.jojk.me",
    name: "Emarsys",
    type: "CRM"
  },
  Shopify: {
    id: "shopify",
    metaUrl: "https://segint-dummy.jojk.me/shopify/meta",
    disconnectUrl: "https://s.int.jojk.me/disconnect",
    authUrl: "https://s.int.jojk.me/auth",
    host: "s.int.jojk.me",
    name: "Shopify",
    type: "Ecommerce"
  },
  GoogleAnalytics: {
    id: "googleanalytics",
    metaUrl: "https://segint-ga.jojk.me/meta",
    disconnectUrl: "https://segint-ga.jojk.me/disconnect",
    authUrl: "",
    host: "segint-ga.jojk.me",
    name: "Google Analytics",
    type: "Analytics"
  },
};

export const getIntegrationMeta = (integrationId: string): IngetrationMetaType | undefined => {
  if (integrationId === IntegrationMetas.Mailchimp.id) {
    return IntegrationMetas.Mailchimp
  } else if (integrationId === IntegrationMetas.Klaviyo.id) {
    return IntegrationMetas.Klaviyo
  } else if (integrationId === IntegrationMetas.Emarsys.id) {
    return IntegrationMetas.Emarsys
  } else if (integrationId === IntegrationMetas.Shopify.id) {
    return IntegrationMetas.Shopify
  } else if (integrationId === IntegrationMetas.GoogleAnalytics.id) {
    return IntegrationMetas.GoogleAnalytics
  } else {
    return
  }
}