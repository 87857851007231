import {createContext, useContext, useEffect, useState} from 'react';
import { getCookie } from '../helpers';

import AccountContext from './account-context';
import AlertContext from './alert-context';

export const initialState = {
    name: "",
    sendNow: false,
    prettyDate: "",
    time: "",
    from: "",
    totalCostPerPart: 0,
    totalSize: 0,
    groupsIds: {} as any,
    tmpBlock: {} as any,
    groups: [] as Array<GroupElement>,
    selectedGroups: {},
    selectedBlockGroups: {},
    batchCount: 2 as number,
    batchDelay: 15,
    useBatching: false,
    reset: false,
    updateName: (newName: string) => { },
    updateSendNow: (newState: boolean) => { },
    updateFrom: (newFrom: string) => { },
    updateTime: (newTime: string) => { },
    declareGroups: (groups: any) => { },
    addGroup: (newGroup: any, listType: ListType) => { },
    removeGroup: (removeGroupId: number, listType: ListType) => { },
    updateBatchCount: (newCount: number) => { },
    updateBatchDelay: (newTime: number) => { },
    updateUseBatching: (newState: boolean) => { },
    resetState: () => { }
}

const CampaignContext = createContext({
    ...initialState
});

export function CampaignContextProvider(props: any) {
    const accountCtx = useContext(AccountContext);
    const alertCtx = useContext(AlertContext);

    const [name, setName] = useState("");
    const [sendNow, setSendNow] = useState(initialState.sendNow);
    const [prettyDate, setPrettyDate] = useState("");
    const [time, setTime] = useState("");
    const [from, setFrom] = useState("");
    const [totalCostPerPart, setTotalCostPerPart] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [groupsIds, setGroupsIds] = useState<{[key: number]: GroupElement}>({});
    const [tmpBlock, setTmpBlock] = useState<{[key: number]: GroupElement}>({});
    const [groups, setGroups] = useState([] as Array<GroupElement>);
    const [selectedGroups, setSelectedGroups] = useState({} as { [key: string]: 0 });
    const [selectedBlockGroups, setSelectedBlockGroups] = useState({} as { [key: string]: 0 });
    const [batchCount, setBatchCount] = useState(initialState.batchCount);
    const [batchDelay, setBatchDelay] = useState(initialState.batchDelay);
    const [useBatching, setUseBatching] = useState(initialState.useBatching);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        setFrom(s => s || accountCtx.defaultSender);
    }, [accountCtx.defaultSender])
    
    const updateName = (newName: string) => {
        if (newName) {
            alertCtx.discardAlert("NO_NAME");
        }
        setName(newName);
    }

    const updateSendNow = (newState: boolean) => {
        setSendNow(newState);
    }
    

    const updateFrom = (newFrom: string) => {
        if (newFrom) {
            alertCtx.discardAlert("NO_SENDER");
        }
        setFrom(newFrom);
    }

    const updateTime = (newTime: string) => {
        setTime(newTime);
    }

    const getTotalCost = (sizes: {[key: string]: number}, prices: {[key: string]: number}) => {
        let total = 0;
        for (const cc in sizes) {
            if (cc in prices) {
                total += sizes[cc] * prices[cc];
            } else {
                total += sizes[cc] * prices['default'];
            }
        }
        return total;
    }
    
    const getCombinedSizeByCountry = async (include: number[], exclude: number[]) => {
        const allGroups = [...include, ...exclude.map(x => -x)];
        const allGroupsString = allGroups.join(",");
        
        const token = getCookie("token");
        const url = `${process.env.REACT_APP_API_BASE}/groups/${allGroupsString}/countrycodes`;
        const headers = { Authorization: `Bearer ${token}` };
        const data = await fetch(url, { headers }).then(res => res.json());
        const res: {[key: string]: number} = data["countrycodes"];
        return res;
    }

    const addGroup = async (newGroup: GroupElement, listType: ListType) => {
        alertCtx.discardAlert("NO_RECIPIENT");
        setBatchCount(initialState.batchCount);

        if (listType === "include") {
            setGroupsIds(prev => {
                let cur = {...prev};
                cur[newGroup.id] = newGroup;
                return cur;
            });
            setSelectedGroups(prev => {
                let cur = {...prev};
                cur[newGroup.name] = 0;
                return cur;
            });
        } else {
            setTmpBlock(prev => {
                let cur = {...prev};
                cur[newGroup.id] = newGroup;
                return cur;
            });
            setSelectedBlockGroups(prev => {
                let cur = {...prev};
                cur[newGroup.name] = 0;
                return cur;
            });
        }
    }
    
    const removeGroup = (removeGroupId: number, listType: ListType) => {
        setBatchCount(initialState.batchCount);
        if (listType === "include") {
            setSelectedGroups(prev => {
                let cur = {...prev};
                if (removeGroupId in groupsIds) {
                    delete cur[groupsIds[removeGroupId].name];
                }
                return cur;
            });
            setGroupsIds(prev => {
                let cur = {...prev};
                delete cur[removeGroupId];
                return cur;
            });
        } else {
            setSelectedBlockGroups(prev => {
                let cur = {...prev};
                if (removeGroupId in tmpBlock) {
                    delete cur[tmpBlock[removeGroupId].name];
                }
                return cur;
            });
            setTmpBlock(prev => {
                let cur = {...prev};
                delete cur[removeGroupId];
                return cur;
            });
        }
    }

    const declareGroups = (groups: Array<GroupElement>) => {
        setGroups(groups);
    }


    const resetState = () => {
        setName(initialState.name)
        setSendNow(initialState.sendNow)
        setTime(initialState.time)
        setFrom(initialState.from)
        setTotalCostPerPart(initialState.totalCostPerPart)
        setTotalSize(initialState.totalSize)
        setGroupsIds(initialState.groupsIds)
        setTmpBlock(initialState.tmpBlock);
        setSelectedGroups(initialState.selectedGroups)
        setSelectedBlockGroups(initialState.selectedGroups)
        setBatchCount(initialState.batchCount);
        setBatchDelay(initialState.batchDelay);
        setUseBatching(initialState.useBatching);
        setPrettyDate(initialState.prettyDate)
        setReset(!reset);
    }


    useEffect(() => {
        (async () => {
            const include = Object.values(groupsIds).map(x => x.id);
            if (include.length === 0) {
                setTotalSize(0);
                setTotalCostPerPart(0);
            } else {
                const exclude = Object.values(tmpBlock).map(x => x.id);
                const sizes = await getCombinedSizeByCountry(include, exclude);
                const prices = accountCtx.prices;
                const newTotalSize = Object.values(sizes).reduce((acc, cur) => acc + cur, 0);
                const newTotalCostPerPart = getTotalCost(sizes, prices);
                setTotalSize(newTotalSize);
                setTotalCostPerPart(newTotalCostPerPart);
            }
            setBatchCount(initialState.batchCount);
        })();
    }, [accountCtx.prices, groupsIds, tmpBlock]);


    const updateBatchCount = (newCount: number) => {
        setBatchCount(newCount);
    }

    const updateBatchDelay = (newTime: number) => {
        setBatchDelay(newTime);
    }

    const updateUseBatching = (newState: boolean) => setUseBatching(newState);

    const context = {
        name,
        sendNow,
        prettyDate,
        time,
        from,
        groups,
        groupsIds,
        tmpBlock,
        totalCostPerPart,
        totalSize,
        selectedGroups,
        selectedBlockGroups,
        batchCount,
        batchDelay,
        useBatching,
        reset,
        updateName,
        updateSendNow,
        updateTime,
        updateFrom,
        addGroup,
        declareGroups,
        removeGroup,
        updateBatchCount,
        updateBatchDelay,
        updateUseBatching,
        resetState
    };
    return (
        <CampaignContext.Provider value={context}>
            {props.children}
        </CampaignContext.Provider>
    )
}

export default CampaignContext;