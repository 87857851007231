import { classNames } from "helpers"
import { createRef, useEffect, useState } from "react"
import { generateDateObjects, type MonthRefs, type UpdateRangeFn } from "./helpers"

import './index.css';


type RangeCalendarProps = {
    days?: Array<string>,
    rangeStart?: DateObject | null,
    rangeEnd?: DateObject | null,
    updateRange?: UpdateRangeFn
}


interface MonthNames {
    [key: number]: {
        en: string,
        sv: string
    }
}

const monthNames: MonthNames = {
    1: { en: 'January', sv: 'Januari' },
    2: { en: 'February', sv: 'Februari' },
    3: { en: 'March', sv: 'Mars' },
    4: { en: 'April', sv: 'April' },
    5: { en: 'May', sv: 'Maj' },
    6: { en: 'June', sv: 'Juni' },
    7: { en: 'July', sv: 'Juli' },
    8: { en: 'August', sv: 'Augusti' },
    9: { en: 'September', sv: 'September' },
    10: { en: 'October', sv: 'Oktober' },
    11: { en: 'November', sv: 'November' },
    12: { en: 'December', sv: 'December' }
};

export type DateObject = undefined | {
    year: number,
    month: number,
    day: number
}

const RangeCalendar = (props: RangeCalendarProps) => {

    const [startDate, setStartDate] = useState<DateObject | null>()
    const [endDate, setEndDate] = useState<DateObject | null>()
    const [hoverDate, setHoverDate] = useState()
    const [rangeFocus, setRangeFocus] = useState()
    const [selectState, setSelectState] = useState("")
    const [isLoaded, setIsLoaded] = useState(false);

    const monthRefs: MonthRefs = {};
    const scrollContainerRef = createRef<HTMLDivElement>();


    const months = generateDateObjects(monthRefs);

    // if not endDate
    // if isWithingRange(startDate, hoverDate)
    // else
    // isWithingRange(startDate, endDate)


    const scrollToMonth = (year: number, month: number) => {
        const key = `${year}-${month < 10 ? '0' : ''}${month}`;
        const monthRef = monthRefs[key]
        //monthRefs[key]?.current?.scrollIntoView({ behavior: "smooth" });

        if (monthRef.current && scrollContainerRef.current) {
            const offsetTop = monthRef.current.offsetTop - scrollContainerRef.current.offsetTop;
            scrollContainerRef.current.scrollTop = offsetTop;
        }
    };

    useEffect(() => {

        if (!props.rangeStart && !props.rangeEnd) {
            setStartDate(null)
            setEndDate(null)
            setSelectState("startDate")
            const today = new Date()
            scrollToMonth(today.getFullYear(), today.getMonth() + 1)
        } else {

            if (props.rangeStart) {
                props.rangeStart !== startDate && scrollToMonth(props.rangeStart.year, props.rangeStart.month)
                setStartDate(props.rangeStart)
                if (props.rangeEnd) {
                    setEndDate(props.rangeEnd)
                }
            } else {
                const today = new Date()
                scrollToMonth(today.getFullYear(), today.getMonth() + 1)
            }
            setSelectState("startDate")
        }
    }, [props.rangeStart])


    const handleDayClick = (dayData: { year: number, month: number, day: number }) => {
        if (selectState === "startDate") {
            setStartDate(dayData);
            setEndDate(undefined);
            setSelectState("endDate");
        } else if (selectState === "endDate" && startDate) {
            let start = new Date(startDate.year, startDate.month, startDate.day);
            let end = new Date(dayData.year, dayData.month, dayData.day);
            if (end <= start) {
                setStartDate(dayData)
                setEndDate(undefined)
            } else {
                // Date range should be half-open, i.e. [start_date, end_date)
                let d = new Date(dayData.year, dayData.month - 1, dayData.day);
                d.setDate(d.getDate() + 1); // Next day
                let date :DateObject = {
                     year: d.getFullYear(),
                     month: d.getMonth() + 1, // In JavaScript, months are 0-based.
                     day: d.getDate()
                 };

                setEndDate(date);
                if (props.updateRange) {
                    props.updateRange({ startDate: startDate, endDate: date })
                }
            }

        }
    }

    const isSameDay = (dayOne: any, dayTwo: any) => {
        if (dayOne.year !== dayTwo.year) {
            return false
        }
        if (dayOne.month !== dayTwo.month) {
            return false
        }
        if (dayOne.day !== dayTwo.day) {
            return false
        }
        return true
    }

    function isDayBetween(startDay: DateObject, endDay: DateObject, day: DateObject) {
        if (startDay && endDay && day) {
            const start = new Date(startDay.year, startDay.month - 1, startDay.day);
            const end = new Date(endDay.year, endDay.month - 1, endDay.day);
            const checkDay = new Date(day.year, day.month - 1, day.day);

            return checkDay > start && checkDay < end;
        } else {
            return false
        }
    }

    const renderDays = (monthData: any) => {
        const { year, month, days } = monthData;
        let endDay: DateObject = hoverDate;
        if (endDate) {
            // End date is exclusive
            let d = new Date(endDate.year, endDate.month - 1, endDate.day);
            d.setDate(d.getDate() - 1);
            endDay = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
        }

        return (
            <div className="grid grid-cols-7 gap-y-2 text-center mb-2 opacity-100 transition-opacity">
                {days.map((day: any) =>
                    <div className="cursor-pointer h-8 my-1">
                        <div
                            onMouseOver={() => setHoverDate(day)}
                            onClick={day.month === month ? () => handleDayClick(day) : undefined}
                            className={classNames(
                                "cursor-pointer text-center p-2 self-center",
                                isSameDay(day, (startDate ?? { year: 0, month: 0, day: 0 })) && " bg-birchwood text-white rounded-l-full",
                                isSameDay(day, (endDay ?? { year: 0, month: 0, day: 0 })) && "transition-colors duration-100 bg-birchwood text-white rounded-r-full",
                                isSameDay(day, (hoverDate ?? { year: 0, month: 0, day: 0 })) && "border-2 border-birchwood rounded-full mx-2 -mt-[2px]",
                                startDate && isDayBetween(startDate, endDay, day) && "  bg-birchwood text-white",
                                day.month !== month && "invisible"
                            )}>
                                {day.day}
                                </div>
                    </div>
                )}
            </div>
        )
    }


    return (
        <div className={
            classNames(
                isLoaded && " invisible",
                "flex flex-col w-full h-[28rem] overflow-y-scroll "
            )
        } ref={scrollContainerRef}>
            {/* <div className="border-b border-granitine flex w-full justify-evenly">
                {
                    days.map(day =>
                        <div className="text-base font-semibold w-8">{day}</div>
                    )
                }
            </div> */}
            <div className="flex flex-col w-full justify-evenly">
                {
                    months.map(month =>
                        <div id={`${month.year}-${month.month < 10 ? '0' : ''}${month.month}`} key={`${month.year}-${month.month < 10 ? '0' : ''}${month.month}`}
                            ref={monthRefs[`${month.year}-${month.month < 10 ? '0' : ''}${month.month}`]}

                            className="flex flex-col w-full h-full">
                            <div className="flex w-full gap-2 pl-2 pt-2">
                                <p className="font-semibold">{monthNames[month.month]['sv']}</p>
                                <p className="font-semibold">{month.year}</p>
                            </div>
                            {renderDays(month)}
                        </div>
                    )
                }
            </div>
        </div >
    )
}

export default RangeCalendar;