import { DotsLoader } from "components/DotsLoader";
import { useContext, useEffect, useState } from "react";
import DispatchContext from "store/dispatch-context";
import OverviewRow from "./OverviewRow";
import {DateObject} from "../../../components/RangeCalendar";

type CampaignOverviewProps = {
    startDate: DateObject | null,
    endDate: DateObject | null,
}

export default function CampaignOverview(props: CampaignOverviewProps) {
    const startDate = props.startDate? new Date(props.startDate.year, props.startDate.month - 1, props.startDate.day) : undefined;
    const endDate = props.endDate? new Date(props.endDate.year, props.endDate.month - 1, props.endDate.day) : undefined;
    const { dispatches: allCampaigns, loading } = useContext(DispatchContext);

    const filteredCampaigns = allCampaigns.filter(c => {
        if (c.size < 5) return false;
        if (!startDate || !endDate) return true;
        const date = new Date(c.date);
        return date >= startDate && date < endDate
    })
    const latestCampaigns = filteredCampaigns.slice(0, 10);


    function formatNumber(number: number) {
        if (number >= 1000000) {
            const millions = number / 1000000;
            return `${millions.toFixed(2)}M`;
        } else if (number >= 1000) {
            const thousands = number / 1000;
            return `${thousands.toFixed(1)}K`;
        } else {
            return number.toString();
        }
    }

    return (
        <div className="flex flex-col bg-white rounded-lg statCardShadow p-8 mt-8">
            <div className="flex justify-between items-center">
                <p className="text-base font-semibold ml-8">Campaigns</p>
            </div>
            <div className="mt-12 pl-8">
                <div className="grid grid-cols-[1fr,auto,auto,auto] gap-x-2 py-2 border-b border-gray">
                    <p className="text-base font-semibold">Name</p>
                    <p className="w-[88px] text-base font-semibold">Contacts</p>
                    <p className="w-[88px] text-base font-semibold">Delivery rate</p>
                    <p className="w-[88px] text-base font-semibold">Click rate</p>
                </div>
                {
                    loading ?

                        <div className="flex justify-center mt-4">
                            <DotsLoader />
                        </div>
                        : undefined
                }
                {
                    !loading && latestCampaigns.length ? latestCampaigns.map((campaign: Dispatch) => {
                        let {accepted = 0, delivered = 0, failed = 0, sent = 0} = campaign.texts ?? {};
                        sent = accepted + delivered + failed + sent;
                        let clicks = campaign?.clicks ?? 0;

                        const deliveryRate = sent > 0 ? delivered / sent : 0;
                        const deliveryRateString = `${(deliveryRate * 100).toFixed(1)}%`
                        const clickRate = delivered > 0 ? clicks / delivered : 0;
                        const clickRateString = `${(clickRate * 100).toFixed(1)}%`
                        const sizeString = `${sent}`;

                        return (
                            <OverviewRow campaignName={campaign.name} size={sizeString} deliveryRate={deliveryRateString} clickRate={clickRateString}/>
                        )
                    })
                    : undefined
                }
                {
                    !loading && allCampaigns.length === 0 ?
                    <p className="text-base font-semibold text-decoGray text-center mt-4">No campaigns yet</p>
                    : undefined
                }
                {
                    !loading && allCampaigns.length > 0 && filteredCampaigns.length === 0 ?
                        <p className="text-base font-semibold text-decoGray text-center mt-4">No campaigns in this time period</p>
                        : undefined
                }
            </div>
        </div>
    )
}