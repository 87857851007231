

import { useState } from "react";
import { BsChevronDown, BsChevronRight, BsMapFill } from "react-icons/bs";
import NewDropdown from "components/NewDropdown";
import { Dropdown, DropdownBody, DropdownItem } from "dropdown"
import SearchableDropdownBody from "dropdown/SearchableDropdownBody";

type SearchableDropdownItem = {
    id: number,
    title: string,
    value: string
}

export interface SearchableDropdownProps {
    setSelection: Function,
    data: SearchableDropdownItem[],
    placeholder: string,
    searchPlaceHolder: string
}

const SearchableDropdown = (props: SearchableDropdownProps) => {
    const [selected, setSelected] = useState({} as SearchableDropdownItem )

    const selectClick = (newSelection: SearchableDropdownItem ) => {
        if (newSelection.value === selected.value) {
            setSelected({} as SearchableDropdownItem );
        } else {
            setSelected(newSelection);
        }
    }

    return (
        <Dropdown
            placeholder={props.placeholder}
            bodyEaseIn={false}
            renderBody={(dropdownSettings: any) =>
                <SearchableDropdownBody
                    renderItem={(bodySettings: any) =>
                        <DropdownItem
                            key={bodySettings.id}
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={bodySettings.title === selected.title}
                            handleClick={() => {
                                selectClick(bodySettings);
                                props.setSelection(bodySettings.value);
                                dropdownSettings.handleClose();
                            }} />
                    }
                    data={props.data}
                    bodyDirection="right"
                    searchPlaceholder={props.searchPlaceHolder}
                    maxHeight="max-h-48"
                />
            }
            selected={selected.title}
            textStyles={"font-semibold p-2 truncate text-ellipsis text-left"}
        />
    )


}
export default SearchableDropdown