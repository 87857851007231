

// Dropdown that can host everything else
// Calendar view inside
// 

import RangeCalendar, { DateObject } from "components/RangeCalendar";
import { classNames } from "helpers";
import { getLastNDays, prettyDate } from "./helpers";
import { ReactNode, useEffect, useRef, useState } from "react"
import {UpdateRangeFn} from "components/RangeCalendar/helpers";

import "./index.css";

const days = ["M", "T", "W", "T", "F", "S", "S"]
type DateFilterProps = {
    disabled?: boolean,
    loading?: boolean,
    styles?: string,
    headerStyles?: string,
    textStyles?: string,
    updateRangeFn: UpdateRangeFn,
    rangeStart: DateObject | null,
    rangeEnd: DateObject | null,
    preSelectFilter?: string
}

const DateFilter = ({ disabled, loading, styles, headerStyles, textStyles, updateRangeFn, rangeStart, rangeEnd, preSelectFilter }: DateFilterProps) => {
    const dateFilters = [
        { text: "Today", key: "today", onClickFn: () => {
                updateRangeFn(getLastNDays(1))
                setLabel("Today");
            }
        },
        {
            text: "Yesterday", key: "yesterday", onClickFn: () => {
                let yesterday = new Date()
                yesterday.setDate(today.getDate() - 1)
                updateRangeFn(getLastNDays(1, { year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate() }))
                setLabel("Yesterday");
            }
        },
        { text: "This week", key: "thisweek", onClickFn: () => {
                updateRangeFn(getLastNDays(7));
                setLabel("This week");
            }
        },
        {
            text: "Last week", key: "lastweek", onClickFn: () => {
                let lastWeek = new Date()
                lastWeek.setDate(today.getDate() - 7)
                updateRangeFn(getLastNDays(7, { year: lastWeek.getFullYear(), month: lastWeek.getMonth() + 1, day: lastWeek.getDate() }))
                setLabel("Last week");
            }
        },
        { text: "Last 30 days", key: "30days", onClickFn: () => {
                updateRangeFn(getLastNDays(30));
                setLabel("Last 30 days");
            }
        },
        {
            text: "Last 60 days", key: "60days", onClickFn: () => {
                updateRangeFn(getLastNDays(60));
                setLabel("Last 60 days");
            }
        },
        {
            text: "Last 90 days", key: "90days", onClickFn: () => {
                updateRangeFn(getLastNDays(90));
                setLabel("Last 90 days");
            }
        },
        {
            text: "Last 180 days", key: "all", onClickFn: () => {
                updateRangeFn(getLastNDays(180));
                setLabel("Last 180 days");
            }
        },
        {
            text: "Custom range", key: "custom", onClickFn: () => {}
        },
    ]

    const preSelectLabel = dateFilters.filter(x => x.key === preSelectFilter)?.[0]?.text || "Custom date range";

    const [open, setOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(preSelectFilter ?? "");
    const [label, setLabel] = useState(preSelectLabel);

    const closeDropdown = (e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setOpen(false);
        }
    }

    const today = new Date();

    const handleSelection: UpdateRangeFn = (updateRangeParams) => {
        setSelectedFilter("custom")
        updateRangeFn(updateRangeParams)
        const start = updateRangeParams.startDate;
        const end = updateRangeParams.endDate;
        const label = `${(start && prettyDate(start)) || ''} – ${(end && prettyDate(end)) || ''}`
        setLabel(label)
    }
    return (
        <div className={classNames("relative min-w-fit outline-none", disabled && "cursor-not-allowed", styles)} onBlur={closeDropdown} tabIndex={0} >
            <button
                disabled={disabled || loading}
                className={
                    classNames(
                        "border bg-white font-inherit appearance-none outline-none flex flex-row justify-between items-center border-granitine rounded-default py-3 px-3 cursor-pointer w-full z-10 h-12",
                        disabled && "cursor-not-allowed bg-neutral-100",
                    )
                }
                onClick={(e: any) => {
                    setOpen((oldVal: any) => !oldVal)
                }}
            >
                <p className={
                    classNames(
                        "text-base font-semibold",
                        "mr-4",
                        textStyles
                    )
                }>
                    {label}
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gunMetal25">
                    <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clipRule="evenodd" />
                </svg>
            </button>
            {open &&
                <div className="absolute top-full w-192 right-0 bg-white border border-granitine rounded-default mt-1 z-10 dateFilterShadow">
                    <div className="flex w-full">
                        <div className="flex flex-col text-base font-medium w-96 p-2">

                            {dateFilters.map((dateFilter: any) =>
                                <div
                                    key={dateFilter.key}
                                    className={classNames(
                                        "cursor-pointer p-2",
                                        dateFilter.key === selectedFilter ? " bg-birchwood/20 rounded-md" : ""
                                    )}
                                    onClick={() => {
                                        dateFilter.onClickFn()
                                        setSelectedFilter(dateFilter.key)
                                    }}
                                >{dateFilter.text}</div>
                            )}
                        </div>
                        <div className="border border-iron border-x-0 border-b-0 mb-8">{/* Divider */}</div>
                        <div className="w-full border-l border-granitine flex flex-col pt-4">
                            <div className="flex items-center mb-4 pt-4 px-4">
                                <div className="text-start"> {/* Consider making this clickable */}
                                    <p className="text-base font-medium">{rangeStart ?
                                        `${prettyDate(rangeStart)}`
                                        : "-"}</p>
                                </div>
                                <p className="mx-2 text-base font-medium">-</p>
                                <div className="text-start">
                                    <p className="text-base font-medium">{rangeEnd ?
                                        `${prettyDate(rangeEnd)}`
                                        : "-"}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-7 w-full text-center bg-birchwood text-white mb-2 py-2 top-0">
                                {days.map(dayName =>
                                    <p>{dayName}</p>
                                )}
                            </div>
                            <RangeCalendar rangeStart={rangeStart} rangeEnd={rangeEnd} updateRange={handleSelection} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DateFilter