import Button from "components/Button";
import DateFilter from "components/DateFilter";
import HeaderBar from "components/HeaderBar";
import NewDropdown from "components/NewDropdown";
import { SimpleDropdown } from "components/SimpleDropdown";
import { Dropdown } from "dropdown";
import React, { useContext, useEffect, useState } from "react";

import RevenueView from "./RevenueView";
import StatCard from "./StatCard";
import { type DateObject } from "components/RangeCalendar"
import { UpdateRangeFn } from "components/RangeCalendar/helpers";
import DeliveryView from "./DeliveryView";
import Map from "./Map";
import PerformanceView from "./PerformanceView";
import StatsContext from "../../store/stats-context";
import { classNames } from "helpers";
import ImageWithMarkers from "components/ImageWithMarkers";
import CampaignOverview from "./CampaignOverview";
import FilterDropdown from "./FilterDropdown";
import AccountContext from "../../store/account-context";
import accountContext from "../../store/account-context";
import {Simulate} from "react-dom/test-utils";
import {getLastNDays} from "../../components/DateFilter/helpers";

const DashboardRoute = () => {

    const [selectedFilters, setSelectedFilters] = useState("");
    const [startDate, setStartDate] = useState<DateObject | null>();
    const [endDate, setEndDate] = useState<DateObject | null>();

    const [prettyStartDate, setPrettyStartDate] = useState<string>();
    const [prettyEndDate, setPrettyEndDate] = useState<string>();
    const statsContext = useContext(StatsContext);
    const { totalContacts, billingCurrency } = useContext(AccountContext);

    const costString = `${statsContext.stats.summary.all.total_cost / 100 /100} ${billingCurrency}`;

    const revenue = statsContext.stats.summary.ga_revenue;
    const currency = statsContext.stats.summary.ga_currency;
    const revenueString = revenue? `${revenue.toFixed(0)} ${currency}` : 'No data yet';
    const placedOrders = statsContext.stats.summary.ga_transactions;
    const placedOrdersString = placedOrders? `${placedOrders}` : 'No data yet';
    const conversionRate = statsContext.stats.summary.ga_conversion_rate;
    const conversionRateString = conversionRate? `${(100 * conversionRate).toFixed(1)}%` : 'No data yet';
    
    const clickRate = statsContext.stats.summary.click_rate;
    const clickRateString = `${(100 * clickRate).toFixed(1)}%`;

    const dropdownItems = [
        { title: "Delivery", value: "delivery", id: 1 },
        { title: "Performance", value: "performance", id: 2 },
        { title: "Funnel Analysis", value: "funnelAnalysis", id: 3 },
    ]

    const handleRangeSelection: UpdateRangeFn = (dates) => {
        setStartDate(dates.startDate);
        setEndDate(dates.endDate);
        if (dates.startDate && dates.endDate) {
            const prettyStartDate = new Date(dates.startDate?.year, dates.startDate?.month - 1, dates.startDate.day).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            })
            setPrettyStartDate(prettyStartDate)
            const prettyEndDate = new Date(dates.endDate?.year, dates.endDate?.month - 1, dates.endDate.day).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            })
            setPrettyEndDate(prettyEndDate)
            if (dates.startDate && dates.endDate) {
                const newStartDate = new Date(dates.startDate.year, dates.startDate.month - 1, dates.startDate.day);
                const newEndDate = new Date(dates.endDate.year, dates.endDate.month - 1, dates.endDate.day);
                statsContext.setDates(newStartDate, newEndDate);
            }
        }
    }

    useEffect(() => {
        handleRangeSelection(getLastNDays(30))
    }, [])

    return (
        <>
            <HeaderBar title={!selectedFilters ? "Dashboard" : "Dashboard - " + selectedFilters} />
            <div className={classNames(
                "h-full min-h-screen pageBeginMargin w-11/12 xl:w-4/5 standardLeftMargin xl:mx-auto",
            )}>
                <div className="flex flex-col justify-center bg-whiteSmoke w-full">

                </div>
                <div className="border-b border-gray pb-4 flex justify-between mb-4">
                    <div className="flex flex-row">
                        <div className="w-48">
                            <FilterDropdown placeholder={"Filter"} data={dropdownItems} selectFn={function (selectItem: { title: string, value: string, id: number } | null): void {
                                if (!selectItem) {
                                    setSelectedFilters("");
                                } else {
                                    setSelectedFilters(selectItem.value);
                                }
                            }} />
                        </div>
                        {prettyStartDate && prettyEndDate &&
                            <div className="flex items-center ml-6">
                                <span className="text-base font-semibold text-center">{prettyStartDate.slice(0, -4)} - {prettyEndDate.slice(0, -4)}</span>
                            </div>
                        }
                    </div>
                    <DateFilter updateRangeFn={handleRangeSelection} rangeStart={startDate} rangeEnd={endDate} preSelectFilter={"30days"} />
                </div>
                {
                    selectedFilters === "revenue" &&
                    <>
                        {prettyStartDate && prettyEndDate && <p className="font-semibold text-base mb-4">{prettyStartDate} - {prettyEndDate}</p>}
                        <div className="flex flex-col justify-center bg-white p-4 drop-shadow-2xl rounded-xl">
                            <RevenueView />
                        </div>
                    </>
                }
                {
                    selectedFilters === "delivery" &&
                    <>
                        {prettyStartDate && prettyEndDate && <p className="font-semibold text-base mb-4">{prettyStartDate} - {prettyEndDate}</p>}
                        <DeliveryView />
                    </>
                }
                {
                    selectedFilters === "performance" &&
                    <>
                        {prettyStartDate && prettyEndDate && <p className="font-semibold text-base mb-4"></p>}
                        <PerformanceView startDate={startDate} endDate={endDate} prettyDateRange={prettyStartDate && prettyEndDate && `${prettyStartDate} - ${prettyEndDate}`} />
                    </>
                }
                {
                    selectedFilters === "" &&
                    <div className="flex gap-8 mt-4 mb-8">
                        <div className="w-4/5">
                            <div className="grid grid-cols-3 gap-8">
                                <StatCard title={"Customers"} value={totalContacts.toString()} icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                        <path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z" clipRule="evenodd" />
                                        <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
                                    </svg>
                                } diff="" diffDesc="" diffIcon={undefined} />
                                <StatCard title={"Revenue"} value={revenueString} icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                        <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
                                    </svg>
                                } diff="" diffDesc="" diffIcon={undefined} />
                                <StatCard title={"Placed orders"} value={placedOrdersString} icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                            <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                                        </svg>
                                } diff="" diffDesc="" diffIcon={undefined} />
                                <StatCard title={"Costs"} value={costString} icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                            <path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z" clipRule="evenodd" />
                                            <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
                                        </svg>
                                } diff="" diffDesc="" diffIcon={undefined} />
                                <StatCard title={"CTR"} value={clickRateString} icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                            <path fillRule="evenodd" d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z" clipRule="evenodd" />
                                        </svg>
                                } diff="" diffDesc="" diffIcon={undefined} />
                                <StatCard title={"Conversion rate"} value={conversionRateString} icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                            <path fillRule="evenodd" d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm4.5 7.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25a.75.75 0 01.75-.75zm3.75-1.5a.75.75 0 00-1.5 0v4.5a.75.75 0 001.5 0V12zm2.25-3a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0V9.75A.75.75 0 0113.5 9zm3.75-1.5a.75.75 0 00-1.5 0v9a.75.75 0 001.5 0v-9z" clipRule="evenodd" />
                                        </svg>
                                } diff="" diffDesc="" diffIcon={undefined} />
                            </div>
                            <CampaignOverview startDate={startDate} endDate={endDate} />
                        </div>
                        <Map prettyStartDate={prettyStartDate} prettyEndDate={prettyEndDate}></Map>
                    </div>
                }
            </div>
        </>
    )
}

export default DashboardRoute;