import HeaderBar from "components/HeaderBar";
import {classNames, getCookie} from "helpers";
import {SingleItem} from "../../components/SimpleDropdown";
import {useContext, useEffect, useState} from "react";
import AccountContext from "../../store/account-context";
import FlowsContext, {FlowDraft, KlaviyoFlow, KlaviyoMessage, Metric} from "../../store/flows-context";
import {countryCodes} from "../GroupRoute/CreateModal/helpers";
import Button from "../../components/Button";
import {HiPencilAlt} from "react-icons/hi";
import phoneImg from "../CampaignRoute/sections/section_3/phone.png";
import {SmsInfoType, smsTypeAndCount} from "../CampaignRoute/sections/section_3/helpers";
import AlertContext from "../../store/alert-context";
import {useNavigate} from "react-router-dom";
import {SimpleDropdown2} from "../../components/SimpleDropdown2";

const NewFlowRoute = () => {
    const accountCtx = useContext(AccountContext);
    const flowsCtx = useContext(FlowsContext);
    const alertCtx = useContext(AlertContext);
    const navigate = useNavigate();
    
    const {metrics:allowedMetrics, klaviyoFlows} = flowsCtx;
    
    const [name, setName] = useState('');
    const [sender, setSender] = useState('');
    const [klaviyoFlow, setKlaviyoFlow] = useState<KlaviyoFlow>();
    const [klaviyoMessages, setKlaviyoMessages] = useState<KlaviyoMessage[]>([]);
    const [klaviyoMessagesAreLoading, setKlaviyoMessagesAreLoading] = useState(false);
    const [klaviyoMessage, setKlaviyoMessage] = useState<KlaviyoMessage>();
    const [hasOrHasNot, setHasOrHasNot] = useState('');
    const [metric, setMetric] = useState<Metric|undefined>();
    const [delay, setDelay] = useState(0);
    const [timeOfDay, setTimeOfDay] = useState('');
    const [cc, setCC] = useState('');
    const [rawMessage, setRawMessage] = useState('');
    const [parsedMessage, setParsedMessage] = useState('');

    const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
        charCount: 0,
        parts: 0,
        maxLengthForParts: 0,
        specialChars: [],
        isUnicode: false,
    });
    
    const noneCC = { country: "International", alpha2: "none" }
    const validCCs = [noneCC, ...countryCodes];

    const handleMessageInput = (e?: any) => {
        e?.preventDefault();
        let raw = e ? e.target.value : rawMessage;
        let newSmsInfo = smsTypeAndCount(raw);
        setRawMessage(raw);
        setParsedMessage(raw);
        setSmsInfo(newSmsInfo);
    };

    const sendPreview = async () => {
        let errorMsg = ""
        if (!sender) {
            errorMsg = "'Sender' cannot be empty"
        } else if (!parsedMessage.trim()) {
            errorMsg = "Empty message"
        } else if (!accountCtx.user.number) {
            errorMsg = "Empty number associated with this account"
        }
        if (errorMsg) {
            alertCtx.addAlert("Error", errorMsg, "cruelRuby", "OTHER_ERROR")
            return
        }

        const token = getCookie("token");
        const url = `${process.env.REACT_APP_API_BASE}/texts`
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify({
            sender,
            to: accountCtx.user.number,
            message: rawMessage,
        })
        const response = await fetch(url, { method: 'POST', headers, body});
        const responseBody = await response.json();
        if (!response.ok) {
            errorMsg = `API response: ${responseBody.message}`;
            alertCtx.addAlert("Error", errorMsg, "cruelRuby", "OTHER_ERROR")
            return
        }
        alertCtx.addAlert(
            "Preview sent",
            `Sent to ${accountCtx.user.number}.`,
            "darkAqua",
            "TEST_SMS_SUCCESS"
        );
    }
    
    const onSubmit = async (e?: any) => {
        e?.preventDefault();
        
        let errorMsg = "";
        if (!name) {
            errorMsg = "'Name' cannot be empty"
        } else if (!sender) {
            errorMsg = "'Sender' cannot be empty"
        } else if (!klaviyoFlow) {
            errorMsg = "No Klaviyo flow selected"
        } else if (!klaviyoMessage) {
            errorMsg = "No email selected"
        } else if (!hasOrHasNot) {
            errorMsg = "Either 'has' or 'has not' must be selected"
        } else if (!metric) {
            errorMsg = "No metric selected"
        } else if (!rawMessage.trim()) {
            errorMsg = "Empty message"
        } else if (!delay) {
            errorMsg = "No delay selected"
        } else if (!timeOfDay) {
            errorMsg = "No time of day selected"
        } else if (!cc) {
            errorMsg = "No number format selected"
        }
        
        if (errorMsg) {
            alertCtx.addAlert("Error", errorMsg, "cruelRuby", "OTHER_ERROR")
            return
        }
        
        const draft: FlowDraft = {
            cc: cc,
            days_delay: delay,
            flow_id: klaviyoFlow!.id,
            flow_message_id: klaviyoMessage!.id,
            has_metric: (hasOrHasNot === 'has'),
            message: rawMessage,
            metric_id: metric!.id,
            name: name,
            sender: sender,
            trigger_time: timeOfDay,
        };

        try {
            await flowsCtx.create(draft);
            navigate("/flows");
        } catch (e) {
            errorMsg = `API response: ${(e as Error).message}`;
            alertCtx.addAlert("Unable to save flow", errorMsg, "cruelRuby", "OTHER_ERROR")
        }
    };

    useEffect(() => {
        setSender(s => s || accountCtx.defaultSender);
    }, [accountCtx.defaultSender])

    useEffect(() => {
        setKlaviyoMessages([]);
        setKlaviyoMessage(undefined);
        if (klaviyoFlow) {
            (async () => {
                setKlaviyoMessagesAreLoading(true);
                const messages = await flowsCtx.getMessages(klaviyoFlow)
                setKlaviyoMessages(messages);
                setKlaviyoMessagesAreLoading(false);
            })()
        } else {
            setKlaviyoMessagesAreLoading(false);
        }
    }, [klaviyoFlow]);
    
    return (
        <>
            <HeaderBar title="Create flow" />

            <div className="pageBeginMargin">
                <div
                    className="sectionContainerWidth  flex flex-col justify-center bg-whiteSmoke w-full transform transition ease-in-out duration-500"
                >
                    <div className="grid grid-cols-1 gap-5 w-full">
                        <div className="relative z-40">
                            <div className='flex flex-col bg-white sectionShadow px-16 py-8 gap-4 border border-newSilver'>
                                <div className="flex flex-row justify-between items-center" >
                                    <p className="text-lg font-medium text-tamahagane leading-8">Flow details</p>
                                </div>
                                <div className="flex gap-20">
                                    <div className="flex w-full 2xl:defaultSectionWidth gap-8">
                                        <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                            <p className="text-base font-semibold">Name</p>
                                            <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="focus:ring-0 apperance-none outline-0 text-base py-3 px-3 border h-12 focus:border-granitine border-granitine rounded-default w-full" tabIndex={0}></input>
                                        </div>
                                        <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                            <p className="text-base font-semibold">Sender</p>
                                            <div className='h-full' key={5}>
                                                {(() => {
                                                    const senders = accountCtx.allowedSenders;
                                                    const data = senders.map((value, id) => ({value, title: value, id}));
                                                    return <SimpleDropdown2
                                                        placeholder={"Choose sender"}
                                                        data={data}
                                                        selected={data.find(x => x.value === sender)}
                                                        selectFn={(item) => {setSender(item?.value)}}
                                                    />
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="relative z-30">
                            <div className='flex flex-col bg-white sectionShadow px-16 py-8 gap-4 border border-newSilver'>
                                <div className="flex flex-row justify-between items-center" >
                                    <p className="text-lg font-medium text-tamahagane leading-8">Trigger</p>
                                </div>
                                <div className="flex flex-col gap-10">
                                    <div className="flex w-full 2xl:defaultSectionWidth gap-8">
                                        <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                            <p className="text-base font-semibold">Klaviyo flow</p>
                                            {(() => {
                                                const data= klaviyoFlows.map((value, id) => ({value, id, title: value.name}));
                                                return <SimpleDropdown2
                                                    placeholder={"Choose"}
                                                    data={data}
                                                    selected={data.find(x => x.value === klaviyoFlow)}
                                                    selectFn={(item) => {setKlaviyoFlow(item?.value)}}
                                                    search={true}
                                                />
                                            })()}
                                        </div>
                                        <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                            <p className="text-base font-semibold">Email</p>
                                            {(() => {
                                                const data= klaviyoMessages.map((value, id) => ({value, id, title: value.name}));
                                                return <SimpleDropdown2
                                                    placeholder={(klaviyoFlow && klaviyoMessages.length === 0)? "No emails in this flow" : "Choose"}
                                                    disabled={!klaviyoFlow || klaviyoMessages.length === 0}
                                                    data={data}
                                                    loading={klaviyoMessagesAreLoading}
                                                    selected={data.find(x => x.value === klaviyoMessage)}
                                                    selectFn={(item) => {setKlaviyoMessage(item?.value)}}
                                                    search={true}
                                                />
                                            })()}
                                        </div>
                                    </div>
                                    <div className="flex w-full 2xl:defaultSectionWidth gap-8">
                                        <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                            <p className="text-base font-semibold">Has / has not</p>
                                            {(() => {
                                                const data=[
                                                    {value: 'has', title: 'Has', id: 0},
                                                    {value: 'has_not', title: 'Has not', id: 1},
                                                ]
                                                return <SimpleDropdown2
                                                    placeholder="Choose"
                                                    data={data}
                                                    selected={data.find(x => x.value === hasOrHasNot)}
                                                    selectFn={(item) => {setHasOrHasNot(item?.value)}}
                                                />
                                            })()}
                                        </div>
                                        <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                            <p className="text-base font-semibold">Metric</p>
                                            {(() => {
                                                const data= allowedMetrics.map((value, id) => ({value, id, title: value.name}));
                                                return <SimpleDropdown2
                                                    placeholder={"Choose metric"}
                                                    data={data}
                                                    selected={data.find(x => x.value === metric)}
                                                    selectFn={(item) => {setMetric(item?.value)}}
                                                />
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="relative z-20">
                            <>
                                
                                <div className="flex sectionShadow border border-newSilver">
                                    <div className="flex flex-col px-16 pt-8 pb-16 w-full 3xl:w-[70%]  bg-white border-r border-gray">
                                        <div className="defaultSectionWidth">
                                            <div className="mb-8 flex justify-between items-center">
                                                <p className="text-lg font-medium text-tamahagane leading-8">
                                                    {name || "Untitled flow"}
                                                </p>
                                                <HiPencilAlt className="w-7 h-7 text-birchwood" />
                                            </div>

                                            <div className="mb-4 relative rounded-default h-[26rem] border-solid border focus:border-gunMetal25 border-gunMetal25 overflow-auto">
                                                <textarea
                                                    onChange={handleMessageInput}
                                                    value={rawMessage}
                                                    className={classNames(
                                                        "ring-0 focus:ring-0 appearance-none resize-none text-sm text-gunMetal text-clip outline-0 py-2 px-3 h-3/4 w-full border-none"
                                                    )}
                                                    tabIndex={5}
                                                ></textarea>
                                            </div>

                                            <table className="table-auto w-full mb-10">
                                                <thead>
                                                <tr>
                                                    <th className="">
                                                        <div className="flex">
                                                            <p className="text-sm whitespace-pre font-semibold">
                                                                Characters
                                                            </p>
                                                            <div className="w-26 flex">
                                                                <p
                                                                    className={`text-sm opacity-70 font-normal ml-1 `}
                                                                >
                                                                    {`${smsInfo.charCount} / ${smsInfo.maxLengthForParts}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className="">
                                                        <div className="flex">
                                                            <p className="text-sm font-semibold">
                                                                SMS
                                                            </p>
                                                            <p className="text-right ml-2 text-sm font-normal opacity-70">
                                                                {smsInfo.parts}
                                                            </p>
                                                        </div>
                                                    </th>
                                                    <th className="">
                                                        <p className="text-sm font-semibold col-span-1 truncate">
                                                            Special chars
                                                            <span className="font-normal text-sm opacity-70 ">
                                                {" "}
                                                                {smsInfo.specialChars.length ===
                                                                    0 && "None"}
                                                                {smsInfo.specialChars.length <=
                                                                    3 &&
                                                                    smsInfo.specialChars.join(
                                                                        ""
                                                                    )}
                                                                {smsInfo.specialChars.length >
                                                                    3 &&
                                                                    smsInfo.specialChars
                                                                        .slice(0, 3)
                                                                        .join("") +
                                                                    " +" +
                                                                    (smsInfo.specialChars
                                                                            .length -
                                                                        3)}
                                            </span>
                                                        </p>
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            
                                        </div>
                                    </div>
                                    <div className="bg-white 3xl:w-[30%] md:w-[40%] flex flex-col items-center px-8 relative lg:w-full">
                                        <div className="phoneImg">
                                            <img src={phoneImg} alt="" className="mt-8" />
                                            <div className=" absolute mt-16 top-0 text-center w-full">
                                                <div className="flex flex-col items-center">
                                                    <div className="border-b border-gray w-9/12 pb-4 pt-6">
                                                        <p className="text-center text-sm mt-0.5 text-black">
                                                            {sender || "Sender"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="absolute top-32 w-full ">
                                                <div className="w-4/5 mx-auto rounded-xl rounded-br-none mt-3">
                                                    <p
                                                        className={`text-xs leading-tight break-words p-3 whitespace-pre-wrap max-h-96 overflow-auto custom-scrollbar ${
                                                            !rawMessage && "italic"
                                                        } text-tamahagane`}
                                                    >
                                                        {parsedMessage || "No message to preview."}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-8 mb-8">
                                            <Button
                                                text="Preview"
                                                style="rounded-default hover:bg-decoGray mx-auto bg-tamahagane h-12 w-48 flex items-center"
                                                textStyles="text-sm font-medium text-center text-white"
                                                handleClick={sendPreview}
                                            ></Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>

                        <div>
                            <div className="relative z-40">
                                <div className='flex flex-col bg-white sectionShadow px-16 py-8 gap-4 border border-newSilver'>
                                    <div className="flex flex-row justify-between items-center" >
                                        <p className="text-lg font-medium text-tamahagane leading-8">Settings</p>
                                    </div>
                                    <div className="flex gap-20">
                                        <div className="flex w-full 2xl:defaultSectionWidth gap-8">
                                            <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                                <p className="text-base font-semibold">Delay trigger by</p>
                                                {(() => {
                                                    const data=[
                                                        {value: 1, title: '1 day', id: 1},
                                                        {value: 2, title: '2 days', id: 2},
                                                        {value: 3, title: '3 days', id: 3},
                                                        {value: 4, title: '4 days', id: 4},
                                                        {value: 5, title: '5 days', id: 5},
                                                        {value: 6, title: '6 days', id: 6},
                                                        {value: 7, title: '7 days', id: 7},
                                                        {value: 8, title: '8 days', id: 8},
                                                        {value: 9, title: '9 days', id: 9},
                                                        {value: 10, title: '10 days', id: 10},
                                                        {value: 11, title: '11 days', id: 11},
                                                        {value: 12, title: '12 days', id: 12},
                                                        {value: 13, title: '13 days', id: 13},
                                                        {value: 14, title: '14 days', id: 14},
                                                    ]
                                                    return <SimpleDropdown2
                                                        placeholder={"Choose"}
                                                        data={data}
                                                        selected={data.find(x => x.value === delay)}
                                                        selectFn={(item) => {setDelay(item?.value || 0)}}
                                                    />
                                                })()}
                                            </div>
                                            <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                                <p className="text-base font-semibold">Time of day to send</p>
                                                {(() => {
                                                    const data=[
                                                        {value: '8:00', title: '08:00', id: 0},
                                                        {value: '9:00', title: '09:00', id: 1},
                                                        {value: '10:00', title: '10:00', id: 2},
                                                        {value: '11:00', title: '11:00', id: 3},
                                                        {value: '12:00', title: '12:00', id: 4},
                                                        {value: '13:00', title: '13:00', id: 5},
                                                        {value: '14:00', title: '14:00', id: 6},
                                                        {value: '15:00', title: '15:00', id: 7},
                                                        {value: '16:00', title: '16:00', id: 8},
                                                        {value: '17:00', title: '17:00', id: 9},
                                                        {value: '18:00', title: '18:00', id: 10},
                                                        {value: '19:00', title: '19:00', id: 11},
                                                        {value: '20:00', title: '20:00', id: 12},
                                                        {value: '21:00', title: '21:00', id: 13},
                                                    ]
                                                    return <SimpleDropdown2
                                                        placeholder={"Choose"}
                                                        data={data}
                                                        selected={data.find(x => x.value === timeOfDay)}
                                                        selectFn={(item) => {setTimeOfDay(item?.value || '')}}
                                                    />
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-20">
                                        <div className="flex w-full 2xl:defaultSectionWidth gap-8">
                                            <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                                                <p className="text-base font-semibold">Number format in flow</p>
                                                {(() => {
                                                    const data= validCCs.map((x, id) => ({
                                                        value: x.alpha2,
                                                        title: x.country,
                                                        id
                                                    }))
                                                    return <SimpleDropdown2
                                                        placeholder={"Choose"}
                                                        data={data}
                                                        selected={data.find(x => x.value.toLowerCase() === cc.toLowerCase())}
                                                        selectFn={(item) => {setCC(item?.value || '')}}
                                                    />
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mb-4">
                                        <Button handleClick={onSubmit} text="Confirm" bgColor="bg-wilmington" textColor="white" textStyles="text-sm font-medium" style="w-48 rounded-default " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewFlowRoute;
