import { classNames, getCookie, useDelete } from "helpers";
import { useContext, useEffect, useRef, useState } from "react";
import AlertContext from "store/alert-context";
import { useProgressBar } from "store/progressbar-context";
import { useGroupAPI, useGroupData } from "store/group-context";
import { printUnixTime, formatLastCampaign } from "../helpers";
import RemovedAlert from "../RemovedAlert";
import './index.css';
import ProgressBar from "./ProgressBar";

interface TableRowProps {
    checked: boolean,
    data: {
        id: number,
        _id?: string,
        isNew?: boolean;
        name: string,
        mixed?: string[],
        size: number,
        source_type: string,
        source: string,
        sync?: {
            last_sync: number
        },
        progress?: {
            requestAttempts: number;
            state: "pending" | "failed" | "success"
        },
        last_campaign?: string,
        loading?: boolean,
        time_left?: number

    },
    functions: {
        updateMergeGroups: Function
        handleRemoveGroup: Function
    }
}

const TableRow = (props: TableRowProps) => {
    const { id, _id, name, mixed, size, source_type, source, sync, loading, progress } = props.data;
    const { updateMergeGroups, handleRemoveGroup } = props.functions;

    const alertCtx = useContext(AlertContext);
    const [progressPercent, setProgressPercent] = useProgressBar(name);
    const groupAPI = useGroupAPI();

    const token = getCookie("token");

    const [currentProgress, setCurrentProgress] = useState(0);
    const [showProgress, setShowProgress] = useState((loading || props.data.isNew));
    const [isRemoving, setIsRemoving] = useState(false);
    const [removing, setRemoving] = useState(false);

    const dateFormatOptions: any = { day: 'numeric', month: 'short', year: 'numeric' }
    let created = new Date();
    if (_id) {
        let timestamp = _id.toString().substring(0, 8)
        created = new Date(parseInt(timestamp, 16) * 1000)
    }

    const removeTimeoutRef = useRef(null as any);

    const scrubbedName = (() :string => {
        const match = name.match(/^Klaviyo (?:list|segment) "(.*)"$/);
        return match ? match[1].replace(/\\"/g, '"') : name;
    })()

    useEffect(() => {
        if (props.data.isNew) {
            setTimeout(() => {
                setShowProgress(false);
            }, 2000)
        }
    }, [])

    useEffect(() => {
        if (props.data.isNew && !showProgress) {
            let name = props.data.name.replace(/(?:Klaviyo|Mailchimp|Emarsys) (?:list|segment) "(.*)"$/, "$1");
            if (props.data.size > 0) {
                alertCtx.addAlert(`Finished import`, `The group "${name}" was imported.`, "darkAqua", "CAMPAIGN_SUCCESS")
            } else {
                alertCtx.addAlert(`Error`, `The group "${name}" is empty.`, "cruelRuby", "CAMPAIGN_SUCCESS")
            }
            groupAPI.removeIsNew(props.data.id)
        }
    }, [showProgress, props.data])

    useEffect(() => {
        if (loading && progress) {
            if (progress.state === "success") {
                setProgressPercent(100);
            } else {
                const step = Math.max(0.2, Math.random() * 10);

                const newProgress = progressPercent + (99 - progressPercent) * (step / 100);

                setProgressPercent(newProgress)
            }
        }

    }, [progress])

    const cancelRemoveGroup = () => {
        clearTimeout(removeTimeoutRef.current)
        setRemoving(false);
        setIsRemoving(false);
    }

    if ((props.data.size === 0) && !loading) {
        return null
    } else {

        return (
            <>
                <tr
                    className={
                        classNames(
                            "border-b border-gray py-4 relative",
                            props.data.isNew && !showProgress && "new-item-pulse",
                            removing && "remove-tr"
                        )}
                    key={_id}>
                    <td className="w-12 py-4 pl-2">
                        <input disabled={isRemoving} checked={props.checked} type="checkbox" onClick={() => updateMergeGroups(props.data)} className="rounded border-gray text-tamahagane focus:ring-tamahagane w-6 h-6 cursor-pointer" />
                    </td>
                    <td>
                        <p className={classNames(
                            "text-base truncate ",
                            isRemoving && "opacity-50"
                        )}>{scrubbedName}</p>
                    </td>
                    <td className="w-44">
                        {false && loading &&
                            <div className="flex gap-2">
                                <div className="firstdot-flashing"></div>
                                <div className="seconddot-flashing"></div>
                                <div className="thirddot-flashing"></div>
                            </div>
                        }
                        {
                            false && loading &&
                            <p className={classNames(
                                "text-base importing-text",
                                isRemoving && "opacity-50"
                            )}>Importerar grupp</p>
                        }
                        {
                            false && loading &&
                            <div className="flex progress-bar-parent border-[0.5px] border-zinc-300 rounded-lg w-2/4 overflow-hidden">
                                <div className={classNames(
                                    false && "test-progress",
                                    "bg-acanthusLeaf",
                                    "h-[1.6rem]",
                                    "progress-bar-shadow",
                                )}
                                    style={{ width: currentProgress + "%" }}
                                >
                                </div>
                            </div>
                        }
                        {
                            showProgress &&
                            <div className="flex progress-bar-parent relative border-[0.5px] border-zinc-400 bg-white rounded-md w-4/5 overflow-hidden">
                                <div className={classNames(
                                    false && "test-progress",
                                    "bg-acanthusLeaf",
                                    "h-[1.6rem]",
                                    "progress-bar-shadow",
                                    "smooth-bar",
                                    "absolute z-0"
                                )}
                                    style={{ width: (props.data.isNew ? 100 : progressPercent) + "%" }}
                                >
                                </div>
                                <div className={classNames(
                                    "flex gap-2 w-full",
                                    "h-[1.6rem] z-10"
                                )}
                                >
                                </div>
                            </div>

                        }
                        {!showProgress && <p className={
                            classNames(
                                "text-base w-20",
                                isRemoving && "opacity-50"
                            )
                        }>{size}</p>}
                    </td>
                    <td className="w-44">
                        <p className={classNames(
                            "text-base capitalize",
                            isRemoving && "opacity-50"
                        )}>{source_type || "-"}</p>
                    </td>
                    <td className="w-44">
                        <p className={classNames(
                            "text-base capitalize",
                            isRemoving && "opacity-50"
                        )}
                        >{source || "-"}</p>
                    </td>
                    <td className="w-56">
                        <p className={classNames(
                            "text-base",
                            isRemoving && "opacity-50"
                        )}
                        >{sync ? printUnixTime(sync.last_sync) : "-"}</p>
                    </td>
                    <td className=" w-32">
                        <p className={classNames(
                            "text-base",
                            isRemoving && "opacity-50"
                        )}
                        >{created ? created.toLocaleDateString('en-SE', dateFormatOptions) : "-"}</p>
                    </td>
                    <td className="ignore">
                        <div className="flex items-center">
                            {!isRemoving ? (
                                <div className="cursor-pointer flex items-center w-full justify-center" onClick={() => handleRemoveGroup(props.data)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            ) : (
                                <div className="flex justify-center items-center m-0 w-28 relative z-10" onClick={() => cancelRemoveGroup()}>
                                    <p className="text-base z-10 absolute cursor-pointer underline">Ångra</p>
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            </>
        )
    }
}

export default TableRow;