interface ButtonProps {
    handleClick?: Function | any;
    text?: string;
    disabled?: boolean;
    bgColor?: string;
    textColor?: string;
    style?: string;
    textStyles?: string;
    children?: any;
    renderText?: () => void;
}
function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const Button = (props: ButtonProps) => {
    return (
        <div
            onClick={!props.disabled && props.handleClick ? props.handleClick : undefined}
            className={classNames(
                props.bgColor,
                "transition-all",
                "px-3 py-3 h-12",
                "select-none",
                "cursor-pointer",
                "text-center",
                "flex justify-center items-center",
                "min-w-[12rem]",
                props.disabled
                    ? "opacity-50 cursor-not-allowed"
                    : "active:scale-90 ",
                props.style || "",
                "rounded-default",
            )}
        >
            {props.text ? (
                <p
                    className={`text-base text-${props.textColor} ${props.textStyles} `}
                >
                    {props.text}
                </p>
            ) : undefined}
            {props.renderText ? props.renderText() : undefined}
        </div>
    );
};

export default Button;
