import { Transition } from "@headlessui/react";
import { classNames } from "helpers";
import { useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";

const ImporterPickerWithDropdown = (props: any) => {
    const { segmentData, selectedList, selectedImports, setSelectedImports, selected, canImportMainList } = props;

    const [showSourceType, setShowSoureType] = useState("")
    const [open, setOpen] = useState(false)

    const [searchQuery, setSearchQuery] = useState("")

    const dropdownRef = useRef(null as any)

    const openDropdown = (e: any) => {
        setOpen(oldState => !oldState);
    }

    const closeDropdown = (e: any) => {
        console.log("Target: ", e.currentTarget, e.relatedTarget, e.currentTarget.contains(e.relatedTarget))
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setOpen(false)
            setShowSoureType("")
        }
    }

    const handleSearch = (e: any) => {
        setSearchQuery(e.target.value);
    }

    const handleHover = (e: any, sourceType: string) => {
        console.log("Target 2: ", sourceType)
        setShowSoureType(sourceType)
        dropdownRef.current.focus()
    }

    if (props.disabled) {
        return (
            <div className="relative w-full min-w-fit outline-none" onBlur={closeDropdown} tabIndex={2}>
                <button disabled={props.disabled}

                    className={
                        classNames(
                            "border font-inherit text-inherit appearance-none outline-none flex flex-row justify-between items-center border-granitine rounded-default px-3 cursor-not-allowed w-full bg-neutral-100 h-12",
                            props.headerButtonStyles
                        )
                    } onClick={openDropdown} ref={props.dropdownRef} >
                    <p className={"text-base " + props.textStyles}>{!selected ? props.placeholder : selected}</p>
                    {open ? <HiChevronDown className="w-6 h-6 text-tamahagane" /> : <HiChevronRight className="w-6 h-6 text-tamahagane" />}
                </button>
            </div>
        )
    } else {
        return (
            <div className="w-full min-w-fit outline-none relative" ref={dropdownRef} onBlur={closeDropdown} tabIndex={2}>
                <button disabled={props.disabled}
                    className={
                        classNames(
                            "border font-inherit text-inherit appearance-none outline-none flex flex-row items-center justify-between border-granitine rounded-default px-3 cursor-pointer w-full h-12 bg-white",
                            props.headerButtonStyles
                        )
                    } onClick={openDropdown}
                >
                    <p className={"text-base text-left w-72 truncate " + props.textStyles}>{!selected ? props.placeholder : selected}</p>
                    {open ? <HiChevronDown className="w-6 h-6 text-tamahagane" /> : <HiChevronRight className="w-6 h-6 text-tamahagane" />}
                </button>
                <Transition
                    show={open}
                    enter="duration-[55ms]"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute z-30 left-0 top-full mt-1  md:w-[20rem] xl:w-[320px] bg-white dropdownShadow border border-gray will-change-filter rounded-default ">
                        <div className="flex h-full w-full ">
                            <div className="h-[20rem] min-h-0 w-full relative">
                                <p className="font-semibold text-base pl-4 py-4">{selectedList.name}</p>

                                {

                                    canImportMainList &&
                                    <div className="w-full relative hover:bg-birchwood/20" onMouseDown={(e: any) => handleHover(e, selectedList.type)} >
                                        <div className={classNames(
"pl-8 p-4 font-inherit flex justify-between items-center text-inherit appearance-none transition-all duration-200 cursor-pointer  max-w-full w-full truncate " ,

                                                    showSourceType === selectedList.type ? "bg-birchwood/20" : ""
                                        )}>
                                            <p className="text-base first-letter:uppercase mr-1">Master list</p>
                                            <HiChevronRight className="w-6 h-6 text-tamahagane" />
                                        </div>
                                        {
                                            showSourceType === selectedList.type &&
                                            <div className="absolute z-30 -right-full top-0  bg-white w-full dropdownShadow border border-gray will-change-filter rounded-default overflow-auto" >
                                                <div className="overflow-auto h-72 ">
                                                    <button
                                                        className={`p-4 font-inherit flex items-center text-inherit appearance-none transition-all duration-200 cursor-pointer hover:bg-birchwood/20 max-w-full w-full truncate`}
                                                        key={selectedList.key}
                                                        onClick={() => {
                                                            if (selectedImports.findIndex((item:any) => selectedList.key === item.id)) {
                                                                console.log("Selected list: ", selectedList, selectedImports)
                                                                let mainListIndex = segmentData.findIndex((mainList: any) => mainList.id === selectedList.key)
                                                                let { id, name, type } = segmentData[mainListIndex]
                                                                return setSelectedImports([...selectedImports, { id, name, type, list_id: id }])
                                                            } else {
                                                                return setSelectedImports(selectedImports.filter((oldSource: any) => oldSource.id !== selectedList.key))
                                                            }
                                                        }}
                                                    >

                                                        <input type="checkbox" onChange={() => undefined} checked={selectedImports.findIndex((mainList: any) => mainList.id === selectedList.key) !== -1} className="rounded border-gray text-ocean focus:ring-darkAqua col-span-1 self-center w-6 h-6 mr-4 cursor-pointer" />
                                                        <p className="text-left text-base w-full truncate select-none">{selectedList.name}</p>
                                                    </button>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    segmentData.find((item: any) => item.id === selectedList.key).children.map((sourceType: any) => {
                                        return (
                                            <div key={sourceType.type} className="w-full relative" onMouseDown={(e:any) => handleHover(e, sourceType.type)} >
                                                <div className={classNames(
                                                    "pl-8 p-4 font-inherit flex justify-between items-center text-inherit appearance-none transition-all duration-200 cursor-pointer  max-w-full w-full truncate hover:bg-birchwood/20",
                                                    showSourceType === sourceType.type ? "bg-birchwood/20" : ""

                                                    )} >
                                                    <p className="text-base first-letter:uppercase mr-1"> {sourceType.type}</p>
                                                    <HiChevronRight className="w-6 h-6 text-tamahagane" />
                                                </div>
                                                {
                                                    showSourceType === sourceType.type &&
                                                    <div className="absolute z-30 left-full top-0 bg-white w-[650px] dropdownShadow border border-gray will-change-filter rounded-default overflow-auto" >
                                                        <div className="flex border-solid items-center p-4">
                                                            <BsSearch className="w-5 h-5 mr-4 text-gunMetal50" />
                                                            <div className="p-2 flex w-full border border-gray rounded-default">
                                                                <input name="q" type="text" autoComplete="off" aria-autocomplete="none" autoCorrect="off" onChange={handleSearch} className="border-0 focus:ring-0 apperance-none w-full ml-1 outline-none text-base " placeholder="Search"></input>
                                                            </div>
                                                        </div>
                                                        <div className="overflow-auto max-h-72 ">
                                                            {
                                                                sourceType.children.filter((source: any) => {
                                                                    if (searchQuery === "") {
                                                                        return source
                                                                    }
                                                                    let match = true;
                                                                    if (searchQuery) {
                                                                        if (
                                                                            searchQuery.length >= 2 && source.name.toLowerCase().includes(searchQuery)
                                                                        ) {
                                                                        } else {
                                                                            match = false;
                                                                        }
                                                                    }
                                                                    if (match) {
                                                                        return source;
                                                                    }
                                                                }).map((source: any) => {
                                                                    return (
                                                                        <button
                                                                            className={`p-4 font-inherit flex items-center text-inherit appearance-none transition-all duration-200 cursor-pointer hover:bg-birchwood/20 max-w-full w-full truncate`}
                                                                            key={source.id}
                                                                            onClick={() => {
                                                                                if (selectedImports.indexOf(source) === -1) {
                                                                                    return setSelectedImports([...selectedImports, source])
                                                                                } else {
                                                                                    return setSelectedImports(selectedImports.filter((oldSource: any) => oldSource !== source))
                                                                                }
                                                                            }}
                                                                        >

                                                                            <input type="checkbox" onChange={() => undefined} checked={selectedImports.indexOf(source) !== -1} className="rounded border-gray text-tamahagane focus:ring-tamahagane col-span-1 self-center w-6 h-6 mr-4 cursor-pointer" />
                                                                            <p className="text-left text-base w-full truncate select-none">{source.name}</p>
                                                                        </button>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        )
    }

}

export default ImporterPickerWithDropdown