import ImageWithMarkers from "components/ImageWithMarkers"
import {stat} from "fs/promises";
import {classNames} from "helpers";
import React, {useContext, useEffect, useState} from "react";
import StatsContext from "store/stats-context";
import NordicMap from "./Nordic_map.png";
import AccountContext from "../../../store/account-context";

const colors = ["#88958D", "#BC9CB0", "#FACF9A", "#BC9CB0", "#FACF9A"]

type CountryData = { name: string, sent: number, score: number };

const Map = (props: any) => {

    const statsCtx = useContext(StatsContext);
    const accountCtx = useContext(AccountContext);
    const [data, setData] = useState<CountryData[]>([]);

    useEffect(() => {
        const prices = accountCtx.prices;
        let countries: { [key: string]: CountryData } = {};
        Object.keys(prices).forEach(cc => {
            countries[cc] = {
                name: cc,
                sent: 0,
                score: 0,
            }
        })
        countries['default'] = {
            name: 'Other',
            sent: 0,
            score: 0,
        }

        if (statsCtx.stats) {
            const stats = statsCtx.stats.summary.countries;
            const entries = Object.entries(stats);
            entries.forEach(([cc, v]) => {
                const k = (cc in countries)? cc : 'default';
                countries[k].sent += v.texts.total;
            });
            const min = Math.min(...Object.values(countries).map(v => v.sent));
            const max = Math.max(...Object.values(countries).map(v => v.sent));
            Object.values(countries).forEach(x => {
                if (max === 0) {
                    x.score = 1;
                } else if (max === min) {
                    x.score = 100;
                } else {
                    x.score = Math.round((x.sent / (max-min))* 100) || 1;
                }
            });
        }

        let result = Object.keys(countries)
            .filter(k => k !== 'default')
            .map(k => countries[k])
            .sort((a,b) => {
                return (a.score === b.score)? (b.sent - a.sent) : b.score - a.score;
            })
            .concat(countries['default']);

        setData(result);
    }, [statsCtx.stats, accountCtx.prices]);

    function formatNumber(number: number) {
        if (number >= 1000000) {
            const millions = number / 1000000;
            return `${millions.toFixed(2)}M`;
        } else if (number >= 1000) {
            const thousands = number / 1000;
            return `${thousands.toFixed(1)}K`;
        } else {
            return number.toString();
        }
    }

    return (
        <div className="w-2/5 flex flex-col px-8 py-4 bg-white rounded-lg statCardShadow">
            <p className="text-base font-semibold">SMS traffic</p>
            {data.map((v, index) =>
                <div className="flex mt-8">
                    <p className="text-base mr-4 w-14 font-medium flex-shrink-0">{v.name}</p>
                    <div
                        className={classNames("h-6 w-3/4 mr-4 rounded-md", `bg-[${colors[index]}]`)}
                        style={{width: `${v.score}%`, backgroundColor: colors[index % colors.length]}}
                    ></div>
                    <p className="text-base font-medium">{formatNumber(v.sent)}</p>
                </div>
            )}
 {/*          <div className='relative h-[18.28rem] w-full 2xl:w-[36rem] 2xl:h-[20.57rem] overflow-hidden object-contain'>
                <img src={NordicMap} alt="Map" className=' w-128 h-[18.28rem] 2xl:w-[36rem] 2xl:h-[20.57rem] absolute '/> 
           </div>*/}

        </div>
    )
}

export default Map