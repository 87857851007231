import { DateObject } from "components/RangeCalendar";
import SearchableDropdown from "components/SearchableDropdown";
import { SimpleDropdown, SingleItem } from "components/SimpleDropdown";
import { ReactNode, useEffect, useState } from "react";
import StatCard from "../StatCard";
import StatGraph, { GraphDataPoint } from "../StatGraph";

type PerformanceViewProps = {
    statCards?: Array<string>,
    startDate?: DateObject | null,
    endDate?: DateObject | null,
    prettyDateRange?: string
}

type SelectedStat = {
    title: string,
    data: Array<GraphDataPoint>,
    graphType: "linechart",
    id: string,
    dataUnit: string,
    totalData: any,
    icon: ReactNode
};

const performanceViewCards: Array<SelectedStat> = [
    {
        title: "EPM",
        data: [
            { date: '2023-01-01', data: 200 },
            { date: '2023-02-10', data: 100 },
            { date: '2023-02-15', data: 300 },
            { date: '2023-02-20', data: 450 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 700 },
        ],
        graphType: "linechart",
        dataUnit: "Sent SMS",
        id: "totalvolume",
        totalData: "56.256",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>
    },
    {
        title: "Costs",
        data: [
            { date: '2023-01-01', data: 800 },
            { date: '2023-02-10', data: 200 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "Delivered SMS",
        id: "delivered",
        totalData: "56.156 (98.3%)",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>

    },
    {
        title: "Conversion rate",
        data: [
            { date: '2023-01-01', data: 100 },
            { date: '2023-01-15', data: 200 },
            { date: '2023-01-20', data: 300 },
            { date: '2023-01-25', data: 400 },
            { date: '2023-02-05', data: 500 },
            { date: '2023-02-10', data: 300 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "SEK saved",
        id: "smartsending",
        totalData: "3.164 SEK",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
        </svg>

    },
    {
        title: "AOV",
        data: [
            { date: '2023-01-01', data: 1000 },
            { date: '2023-01-15', data: 2000 },
            { date: '2023-01-20', data: 3000 },
            { date: '2023-01-25', data: 4000 },
            { date: '2023-02-05', data: 5000 },
            { date: '2023-02-10', data: 6000 },
            { date: '2023-02-15', data: 7000 },
            { date: '2023-02-20', data: 8000 },
            { date: '2023-02-21', data: 9000 },
            { date: '2023-02-28', data: 1000 },
        ],
        graphType: "linechart",
        dataUnit: "Unique Contacts reached",
        id: "uniquereached",
        totalData: "43.123",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
        </svg>

    },
    {
        title: "Unsubscrive rate",
        data: [
            { date: '2023-01-01', data: 50 },
            { date: '2023-01-15', data: 30 },
            { date: '2023-01-20', data: 10 },
            { date: '2023-02-05', data: 20 },
            { date: '2023-02-10', data: 10 },
            { date: '2023-02-15', data: 4 },
            { date: '2023-02-20', data: 2 },
            { date: '2023-02-21', data: 3 },
        ],
        graphType: "linechart",
        dataUnit: "Unsubscribes",
        id: "unsubscribed",
        totalData: "0.003%",
        icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
        </svg>
        )
    },
    {
        title: "ROI per SMS",
        data: [
            { date: '2023-01-01', data: 200 },
            { date: '2023-02-10', data: 100 },
            { date: '2023-02-15', data: 300 },
            { date: '2023-02-20', data: 450 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 700 },
        ],
        graphType: "linechart",
        dataUnit: "Sent SMS",
        id: "totalvolume",
        totalData: "56.256",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>
    },
    {
        title: "Revenue total",
        data: [
            { date: '2023-01-01', data: 800 },
            { date: '2023-02-10', data: 200 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "Delivered SMS",
        id: "delivered",
        totalData: "56.156 (98.3%)",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>

    },
    {
        title: "CTR",
        data: [
            { date: '2023-01-01', data: 100 },
            { date: '2023-01-15', data: 200 },
            { date: '2023-01-20', data: 300 },
            { date: '2023-01-25', data: 400 },
            { date: '2023-02-05', data: 500 },
            { date: '2023-02-10', data: 300 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "SEK saved",
        id: "smartsending",
        totalData: "3.164 SEK",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
        </svg>

    },
    {
        title: "NoF Transactions",
        data: [
            { date: '2023-01-01', data: 1000 },
            { date: '2023-01-15', data: 2000 },
            { date: '2023-01-20', data: 3000 },
            { date: '2023-01-25', data: 4000 },
            { date: '2023-02-05', data: 5000 },
            { date: '2023-02-10', data: 6000 },
            { date: '2023-02-15', data: 7000 },
            { date: '2023-02-20', data: 8000 },
            { date: '2023-02-21', data: 9000 },
            { date: '2023-02-28', data: 1000 },
        ],
        graphType: "linechart",
        dataUnit: "Unique Contacts reached",
        id: "uniquereached",
        totalData: "43.123",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
        </svg>

    },
    {
        title: "Top OV",
        data: [
            { date: '2023-01-01', data: 50 },
            { date: '2023-01-15', data: 30 },
            { date: '2023-01-20', data: 10 },
            { date: '2023-02-05', data: 20 },
            { date: '2023-02-10', data: 10 },
            { date: '2023-02-15', data: 4 },
            { date: '2023-02-20', data: 2 },
            { date: '2023-02-21', data: 3 },
        ],
        graphType: "linechart",
        dataUnit: "Unsubscribes",
        id: "unsubscribed",
        totalData: "0.003%",
        icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
        </svg>
        )
    }
]

const deliveryViewCards: Array<SelectedStat> = [
    {
        title: "Total Volume",
        data: [
            { date: '2023-01-01', data: 200 },
            { date: '2023-02-10', data: 100 },
            { date: '2023-02-15', data: 300 },
            { date: '2023-02-20', data: 450 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 700 },
        ],
        graphType: "linechart",
        dataUnit: "Sent SMS",
        id: "totalvolume",
        totalData: "56.256",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>
    },
    {
        title: "Delivered",
        data: [
            { date: '2023-01-01', data: 800 },
            { date: '2023-02-10', data: 200 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "Delivered SMS",
        id: "delivered",
        totalData: "56.156 (98.3%)",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>

    },
    {
        title: "Smart Sending",
        data: [
            { date: '2023-01-01', data: 100 },
            { date: '2023-01-15', data: 200 },
            { date: '2023-01-20', data: 300 },
            { date: '2023-01-25', data: 400 },
            { date: '2023-02-05', data: 500 },
            { date: '2023-02-10', data: 300 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "SEK saved",
        id: "smartsending",
        totalData: "3.164 SEK",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
        </svg>

    },
    {
        title: "Unique Contacts reached",
        data: [
            { date: '2023-01-01', data: 1000 },
            { date: '2023-01-15', data: 2000 },
            { date: '2023-01-20', data: 3000 },
            { date: '2023-01-25', data: 4000 },
            { date: '2023-02-05', data: 5000 },
            { date: '2023-02-10', data: 6000 },
            { date: '2023-02-15', data: 7000 },
            { date: '2023-02-20', data: 8000 },
            { date: '2023-02-21', data: 9000 },
            { date: '2023-02-28', data: 1000 },
        ],
        graphType: "linechart",
        dataUnit: "Unique Contacts reached",
        id: "uniquereached",
        totalData: "43.123",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
        </svg>

    },
    {
        title: "Unsubscribe rate",
        data: [
            { date: '2023-01-01', data: 50 },
            { date: '2023-01-15', data: 30 },
            { date: '2023-01-20', data: 10 },
            { date: '2023-02-05', data: 20 },
            { date: '2023-02-10', data: 10 },
            { date: '2023-02-15', data: 4 },
            { date: '2023-02-20', data: 2 },
            { date: '2023-02-21', data: 3 },
        ],
        graphType: "linechart",
        dataUnit: "Unsubscribes",
        id: "unsubscribed",
        totalData: "0.003%",
        icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
        </svg>
        )
    }
]

const exampleCampaignDropdownData = [
    {
        id: 1,
        title: "Campaigns - Over all time",
        value: "overalltime"
    },
    {
        id: 2,
        title: "Testkampanj 27 juni",
        value: "testkampanj"
    },
    {
        id: 3,
        title: "Text2win Summer Spectacular",
        value: "test3"
    },
    {
        id: 3,
        title: "Mobile Madness Flash Sale",
        value: "test4"
    }
]
const PerformanceView = (props: PerformanceViewProps) => {
    const [selectedStat, setSelectedStat] = useState<SelectedStat>();

    useEffect(() => {
        setSelectedStat(performanceViewCards[0])
    }, [])

    return (
        <div className="flex flex-col">
            {props.prettyDateRange && props.prettyDateRange.length > 5 &&
                <div className="flex items-center mb-4">
                    <p className="font-semibold text-base mr-4">Performance: {props.prettyDateRange}</p>
                    <div className="w-1/4 ">
                        <SearchableDropdown placeholder={"Filter by campaign"} data={exampleCampaignDropdownData} setSelection={function (item: SingleItem | null): void {
                            return undefined;
                        } } searchPlaceHolder={"Sök efter kampanj"} />
                    </div>
                </div>
            }
            {selectedStat &&
                <div className="bg-white p-4 rounded-lg sectionShadow">
                    <p className="text-base font-semibold">{selectedStat.title}</p>
                    {<StatGraph type={selectedStat.graphType} data={selectedStat.data} yDataName={selectedStat.title} yDataUnit={selectedStat.dataUnit} />}
                </div>
            }
            <p className="font-semibold text-base mt-12">Rubrik</p>
            <p className="text-sm w-3/4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className="flex gap-8 mt-8">
                <div className="grid grid-cols-5 gap-8 w-full">
                    {performanceViewCards.map((statCard: any) =>
                        <StatCard title={statCard.title} value={statCard.totalData} icon={statCard.icon} handleClick={() => setSelectedStat(statCard)} />
                    )}
                </div>
            </div>
            <div className="border-b border-gray w-full mt-12"></div>
            <p className="font-semibold text-base mt-4">Rubrik</p>
            <p className="text-sm w-3/4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className="flex gap-8 mt-8">
                <div className="grid grid-cols-5 gap-8 w-full">
                    {deliveryViewCards.map((statCard: any) =>
                        <StatCard title={statCard.title} value={statCard.totalData} icon={statCard.icon} handleClick={() => setSelectedStat(statCard)} />
                    )}
                </div>
            </div>
            <div className="border-b border-gray w-full mt-12"></div>
        </div>
    )
}

export default PerformanceView