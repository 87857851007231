import {getCookie, integrationLookup} from 'helpers';
import { createContext, useState } from 'react';

const AccountContext = createContext({
    billingCurrency: "",
    prices: {} as {[key: string]: number},
    dispatchAnyTime: false,
    allowedSenders: [],
    _id: "",
    apiKey: "",
    vatNumber: "None",
    integrations: [] as any[],
    user: {} as any,
    totalContacts: 0,
    defaultSender: "",
    allowedDomains: [] as Array<string>,
    loadData: (data: any) => { }
});

export function AccountContextProvider(props: any) {
    const [account, setAccount] = useState({} as any)
    const [user, setUser] = useState({} as any)

    const fetchAccount = async () => {
        let token = getCookie("token");
        const accountData = await fetch(process.env.REACT_APP_API_BASE + "/account", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        let accountRes = await accountData.json();
        if (accountRes.error && accountRes.error === 403) {
            return false;
        }

        const userData = await fetch(process.env.REACT_APP_API_BASE + "/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const userRes = await userData.json();
        accountRes.account["user"] = userRes.user;
        loadData(accountRes.account);
        return true;
    }

    const loadData = (data: any) => {
        const { 
            billing_currency, 
            prices, 
            dispatch_anytime, 
            allowed_senders, 
            _id, 
            api_key,
            vat_number,
            integrations,
            total_contacts,
            allowed_domains,
            default_sender
        } = data;
    
        const integrationsList = integrations.map((integration: any) => {
            if (integration.integration_id in integrationLookup) {
                return {...integration, url: integrationLookup[integration.integration_id].url}
            } else {
                return {...integration, url: ""}
            }
        })
        setAccount({
            billingCurrency: billing_currency,
            prices,
            dispatchAnyTime: dispatch_anytime,
            allowedSenders: allowed_senders,
            _id,
            apiKey: api_key,
            vatNumber: vat_number,
            integrations: integrationsList,
            totalContacts: total_contacts,
            allowedDomains: allowed_domains,
            defaultSender: default_sender ?? ""
        });
        setUser(data.user);
    }

    const context = {
        billingCurrency: account.billingCurrency,
        prices: account.prices,
        dispatchAnyTime: account.dispatchAnyTime,
        allowedSenders: account.allowedSenders,
        _id: account._id,
        apiKey: account.apiKey,
        vatNumber: account.vatNumber,
        integrations: account.integrations,
        totalContacts: account.totalContacts,
        defaultSender: account.defaultSender,
        allowedDomains: account.allowedDomains,
        user,
        loadData,
        fetchAccount
    };

    return (
        <AccountContext.Provider value={context}>
            {props.children}
        </AccountContext.Provider>
    )
}

export default AccountContext;