import React, { createContext, ReactNode } from "react";

interface ProgressBarContextType {
    progressValues: { [id: string]: number };
    setProgressValues: React.Dispatch<React.SetStateAction<{ [id: string]: number }>>;
}

// Initialize the context with undefined. The actual value will be provided by the ProgressBarProvider
const ProgressBarContext = React.createContext<ProgressBarContextType | undefined>(undefined);


function ProgressBarProvider({ children }: { children: ReactNode }) {
    const [progressValues, setProgressValues] = React.useState<{ [id: string]: number }>({});

    const value = { progressValues, setProgressValues };

    return (
        <ProgressBarContext.Provider value={value}>
            {children}
        </ProgressBarContext.Provider>
    );
}

function useProgressBar(id: string) {
    const context = React.useContext(ProgressBarContext);

    // Throw an error if the context is undefined. This means that the hook is used outside of a ProgressBarProvider
    if (!context) {
        throw new Error('useProgressBar must be used within a ProgressBarProvider');
    }

    const { progressValues, setProgressValues } = context;

    const setProgress = (progress: number) => {
        setProgressValues(oldValues => ({ ...oldValues, [id]: progress }));
    };

    return [progressValues[id] || 0, setProgress] as const;
}

export {
    ProgressBarProvider,
    useProgressBar
}