import { classNames } from "helpers";
import { useState } from "react";
import { BsGear } from "react-icons/bs";
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import {
    HiChartBar,
    HiMail,
    HiUserGroup,
    HiFolderRemove,
    HiLink,
    HiUser,
} from "react-icons/hi";

import JojkaLogo from "../../jojka_logo.png";
import BogioLogo from "../../bogio_logo.png";
import MenuItem from "../MenuItem";
import "./index.css";

const SideBar = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const menuItems = [
        {
            icon: HiChartBar,
            route: "/",
            title: "home",
        },
        {
            icon: (props: any) => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 block m-auto fill-currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M4.606 12.97a.75.75 0 01-.134 1.051 2.494 2.494 0 00-.93 2.437 2.494 2.494 0 002.437-.93.75.75 0 111.186.918 3.995 3.995 0 01-4.482 1.332.75.75 0 01-.461-.461 3.994 3.994 0 011.332-4.482.75.75 0 011.052.134z"
                        clipRule="evenodd"
                    />
                    <path
                        fillRule="evenodd"
                        d="M5.752 12A13.07 13.07 0 008 14.248v4.002c0 .414.336.75.75.75a5 5 0 004.797-6.414 12.984 12.984 0 005.45-10.848.75.75 0 00-.735-.735 12.984 12.984 0 00-10.849 5.45A5 5 0 001 11.25c.001.414.337.75.751.75h4.002zM13 9a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),

            route: "/campaign-dispatches",
            title: "campaigns",
        },
        {
            icon: HiMail,
            route: "/create-campaign",
            title: "create campaign",
        },
         {
            icon: (props: any) => (
                <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 458.624 458.624"
                    className="w-6 h-6 block m-auto fill-currentColor"
                >
                    <path d="M339.588,314.529c-14.215,0-27.456,4.133-38.621,11.239l-112.682-78.67c1.809-6.315,2.798-12.976,2.798-19.871
                    c0-6.896-0.989-13.557-2.798-19.871l109.64-76.547c11.764,8.356,26.133,13.286,41.662,13.286c39.79,0,72.047-32.257,72.047-72.047
                    C411.634,32.258,379.378,0,339.588,0c-39.79,0-72.047,32.257-72.047,72.047c0,5.255,0.578,10.373,1.646,15.308l-112.424,78.491
                    c-10.974-6.759-23.892-10.666-37.727-10.666c-39.79,0-72.047,32.257-72.047,72.047s32.256,72.047,72.047,72.047
                    c13.834,0,26.753-3.907,37.727-10.666l113.292,79.097c-1.629,6.017-2.514,12.34-2.514,18.872c0,39.79,32.257,72.047,72.047,72.047
                    c39.79,0,72.047-32.257,72.047-72.047C411.635,346.787,379.378,314.529,339.588,314.529z"/>
                </svg>
            ), 
            route: "/flows",
            title: "Flows",
        },
        {
            icon: HiUserGroup,
            route: "/groups",
            title: "audience",
        },
        {
            icon: (props: any) => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 block m-auto fill-currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
                        clipRule="evenodd"
                    />
                </svg>
            ),

            route: "/integrations",
            title: "integrations",
        },
        {
            icon: HiUser,
            containerClasses: "mt-auto justify-self-end",
            route: "/logout",
            title: "Log out",
        },
    ];

    return (
        <div
            className={classNames(
                "fixed h-screen z-[100]",
                (process.env.REACT_APP_API_BASE?.includes("app.jojka.com") ||
                    window.location.href.includes("app.jojka.com")) &&
                    false &&
                    "border border-red-500"
            )}
        >
            <motion.div
                className={classNames(
                    "flex flex-col pb-3 bg-white hover:items-start h-full ",
                    open && "drop-shadow-lg"
                )}
                animate={{
                    width: open ? "20rem" : "4.7rem",
                }}
                transition={{
                    ease: "easeInOut",
                    duration: 0.2,
                }}
                initial={false}
                onMouseOver={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                {/*<div className='h-7 mt-8'><p className='text-center text-xl font-normal text-darkAqua'>J</p></div>*/}
                <div
                    className={classNames(
                        "h-20 flex items-center justify-center border-b border-birchwood w-full bg-birchwood "
                    )}
                >
                    {
                        <img
                            className={classNames(" w-20")}
                            src={BogioLogo}
                            alt=""
                        />
                    }
                </div>

                {menuItems.map((menuItem: any, i: number) => {
                    return (
                        <div
                            className={classNames(
                                menuItem.containerClasses,
                                "w-full"
                            )}
                            key={i}
                        >
                            <MenuItem
                                title={menuItem.title}
                                open={open}
                                Icon={menuItem.icon}
                                active={location.pathname === menuItem.route}
                                link={menuItem.route}
                            />
                        </div>
                    );
                })}
            </motion.div>
        </div>
    );
};

export default SideBar;
