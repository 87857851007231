import Graphemer from 'graphemer';

export type SmsInfoType = {
  charCount: number;
  parts: number;
  maxLengthForParts: number,
  specialChars: string[];
  isUnicode: boolean;
};

export const noneGSMchars = (text: string) : string[] => {
    const nonBreakingSpaceChar = String.fromCharCode(160);
    const GSMChars = "@£$¥èéùìòÇØø\nÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà "
    const GSMArray = Array.from(GSMChars);
    const splitter = new Graphemer();
    const seen = new Set<string>();
    const result: string[] = [];

    for (const char of splitter.splitGraphemes(text)) {
        if (!GSMArray.includes(char) && !seen.has(char)) {
            seen.add(char);
            if (char === nonBreakingSpaceChar) {
                result.push('(non-breaking space)');
            } else {
                result.push(char);
            }
        }
    }
    return result;
}

export const smsTypeAndCount = (text: string) : SmsInfoType => {
    let specialChars = noneGSMchars(text);
    let smsLength = text.length;
    let isUnicode = (specialChars.length === 0);
    let smsType = specialChars.length ? 1 : 0;
    let singlePartLength = specialChars.length ? 70 : 160;
    let multiPartLength = specialChars.length ? 67 : 153;
    let partLength = (text.length <= singlePartLength) ? singlePartLength : multiPartLength;
    let parts = Math.ceil(smsLength / partLength);
    let maxLengthForParts = parts * partLength;

    return {
        'charCount': smsLength,
        parts,
        specialChars,
        maxLengthForParts,
        'isUnicode': isUnicode
    }
}