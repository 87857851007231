import { DotsLoader } from "components/DotsLoader";
import { deleteCookie, getCookie } from "helpers";
import { useEffect } from "react"

export default function LogoutRoute() {
    useEffect(() => {

        let token = getCookie("token");

        fetch(process.env.REACT_APP_API_BASE + "/logout", {
            method: "POST",
            headers: {
                Authorization: `Authorization ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    window.location.href = "/";
                }
                return response.json();
            })
            .then(data => {
                deleteCookie("token");
                window.location.href = '/';
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }, [])

    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center">
            <p className="text-base text-tamahagane mb-4">Logging out</p>
            <DotsLoader />
        </div>
    )
}