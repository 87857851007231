import { createContext, useState } from 'react';

type AlertObject = {
    alertType: AlertType,
    title: string,
    description: string,
    id: number,
    bgColor: string,
    status: string
}

const AlertContext = createContext({
    alerts: [] as any,
    addAlert: (title: string, description: string = "", bgColor: string, alertType: AlertType) => { },
    discardAlert: (alertType: AlertType) => { }
});

export function AlertContextProvider(props: any) {
    const [alerts, setAlerts] = useState([] as Array<AlertObject>);

    const addAlert = (title: string, description: string = "", bgColor: string, alertType: AlertType) => {
        const newAlerts = [...alerts];
        let status = bgColor === "cruelRuby" ? "error" : "success";

        newAlerts.unshift({
            title,
            alertType,
            description,
            id: Date.now(),
            bgColor,
            status
        });
        setAlerts(newAlerts)
    }

    const discardAlert = (alertType: AlertType) => {
        const newAlerts = alerts.filter((t: any) => t.alertType !== alertType);
        setAlerts(newAlerts);
    }

    const context = {
        alerts,
        addAlert,
        discardAlert
    };

    return (
        <AlertContext.Provider value={context}>
            {props.children}
        </AlertContext.Provider>
    )
}

export default AlertContext;