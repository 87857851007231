import { classNames } from "helpers";
import { useEffect, useState } from "react";
import { Dropdown, DropdownBody, DropdownItem } from "../dropdown/index";

export type SingleItem = {
    title: string;
    value: any;
    id: number;
};

type SimpleDropdownProps = {
    placeholder: string;
    bodyWidth?: string;
    bodyHeight?: string;
    disabled?: boolean;
    loading?: boolean;
    textColor?: string;
    textStyles?: string;
    noDeselect?: boolean;
    preSelect?: (itemMatch: SingleItem) => boolean; // This will be used to select which item should be pre selected
    data: Array<SingleItem>;
    reset?: any;
    selectFn: (item: SingleItem | null) => void;
};

export const SimpleDropdown = (props: SimpleDropdownProps) => {
    const [selected, setSelected] = useState("");

    useEffect(() => {
        if (props.preSelect) {
            const preSelectedItem = props.data.find(props.preSelect);
            console.log("DEBUG 3", preSelectedItem);
            setSelected(preSelectedItem ? preSelectedItem.title : "");
            preSelectedItem && props.selectFn(preSelectedItem);
        } else {
            setSelected("");
        }
    }, [props.reset]);

    useEffect(() => {
        if (props.preSelect && !selected.length) {
            const preSelectedItem = props.data.find(props.preSelect);
            setSelected(preSelectedItem ? preSelectedItem.title : "");
        }
    }, [props.preSelect]);

    return (
        <Dropdown
            disabled={props.disabled}
            placeholder={props.placeholder}
            bodyEaseIn={false}
            loading={props.loading}
            renderBody={(dropdownSettings: any) => (
                <DropdownBody
                    bodyWidth={props.bodyWidth ?? ""}
                    bodyHeight={props.bodyHeight ?? ""}
                    renderItem={(bodySettings: any) => (
                        <DropdownItem
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={selected === bodySettings.title}
                            handleClick={() => {
                                dropdownSettings.handleClose();
                                if (bodySettings.title === selected) {
                                    !props.noDeselect && setSelected("");
                                    !props.noDeselect && props.selectFn(null);
                                } else {
                                    setSelected(bodySettings.title);
                                    props.selectFn(bodySettings);
                                }
                            }}
                        />
                    )}
                    data={props.data}
                />
            )}
            styles={"relative w-full outline-none"}
            backgroundColor={"bg-birchwood"}
            textStyles={classNames(
                "truncate overflow-hidden overflow-ellipsis max-w-full ",
                props.textStyles ? props.textStyles : "text-tamahagane"
            )}
            selected={selected}
        />
    );
};
