import FlexSendCampaignBar from "./sections/section_1/FlexSendCampaignBar";
import FlexMessageBar from "./sections/section_3/FlexMessageBar";
import CampaignContext, {
    CampaignContextProvider,
} from "store/campaign-context";
import { MessageContextProvider } from "store/message-context";
import { TemplateContextProvider } from "store/template-context";
import HeaderBar from "components/HeaderBar";
import FlexScheduleBarBeta from "./sections/section_4/FlexScheduleBarBeta";
import FlexSendToBarBeta from "./sections/section_2/FlexSendToBarBeta";
import { UTMDataProvider } from "store/utm-context";
import { ShortlinksContext, ShortlinksProvider } from "store/shortlink-context";
import { classNames } from "helpers";
import { useToolbarContext } from "store/toolbar-context";
import {CampaignTimeProvider} from "../../store/campaign-time-context";
import {useContext} from "react";
import AccountContext from "../../store/account-context";

const CampaignRoute = () => {
    const {dispatchAnyTime} = useContext(AccountContext);
    const { toolbarOpen, setToolbarOpen } = useToolbarContext();

    return (
        <CampaignContextProvider>
            <MessageContextProvider>
                <TemplateContextProvider>
                    <UTMDataProvider>
                        <CampaignTimeProvider dispatchAnyTime={dispatchAnyTime}>
                            <HeaderBar title="Create Campaign" />

                            <div className="pageBeginMargin">
                                <div
                                    className={classNames(
                                        "sectionContainerWidth  flex flex-col justify-center bg-whiteSmoke w-full transform transition ease-in-out duration-500",
                                        toolbarOpen
                                            ? " -translate-0 2xl:-translate-x-52"
                                            : "translate-x-0"
                                    )}
                                >
                                    <div className="grid grid-cols-1 gap-5 w-full">
                                        <div className="relative z-40">
                                            <FlexSendCampaignBar />
                                        </div>
                                        <div className="relative z-30">
                                            <FlexSendToBarBeta />
                                        </div>
                                        <div className="relative z-20">
                                            <FlexMessageBar />
                                        </div>
                                        {/*
                                        <div className='flex flex-row min-h-max ' >
                                          <div className='ml-10 w-8/12 relative z-10'>
                                            <MessageBar ></MessageBar>
                                          </div>
                                          <div className='mr-10 ml-5 w-4/12 relative'>
                                            <PreviewMessageBarDiv ></PreviewMessageBarDiv>
                                          </div>
                                        </div>
                                
                                        */}
                                        <div>
                                            <FlexScheduleBarBeta />
                                        </div>
                                        <div>{/*<FlexReviewBar />*/}</div>
                                    </div>
                                </div>
                            </div>
                        </CampaignTimeProvider>
                    </UTMDataProvider>
                </TemplateContextProvider>
            </MessageContextProvider>
        </CampaignContextProvider>
    );
};

export default CampaignRoute;
