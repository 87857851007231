import { Transition } from "@headlessui/react";
import { classNames } from "helpers";
import { Fragment, useState } from "react";
import { BsChevronDown, BsChevronRight, BsMapFill } from "react-icons/bs";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";
import NewDropdown from "./NewDropdown";

export interface DropdownProps {
    disabled?: boolean | undefined;
    checkbox?: boolean,
    selected: string,
    setSelection: Function,
    placeholder: string,
    items: { text: string, key: any }[],
    dropdownRef?: React.LegacyRef<HTMLButtonElement>,
    overflow?: boolean,
    styles?: string,
    textStyles?: string
}

const Dropdown = (props: DropdownProps) => {
    const [open, setOpen] = useState(false);
    //const [selected, setSelected] = useState("");
    const { selected } = props;

    const openDropdown = (e: any) => {
        setOpen(oldState => !oldState);
    }

    const closeDropdown = (e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setOpen(false);
        }
    }


    // Gör ett prop interface och normalisera det som skickas in.
    // Selected får bara vara en string

    const selectClick = (index: number) => {
        if (props.items[index].text === selected) {
            props.setSelection("");
            //setSelected("")
        } else {
            props.setSelection(props.items[index]);
            //setSelected(props.items[index].text)
        }
        setOpen(false);
    }

    return (
        <div className="relative w-full min-w-fit outline-none" onBlur={closeDropdown} tabIndex={2}>
            <button 
            disabled={props.disabled} 
            className={
                classNames(
"border font-inherit text-inherit appearance-none outline-none flex flex-row justify-between items-center border-granitine rounded-default py-3 px-3 h-12 w-full",
                    props.disabled ? "cursor-not-allowed" : "cursor-pointer"
                )
            } onClick={openDropdown} ref={props.dropdownRef} >
                <p className={"text-base " + props.textStyles}>{!props.selected ? props.placeholder : props.selected}</p>
                {open ? <HiChevronDown /> : <HiChevronRight />}
            </button>

            <Transition
                show={open}
                enter="duration-[55ms]"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <NewDropdown checkbox={props.checkbox} data={props.items} dataHasId={false} handleClick={selectClick} selected={selected} searchField={false} />
            </Transition>

            {/*
                open &&
            <div className={`absolute left-0 right-full border-t-0 border-solid bg-white border border-gray w-full dropdownShadow ${(props.items.length > 3 || props.overflow) && "overflow-y-scroll h-32"}`}>
                <div className="flex flex-col divide-y divide-iron" >
                    {
                        props.items.map((option: any, i:number) => {
                            return (
                                <button className={`py-3 px-3 font-inherit ${selected == option.text ? "bg-milkyWay" : "bg-white "} relative text-inherit appearance-none transition-all duration-200 hover:bg-milkyWay`} key={option.key} onClick={() => selectClick(i)}>
                                    <p className="text-left text-base">{option.text}</p>
                                </button>
                            );
                        })
                    }
                </div>
            </div>

                */}
        </div>
    )
}
export default Dropdown