import { useState } from 'react';
import Button from 'components/Button';
import { ActivationStatus } from '.';
import errorIcon from 'assets/error_icon.png';
import { IntegrationMetas } from 'data/IntegrationMetas';

export const EmarsysActivationForm = (props: any) => {

    const { onStatusChanged, integrationKey } = props;
    const [apiUserName, setApiUserName] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [secretError, setSecretError] = useState("");
    const [apiError, setApiError] = useState("");

    const handleUserNameInput = (value: string) => {
        setApiUserName(value)
        if (value.length > 0 && userNameError.length > 0) {
            setUserNameError("")
        }
    }

    const handleSecretInput = (value: string) => {
        setApiSecret(value)
        if (value.length > 0 && secretError.length > 0) {
            setSecretError("")
        }
    }

    const activateIntegration = () => {

        setApiError("")

        if (apiUserName.length > 0 && apiSecret.length > 0) {

            const body = {
                "emarsys_secret": apiSecret,
                "emarsys_username": apiUserName,
                "connection_name" : ""
            }

            const authUrl = IntegrationMetas.Emarsys.authUrl
            const headers = new Headers({
                'Authorization': `Bearer ${integrationKey}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Host': `${IntegrationMetas.Emarsys.host}`
            });

            fetch(authUrl, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: headers
            })
                .then(response => {
                    if (response.ok) {
                        onStatusChanged(ActivationStatus.SUCCESS);
                    } else {
                        response.text().then(error => setApiError(error))
                    }
                })
                .catch(error => {
                    onStatusChanged(ActivationStatus.ERROR);
                });
        } else {
            if (apiUserName.length === 0) {
                setUserNameError("Mandatory field")
            }

            if (apiSecret.length === 0) {
                setSecretError("Mandatory field")
            }
        }
    };

    const getInputClassName = (error: string) => {
        if (error.length > 0) {
            return "border border-red-500 focus:ring-0 apperance-none outline-0 text-base w-full rounded-default h-12 mt-2 "
        } else {
            return "border border-granitine focus:ring-0 apperance-none outline-0 text-base w-full rounded-default h-12 mt-2 "
        }
    }

    return (
        <>
            <div className='flex-grow'>
                <p className='text-base text-tamahagane'>To import contacts from Emarsys, we need to have access to your Emarsys account. To do this, you need to create an API user for us at Emarsys.</p>
                <br />
                {apiError.length > 0 && (
                    <div className="flex">
                        <img src={errorIcon} alt="Error Icon" className="mr-2 h-8" />
                        <p className="text-base text-tamahagane">{apiError}</p>
                        <br />
                        <br />
                    </div>
                )}
                <div className='flex flex-col'>
                    <p className='font-bold text-base text-tamahagane'>API username:</p>
                    <input type="text" onChange={(e: any) => { handleUserNameInput(e.target.value); }}
                        className={getInputClassName(userNameError)} />
                    {userNameError.length > 0 && <p className='text-red-500 text-sm mt-1'>{userNameError}</p>}
                    {userNameError.length === 0 && <br />}
                    <p className='font-bold text-base text-tamahagane'>API secret:</p>
                    <input type="text" onChange={(e: any) => { handleSecretInput(e.target.value); }}
                        className={getInputClassName(secretError)} />
                    {secretError.length > 0 && <p className='text-red-500 text-sm mt-1'>{secretError}</p>}
                    {secretError.length === 0 && <br />}

                </div>
                <br />
                <p className='text-base text-tamahagane'>
                    There is an article in the Emarsys Help pages that can help you find where to go, <a href="https://help.emarsys.com/hc/en-us/articles/115004740329#api-users" target='_blank' className="underline">click here</a> to go to that help page.
                </p>
                <br />
                <p className='text-base text-tamahagane'>
                    Removing the API user will disable our access to your Emarsys account.
                    If you need to change the API user we use you can disconnect the integration in Jojka and then authorize it again with the new API user credentials.
                </p>
                <br />
                <br />
            </div>
            <div className='flex justify-end'>
                <Button text="Submit" bgColor="bg-decoGray" textColor="white" style="px-8 h-12" handleClick={() => { activateIntegration(); }} />
            </div>
        </>);

};
