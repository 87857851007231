import {getCookie} from 'helpers';
import React, {useState, useEffect, ReactNode, createContext} from 'react';

type DeliveryStats = {
    total_cost: number,
    texts: {
        total: number,
        delivered: number,
        sent: number,
        failed: number,
    },
    parts: {
        total: number,
        delivered: number,
        sent: number,
        failed: number,
    }
}

type DeliveryStatsWrapper = {
    all: DeliveryStats,
    unique_recipients: number,
    click_rate: number,
    countries: { [key: string]: DeliveryStats }
    ga_revenue: number | undefined,
    ga_transactions: number | undefined,
    ga_currency: string | undefined,
    ga_conversion_rate: number | undefined
}

type StatsForSplit = {
    start: Date,
    end: Date,
    stats: DeliveryStatsWrapper
}

type StatsForPeriod = {
    summary: DeliveryStatsWrapper,
    splits: Array<StatsForSplit>
}

type State = {
    startDate: Date,
    endDate: Date,
    stats: StatsForPeriod
    error: any,
    setDates: (start: Date, end: Date) => void
}

const defaultStats = {
    summary: {
        unique_recipients: 0,
        click_rate: 0,
        all: {
            total_cost: 0,
            texts: {
                total: 0,
                delivered: 0,
                sent: 0,
                failed: 0,
            },
            parts: {
                total: 0,
                delivered: 0,
                sent: 0,
                failed: 0,
            }
        },
        countries: {},
        ga_revenue: undefined,
        ga_transactions: undefined,
        ga_currency: undefined,
        ga_conversion_rate: undefined,
    }
    , splits: []
}

const initialState: State = {
    startDate: new Date(),
    endDate: new Date(),
    stats: defaultStats,
    error: null,
    setDates: () => {
    }
}

const StatsContext = createContext<State>(initialState as State)

export const StatsContextProvider = ({children}: { children: ReactNode }) => {
    const [stats, setStats] = useState<StatsForPeriod>(defaultStats);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const setDates = (start: Date, end: Date) => {
        setStartDate(start);
        setEndDate(end);
    };

    const refreshStats = () => {
        console.log("Refreshing stats.");
        let token = getCookie("token");
        const startDatePart = startDate.toLocaleDateString('sv-SE');
        //const startTimePart = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const startString = `${startDatePart} 00:00`;
        const endDatePart = endDate.toLocaleDateString('sv-SE');
        //const endTimePart = endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const endString = `${endDatePart} 00:00`;
        fetch(process.env.REACT_APP_API_BASE + `/stats?start_date=${startString}&end_date=${endString}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => response.json())
            .then((response) => {
                if (response.error) return;
                response = response.stats
                response.summary.all.total_cost ||= 0 // hotfix
                const data: StatsForPeriod = {
                    ...response,
                    splits: response.splits.map((split: any) => ({
                        ...split,
                        start: new Date(split.start),
                        end: new Date(split.end)
                    }))
                };
                setStats(data)
            });
    }

    useEffect(() => {
        refreshStats();
    }, [startDate, endDate]); // Refresh when dates change

    return (
        <StatsContext.Provider value={{
            stats,
            startDate,
            endDate,
            error: null,
            setDates
        }}>
            {children}
        </StatsContext.Provider>
    );
};

export default StatsContext;
