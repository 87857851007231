
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button';
import { ActivationStatus } from '.';
import errorIcon from 'assets/error_icon.png';
import { IntegrationMetas } from 'data/IntegrationMetas';
import { SimpleDropdown, SingleItem } from 'components/SimpleDropdown';
import { ActivationLogosSection } from './ActivationLogosSection';
import GALogo from 'assets/ga_logo.png'
import './index.css';
import { classNames, deleteCookie, getCookie, header } from 'helpers';
import { DotsLoader } from "components/DotsLoader";
import AccountContext from 'store/account-context';
import { Tooltip } from 'react-tooltip';

type GAProperty = {
    currency_code: string,
    id: string,
    name: string,
    timezone: string
}

type GAAccount = {
    display_name: string,
    id: string,
    properties: Array<GAProperty>
}

export const GAPropertyForm = (props: any) => {

    const { onStatusChanged, updateFeature, integrationKey } = props;

    const [accounts, setAccounts] = useState<Array<GAAccount>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [connectionLoading, setConnectionLoading] = useState<boolean>(false);

    const [selectedAccount, setSelectedAccount] = useState<any>({});
    const [selectedProperty, setSelectedProperty] = useState<any>({});

    const [integrationConnected, setIntegrationConnected] = useState<boolean>(false);

    const [inputError, setInputError] = useState("");
    const [apiError, setApiError] = useState("");

    useEffect(() => {

        let integrationToken = getCookie("gaToken");
        //deleteCookie("gaToken");
        console.log("IntegrationToken: ", integrationToken)
        fetch('https://segint-ga.jojk.me/accounts', header(integrationToken))
            .then(response => response.json())
            .then((resData: any) => {
                // Perform all fetches in parallel with Promise.all
                setLoading(false);
                return Promise.all(resData.accounts.map((account: any) =>
                    fetch(`https://segint-ga.jojk.me/properties?account_id=${account.id}/`, header(integrationToken))
                        .then(response => response.json()).then((res: any) => {
                            let accountIndex = resData.accounts.findIndex((oldAccount: any) => oldAccount.id === account.id);
                            let newAccounts = [...resData.accounts];
                            newAccounts[accountIndex]["properties"] = res.properties;
                            setAccounts(newAccounts);
                        })
                ));
            })
            .catch(error => console.error(error));

    }, []);

    const activateIntegration = async () => {
        setConnectionLoading(true);

        const queryParams = new URLSearchParams(window.location.search);
        const connectionId = queryParams.get("connectionId");
        try {
            const response = await fetch(`https://segint-ga.jojk.me/connections/${connectionId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: selectedAccount.display_name + " - " + selectedProperty.name, account_id: selectedAccount.id, property_id: selectedProperty.id })
            });

            if (!response.ok) {
                throw new Error('HTTP error ' + response.status);
            }

            setConnectionLoading(false);
            setIntegrationConnected(true);

            updateFeature([
                "Show total revenue generated by each campaign",
                "Show total number of purchases generated by each campaign",
                "Aggregated revenue and purchase data for all campaigns"
            ])
            onStatusChanged(ActivationStatus.SUCCESS);
            // Use data as needed...

        } catch (error) {
            console.error('Failed to update route', error);
        }
    };

    return (
        <div className='flex flex-col bg-whiteSmoke h-screen w-screen justify-center py-8'>
            <div className=' flex flex-col w-1/2 xl:w-2/5 3xl:w-1/3 mx-auto bg-white border border-granitine drop-shadow-xl rounded-lg p-12 h-full justify-center'>
                <div className=' mt-36 relative z-20'>
                    <p className='text-lg text-center font-bold mb-8'>Activate integration</p>
                    <div className='mb-4'>
                        <ActivationLogosSection integrationLogo={GALogo} />
                    </div>
                    {apiError.length > 0 && (
                        <div className="flex">
                            <img src={errorIcon} alt="Error Icon" className="mr-2 h-8" />
                            <p className="text-base text-tamahagane">{apiError}</p>
                            <br />
                            <br />
                        </div>
                    )}
                    <p className='font-bold text-base text-tamahagane text-center mb-8'>Please choose which google analytics account and property you want to use.</p>
                    {Object.keys(accounts).length ? <SimpleDropdown disabled={!accounts} placeholder={'Choose account'} data={accounts.map((account: any, i: number) => ({ id: i, value: account, title: account.display_name }))} selectFn={function (item: SingleItem | null): void {
                        if (item) {
                            console.log("Selected: ", item.value)
                            setSelectedAccount(item.value)
                        } else {
                            setSelectedAccount({})
                        }
                        return undefined;
                    }} /> :
                        <SimpleDropdown loading={!accounts.length} disabled={!accounts.length} placeholder={'Choose account'} data={[]} selectFn={function (item: SingleItem | null): void {
                            return undefined;
                        }} />
                    }
                    <div className='mt-4'>
                        {Object.keys(selectedAccount).length ? <SimpleDropdown
                            disabled={!Object.keys(selectedAccount)}
                            placeholder={'Choose property'}
                            data={selectedAccount.properties && selectedAccount.properties.map((property: any, i: number) => ({ id: i, value: property, title: property.name }))}
                            selectFn={function (item: SingleItem | null): void {
                                if (item) {
                                    setSelectedProperty(item.value)
                                } else {
                                    setSelectedProperty({})
                                }
                                return undefined;
                            }} />
                            :

                            <>
                                <div className="z-20"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Select account first."
                                    data-tooltip-place="bottom">
                                    <SimpleDropdown
                                        disabled={!Object.keys(selectedAccount).length}
                                        placeholder={'Choose property'}
                                        data={[]}
                                        selectFn={function (item: SingleItem | null): void {
                                            return undefined;
                                        }} />
                                </div>
                                <Tooltip id="my-tooltip" />
                            </>

                        }
                    </div>
                    {inputError.length > 0 && <p className='text-red-500 text-sm mt-1 h-6'>{inputError}</p>}
                    {inputError.length === 0 && <div className="h-6" />}
                </div>
                <div className={classNames(
                    'flex w-full justify-end self-end mt-auto relative',
                )}>
                    <div className={
                        classNames(
                            "absolute opacity-1 bottom-0 mx-auto z-10",
                            connectionLoading && "fade-out z-0",
                            integrationConnected && "z-0"
                        )
                    }>
                        <div className='flex'>
                            <Button disabled={!(Object.keys(selectedAccount).length && Object.keys(selectedProperty).length)} handleClick={() => { activateIntegration(); }} bgColor="bg-decoGray" textColor='white' style="w-1/3 h-12 turn-round" text={'Continue'}></Button>
                        </div>
                    </div>
                    <div className={
                        classNames(
                            "absolute opacity-0 bottom-4 right-16",
                            connectionLoading && "fade-in z-10"
                        )
                    }>
                        <div className='flex w-full h-full items-center'>

                        </div>
                        <DotsLoader />
                    </div>
                    <div className={
                        classNames(
                            "absolute opacity-0 bottom-0 ",
                        )
                    }>
                        <div className='flex flex-col h-full w-full items-center justify-center'>
                            <p className='text-base text-tamahagane font-semibold'>Everything is done!</p>
                        </div>
                    </div>
                    <div className={
                        classNames(
                            "absolute opacity-0 bottom-0 ",
                            integrationConnected && "fade-in z-10"
                        )
                    }>
                        <div className='flex flex-col items-center justify-center'>
                            <Button handleClick={() => window.close()} bgColor="bg-decoGray" textColor='white' style="w-1/3 h-12 turn-round" text={'Close this tab'}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
