import { useContext } from "react";

import AccountContext from "store/account-context";
import AlertContext from "store/alert-context";
import CampaignContext from "store/campaign-context";

import Button from "components/Button";
import { getCookie } from "helpers";

const SendTestSMSBtn = (props: any) => {
    const campaignCtx = useContext(CampaignContext);
    const alertCtx = useContext(AlertContext);
    const accountCtx = useContext(AccountContext);

    const { text, bgColor, textColor, message, style, link } = props;

    const sendTestSms = () => {
        let token = getCookie("token");
        let alertType: AlertType = "TEST_SMS_ERROR";

        let errorMessage = "";
        if (!campaignCtx.from) {
            errorMessage = "You need to choose a sender first.";
            alertType = "NO_SENDER";
        } else if (!accountCtx.user.number.length) {
            errorMessage =
                "Your user does not have a phone number associated with it.";
        } else if (!message.length) {
            errorMessage = "The campaign must have a message.";
            alertType = "NO_MESSAGE";
        }

        if (errorMessage) {
            alertCtx.addAlert(
                "Preview failed",
                errorMessage,
                "cruelRuby",
                alertType
            );
            return;
        }

        let previewMessage = message;
        if ("text" in link) {
            previewMessage = previewMessage.replaceAll("%link%", link.text);
        }

        if (accountCtx.user.name) {
            previewMessage = previewMessage.replaceAll("%name%", accountCtx.user.name);
            previewMessage = previewMessage.replaceAll("%namn%", accountCtx.user.name);
        }

        fetch(process.env.REACT_APP_API_BASE + "/texts", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                sender: campaignCtx.from,
                to: accountCtx.user.number || "",
                message: previewMessage,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error === 400) {
                    alertCtx.addAlert(
                        "Preview failed",
                        "An unexpected error occurred.",
                        "cruelRuby",
                        "TEST_SMS_ERROR"
                    );
                    return;
                }
                alertCtx.addAlert(
                    "Preview sent",
                    `Sent to ${accountCtx.user.number}.`,
                    "darkAqua",
                    "TEST_SMS_SUCCESS"
                );
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <Button
            text={text}
            bgColor={bgColor}
            textColor={textColor}
            style={style}
            textStyles="text-sm font-medium text-center text-white"
            handleClick={sendTestSms}
        ></Button>
    );
};

export default SendTestSMSBtn;
