
import { useState } from "react";
import { Dropdown, DropdownBody, DropdownItem } from "dropdown"


const FilterDropdown = (props: any) => {

    const { items, onFiltersSelected, multipleSelection } = props;

    const [selectedFilters, setSelectedFilters] = useState<String[]>([]);

    const updateSelectedFilters = (newSelectedFilter: String) => {

        if (multipleSelection) {
            const indexOfNewSelectedFilter = selectedFilters.findIndex((filter) => filter === newSelectedFilter);
            const tempSelectedFilters = selectedFilters;

            if (indexOfNewSelectedFilter !== -1) {
                tempSelectedFilters.splice(indexOfNewSelectedFilter, 1);
            } else {
                tempSelectedFilters.push(newSelectedFilter);
            }
            setSelectedFilters(tempSelectedFilters)
        } else {
            if(selectedFilters.some(filter => filter === newSelectedFilter )){
                setSelectedFilters([])
            } else {
                setSelectedFilters([newSelectedFilter])
            }
        }
    }

    return (

        <Dropdown
            placeholder={"Filter"}
            renderBody={() =>
                <DropdownBody
                    renderItem={(bodySettings: any) =>
                        <DropdownItem
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={selectedFilters.some(filter => bodySettings.value === filter)}
                            handleClick={() => {
                                updateSelectedFilters(bodySettings.value)
                                onFiltersSelected(bodySettings.value)
                            }} />
                    }
                    data={props.items} />
            }
            styles={"relative w-full outline-none"}
            textStyles={"font-semibold truncate overflow-hidden overflow-ellipsis max-w-full text-tamahagane"}
            selected={selectedFilters.join(", ")} />
    )

}
export default FilterDropdown