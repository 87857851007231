import React, {useContext, useEffect, useRef, useState} from 'react';
import { HiAcademicCap, HiBell, HiOutlineCalendar, HiSelector } from 'react-icons/hi';
import CalendarItemsContext from "../store/calendar-items-context";
import CalendarDropdown from "./HeaderBar/CalendarDropdown";

const HeaderBar = (props: any) => {
    const {calendarItems} = useContext(CalendarItemsContext);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const calendarHasNew = calendarItems.some(x => x.isNew);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Close the calendar dropdown when the user clicks away.
    useEffect(() => {
        const listener = (e: any) => {
            if (!dropdownRef.current || !dropdownRef.current.contains(e.target)) {
                setCalendarOpen(false)
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [dropdownRef]);
    
    return (
        <div className="h-20 w-full flex border-b border-gray items-center fixed bg-whiteSmoke z-[99] ">

            <div className='w-full standardLeftMargin pl-8'>
                <p className='text-lg font-medium capitalize'>{props.title}</p>
            </div>
            <div className="flex w-full justify-end h-full items-center">
                <div className='flex justify-between w-[12rem] 3xl:w-1/5 mr-8'>
                    <div className="relative" ref={dropdownRef}>
                        <div className="relative cursor-pointer select-none" onClick={() => setCalendarOpen(x => !x)}>
                            <HiOutlineCalendar className='w-6 h-6 block fill-currentColor' />
                            {calendarHasNew && 
                            <div className="size-[5px] rounded-full bg-cruelRuby absolute top-[-0.2px] right-[-0.5px]"></div>
                            }
                        </div>
                        {calendarOpen &&
                        <div className="absolute w-96 left-1/2 transform -translate-x-1/2 mt-3 shadow-lg">
                            <CalendarDropdown/>
                        </div>    
                        }
                    </div>
                    <a href="https://bogio.tech/en/bogio-learning-center/" target="_blank">
                        <HiAcademicCap className='w-6 h-6 block fill-currentColor' />
                    </a>
                    <HiBell className='w-6 h-6 block fill-currentColor' />
                </div>
                <div className='border-l border-gray px-8 w-[12rem] 3xl:w-1/6 h-full flex items-center justify-center'>
                    <p className='text-sm font-semibold mr-4'>Account</p>
                    <div className='flex flex-col'>
                        <HiSelector className='w-6 h-6' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderBar;
