import { Dropdown, DropdownBody } from "dropdown";
import { classNames } from "helpers";
import {useContext, useEffect, useState} from "react";
import AccountContext from "../../store/account-context";

export default function FilterDropdown(props: any) {
    const [selected, setSelected] = useState("");
    const {user} = useContext(AccountContext);

    useEffect(() =>
        setSelected("")
        , [props.reset])

    return (
        <Dropdown
            disabled={props.disabled}
            placeholder={props.placeholder}
            bodyEaseIn={false}
            loading={props.loading}
            renderBody={(dropdownSettings: any) =>
                <DropdownBody
                    renderItem={(bodySettings: any) => {
                        const {id, title, value} = bodySettings;
                        const enabled = value === "delivery" || user.id === "61924fb34c04fc3cc058fa82";
                        const extras = enabled? "hover:bg-birchwood/20" : "opacity-60";
                        return (
                        <div className="flex flex-col border-b border-gray" key={id}>
                            <button
                                className={`p-4 font-inherit flex items-center appearance-none transition-all duration-200 cursor-pointer ${extras}`}
                                key={id}
                                onClick={() => {
                                    if (!enabled) return;
                                    dropdownSettings.handleClose();
                                    if (title === selected) {
                                        setSelected("");
                                        props.selectFn(null);
                                    } else {
                                        setSelected(title);
                                        props.selectFn(bodySettings);
                                    }
                                }}
                            >
                            <input
                                type={"checkbox"}
                                onChange={() => undefined}
                                checked={selected === title}
                                className="rounded border-gray text-tamahagane focus:ring-darkAqua col-span-1 self-center w-6 h-6 mr-4" />
                                <p className="text-left text-base">{title}</p>
                            </button>
                        </div>
                        );
                    }}
                    data={props.data} />
            }
            styles={"relative w-full outline-none"}
            backgroundColor={"bg-birchwood"}
            textStyles={classNames(
                "font-semibold truncate overflow-hidden overflow-ellipsis max-w-full text-white",
            )}
            headerButtonStyles={"bg-birchwood justify-center border-granitine rounded-default"}
            chevron={false}
            selected={selected} />
    )
}