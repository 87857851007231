import HeaderBar from "components/HeaderBar"
import IntegrationContainer from "./IntegrationContainer"

const IntegrationsRoute = () => {


    return (
        <>
            <HeaderBar title="Integrations" />

            <div className="h-full w-full min-h-screen pageBeginMargin">
                <div className="min-w-min w-10/12 mx-auto">
                    <IntegrationContainer />

                </div>
            </div>
        </>
    )

}

export default IntegrationsRoute