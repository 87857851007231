import { useState } from 'react';
import Button from 'components/Button';
import { ActivationStatus } from '.';
import errorIcon from 'assets/error_icon.png';
import { IntegrationMetas } from 'data/IntegrationMetas';

export const ShopifyActivationForm = (props: any) => {

    const { onStatusChanged, integrationKey } = props;
    const [url, setUrl] = useState("");
    const [inputError, setInputError] = useState("");
    const [apiError, setApiError] = useState("");

    const handleInput = (value: string) => {
        setUrl(value)
        if (value.length > 0 && inputError.length > 0) {
            setInputError("")
        }
    }

    const activateIntegration = () => {
        if (url.length > 0) {

            const body = {
                "shopurl": url
            }

            const authUrl = IntegrationMetas.Shopify.authUrl
            const headers = new Headers({
                'Authorization': `Bearer ${integrationKey}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Host': `${IntegrationMetas.Shopify.host}`
            });

            fetch(authUrl, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: headers
            })
                .then(response => {
                    if (response.ok) {
                        onStatusChanged(ActivationStatus.SUCCESS);
                    } else {
                        response.text().then(error => setApiError(error))
                    }
                })
                .catch(error => {
                    onStatusChanged(ActivationStatus.ERROR);
                });



        } else {
            setInputError("Mandatory field")
        }
    };

    const getInputClassName = (error: string) => {
        if (error.length > 0) {
            return "border border-red-500 focus:ring-0 apperance-none outline-0 text-base w-full rounded-default h-12 mt-2 "
        } else {
            return "border border-granitine focus:ring-0 apperance-none outline-0 text-base w-full rounded-default h-12 mt-2 "
        }
    }


    return (
        <div>
            {apiError.length > 0 && (
                <div className="flex">
                    <img src={errorIcon} alt="Error Icon" className="mr-2 h-8" />
                    <p className="text-base text-tamahagane">{apiError}</p>
                    <br />
                    <br />
                </div>
            )}
            <p className='font-bold text-base text-tamahagane'>Please enter the Shopify URL of your store</p>
            <input type="text" onChange={(e: any) => { handleInput(e.target.value); }}
                className={getInputClassName(inputError)} />
            {inputError.length > 0 && <p className='text-red-500 text-sm mt-1 h-6'>{inputError}</p>}
            {inputError.length === 0 && <div className="h-6" />}
            <br />
            <p className='text-base text-tamahagane'>
                You will then be redirected to Shopify to authorize Jojka to read data from your store.
            </p>
            <div className='absolute bottom-24 right-24'>
                <Button text="Submit" bgColor="bg-decoGray" textColor="white" style="px-8 h-12" handleClick={() => { activateIntegration(); }} />
            </div>
        </div>);

};
