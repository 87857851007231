import { useContext, useState } from "react";
import AlertContext from "store/alert-context";
import CampaignContext from "store/campaign-context";
import MessageContext from "store/message-context";
import { useUTMData } from "store/utm-context";
import { initialState as campaignInitialState } from "store/campaign-context";
import { getCookie } from "helpers";
import { GAContext } from "store/ga-context";
import { useCampaignTime  } from "store/campaign-time-context";



const usePreApproveSanityCheck = () => {
    const campaignCtx = useContext(CampaignContext);
    const messageCtx = useContext(MessageContext);
    const alertCtx = useContext(AlertContext);

    const preApproveSanityCheck = () => {
        let errorMessage = "";
        let alertType: AlertType = "NO_NAME";
        let message = ""
        if (messageCtx.link.hasOwnProperty("target_url")) {
            message = messageCtx.message.replaceAll("%link%", messageCtx.link.text);
        } else {
            message = messageCtx.message;
        }
        let groupIds = Object.keys(campaignCtx.groupsIds).map(Number);

        if (!campaignCtx.name) {
            errorMessage = "The campaign must have a name."
            alertType = "NO_NAME";
        }
        else if (!campaignCtx.from) {
            errorMessage = "The campaign must have a sender."
            alertType = "NO_SENDER";
        }
        else if (!groupIds.length) {
            errorMessage = "The campaign must have at least one recipient."
            alertType = "NO_RECIPIENT";
        }
        else if (!message.length) {
            errorMessage = "The campaign must have a message."
            alertType = "NO_MESSAGE";
        }
        else if (messageCtx.message.includes('%link%') && !messageCtx.link.number) {
            errorMessage = "No link selected"
            alertType = "NO_CONNECTED_LINK";
        }

        if (errorMessage.length) {
            alertCtx.addAlert("Error", errorMessage, "cruelRuby", alertType)
            return false;
        }

        return true;
    }

    return preApproveSanityCheck;

};

interface UTMParams {
    [key: string]: string;
}

function buildUTM(url: string, params: UTMParams): string {
    // Create URL object
    console.log("Url", url)
    let urlObj = new URL(url);
    let paramsObj = new URLSearchParams(urlObj.search);

    console.log("Params: ", params)
    Object.keys(params).forEach(key => {
        paramsObj.set(key, params[key]);
    });

    urlObj.search = paramsObj.toString();

    return urlObj.toString();
}

type ShortlinkData = {
    utm_id?: string,
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_term?: string,
    utm_content?: string
}

function generateUtmData(shortlinkData: ShortlinkData) {

}

const generateHandler = (campaignCtx: typeof campaignInitialState, campaignId?: string) => {
    return {
        "source": {
            "sms": "sms",
            "groupname": "notimplemented",
            "groupid": "notimplemented",
            "jojka": "jojka"
        },
        "medium": {
            "sms": "sms",
            "campaignname": campaignCtx.name
        },
        "campaign": {
            "campaignname": campaignCtx.name,
            "campaignid": campaignId,
            "campaignnameid": campaignCtx.name + campaignId,
            "campaignnamesenddate": campaignCtx.name + campaignCtx.prettyDate
        },
        "id": {
            "campaignid": campaignId
        },
        "term": {
            "externalid": "notimplemented"
        }
    }
}

const useSetTargetLink = () => {
    const campaignCtx = useContext(CampaignContext);
    const messageCtx = useContext(MessageContext);
    const shortlinkData = useUTMData();
    const GACtx = useContext(GAContext);


    function buildFinalUtmData(campaignId?: string) {
        const handler: any = generateHandler(campaignCtx, campaignId)
        let finalUtmData: ShortlinkData = {}
        if (shortlinkData.sources?.value) {
            if (shortlinkData.sources.type === "freetext") {
                finalUtmData["utm_source"] = shortlinkData.sources.value;
            } else {
                finalUtmData["utm_source"] = handler["source"][shortlinkData.sources.value];
            }
        }
        if (shortlinkData.medium?.value) {
            if (shortlinkData.medium.type === "freetext") {
                finalUtmData["utm_medium"] = shortlinkData.medium.value;
            } else {
                finalUtmData["utm_medium"] = handler["medium"][shortlinkData.medium.value];
            }
        }
        if (shortlinkData.campaign?.value) {
            if (shortlinkData.campaign.type === "freetext") {
                finalUtmData["utm_campaign"] = shortlinkData.campaign.value;
            } else {
                finalUtmData["utm_campaign"] = handler["campaign"][shortlinkData.campaign.value];
            }
        }
        if (shortlinkData.id?.value) {
            console.log("true?", shortlinkData.id)
            if (shortlinkData.id.type === "freetext") {
                finalUtmData["utm_id"] = shortlinkData.id.value;
            } else {
                finalUtmData["utm_id"] = handler["id"][shortlinkData.id.value]
            }
        }
        if (shortlinkData.term?.value) {
            if (shortlinkData.term.type === "freetext") {
                finalUtmData["utm_term"] = shortlinkData.term.value;
            } else {
                finalUtmData["utm_term"] = handler["term"][shortlinkData.term.value];
            }
        }
        return finalUtmData;
    }


    const updateTargetLink = async (campaignId?: string) => {
        let finalUtmData = buildFinalUtmData(campaignId);
        let newTargetLink = buildUTM(messageCtx.link.target_url, finalUtmData);

        let postData: any = {
            "target_url": messageCtx.link.target_url,
            "short_code": messageCtx.link.short_code
        }

        if (GACtx?.active === true && GACtx.connection !== null) {
            postData["ga_property"] = GACtx.connection?.ga_property_id;
            postData["target_url"] = newTargetLink;
        }

        try {
            const token = getCookie("token");
            let resultJson = await fetch(process.env.REACT_APP_API_BASE + "/shortlinks/" + messageCtx.link.number, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData) 
            });
        } catch(err) {
            console.log("Could not update target url: ", err)
        }

        console.log("New link: ", newTargetLink)

    }

    return updateTargetLink;
}

export { usePreApproveSanityCheck, useSetTargetLink };
