import { Transition } from "@headlessui/react";
import { ReactNode, useState } from "react"
import { HiChevronDown, HiChevronRight } from "react-icons/hi";
import './index.css';

// Local files
import { classNames } from "helpers";

type DropdownSettings = {
    handleClose?: () => void
}
type DropdownProps = {
    placeholder: string,
    renderBody: (dropdownSettings?: DropdownSettings) => ReactNode,
    selected: string,
    textStyles?: string,
    borderColor?: string,
    backgroundColor?: string,
    disabled?: boolean,
    loading?: boolean,
    styles?: string,
    bodyEaseIn?: boolean,
    headerButtonStyles?: string,
    chevron?: boolean
}

const Dropdown = ({ placeholder, renderBody, selected, textStyles, borderColor, backgroundColor, disabled, loading, styles, headerButtonStyles, bodyEaseIn = true, chevron = true  }: DropdownProps) => {
    const [open, setOpen] = useState(false);

    const closeDropdown = (e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setOpen(false);
        }
    }

    let fullText = !selected ? placeholder : selected;
    let shortenedText = fullText.length > 28 ? fullText.substring(0, 28) + "..." : fullText;
    
    return (
        <div className={classNames("relative w-full min-w-fit outline-none", disabled && "cursor-not-allowed", styles)} onBlur={closeDropdown} tabIndex={0} >
            <button
                disabled={disabled || loading}
                className={
                    classNames(
                        "border font-inherit appearance-none outline-none flex flex-row items-center py-3 px-3 cursor-pointer w-full h-12 z-0",
                        disabled && "cursor-not-allowed bg-neutral-100 opacity-50",
                        headerButtonStyles ? headerButtonStyles : "bg-white justify-between border-granitine rounded-default"
                    
                    )
                }
                onClick={(e:any) => {
                    setOpen((oldVal: any) => !oldVal)
                }} 
                >
                {loading &&
                    <div className="flex gap-2">
                        <div className="firstdot-flashing"></div>
                        <div className="seconddot-flashing"></div>
                        <div className="thirddot-flashing"></div>
                    </div>
                }
                {!loading &&
                    <p className={
                        classNames(
                            "text-base",
                            textStyles
                        )
                    }>
                        {shortenedText}
                    </p>
                }
                {chevron ? ((open) ? <HiChevronDown className="w-6 h-6 text-tamahagane" /> : <HiChevronRight className="w-6 h-6 text-tamahagane" />) : undefined}
            </button>
            {bodyEaseIn && <Transition
                show={open}
                enter="duration-[55ms]"
                enterFrom="opacity-30"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {renderBody({ handleClose: () => setOpen(false) })}
            </Transition>}
            {!bodyEaseIn && open && renderBody({ handleClose: () => setOpen(false) })}

        </div>
    )
}

export default Dropdown
export type {DropdownProps, DropdownSettings};