import { useEffect, useState } from "react";
import { getCookie } from "helpers";


const normalizeSegmentOptions = (rootObj: any) => {
    let rootLists: any = rootObj.options.filter((list: any) => list.id === "lists")[0].children
    let returnArray: any = []

    rootLists.forEach((list: any) => {
        let finalList: any = {
            name: "",
            options: []
        }
        finalList["name"] = list.name
        // [ {type: }]

        list.children.forEach((sourceType: any) => {
            if (sourceType.id.includes("list")) {
                finalList["id"] = sourceType.id

            } else {
                sourceType.children.forEach((source: any) => {
                    let type = source.id.substring(0, source.id.indexOf("-"))
                    if (finalList["options"].findIndex((x: any) => x.type === type) === -1) {
                        finalList["options"].push({ type, children: [] })
                    }
                    finalList["options"][finalList["options"].findIndex((x: any) => x.type === type)].children.push({
                        id: source.id,
                        name: source.name,
                        type
                    })
                })

            }
        });

        returnArray.push(finalList)
    });

    return returnArray;
}

const normalizeSegmentOptionsNew = (rootList: any, integration_id: string) => {
    let returnArray: any = []

    rootList.forEach((list: any) => {
        const segmentOptions: any = []
        list.children.forEach((listItem: any) => {
            if (segmentOptions.findIndex((option: any) => option.type === listItem.type) === -1) {
                segmentOptions.push({ type: listItem.type, children: [listItem] })
            } else {
                segmentOptions.find((option: any) => option.type === listItem.type).children.push(listItem)
            }

        })
        list.children = segmentOptions
        list.main_list = {
            type: "main_list",
            name: list.name
        }
        if (integration_id === "klaviyo") {
            list.id = list.connection_id
            list.main_list["id"] = list.connection_id
        } else if (integration_id === "mailchimp") {
            list.main_list["id"] = list.id
        }
        returnArray.push(list)
    })

    return returnArray
}

interface IntegrationItem {
    integration_id: string,
    key: string
}

const integrationLookup: any = {
    "mailchimp": process.env.REACT_APP_MAILCHIMP_URL,
    "klaviyo": process.env.REACT_APP_KLAVIYO_URL,
    "mailchimp2": "https://external.46elks.com/stevenmock/mailchimp",
    "mailchimp3": "https://external.46elks.com/stevenmock/mailchimp",
}

function useFetch(integration: IntegrationItem) {
    const [data, setData] = useState(null as any)
    const [postData, setPostData] = useState(null as any)
    const [error, setError] = useState(null as any)
    const [loading, setLoading] = useState(null as any)

    // Need tokens to fetch from each segint

    const header: any = (token: string) => {
        return { "Authorization": `Bearer ${token}` }
    };

    function getSegments() {
        setLoading(true)
        let url: any = integrationLookup[integration.integration_id] + "/segment-options?v=2"
        fetch(url, {
            method: "GET",
            headers: {...header(integration.key)},
        }).then((response: any) => {
            return response.json()
        }).then((responseData: any) => {
            setData(normalizeSegmentOptionsNew(responseData.lists, integration.integration_id))
            setLoading(false)
        }).catch((err:any) => {
            setError(err)
        })
    }

    function postImports(imports: any, countryCodes: {[id: string]: {id: string, cc: string}}, integrationSpecific: any): any {
        return (
            async function () {
                const url: any = integrationLookup[integration.integration_id] + "/import?v=2"
                setLoading(true)
                let importsData = {
                    imports: imports.map((importItem: any) => ({...importItem, cc: countryCodes[importItem.id]['cc']})),
                    merge: false
                }
                if (integrationSpecific) {
                    importsData = {...importsData, ...integrationSpecific}
                }
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: {...header(integration.key),
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(importsData)
                    })
                    const responseData = await response.json()
                    return responseData
                } catch (err) {
                    setError(err)
                    return -1;
                } finally {
                    setLoading(false)
                }
            }()
        )
    }

    return { data, postData, error, loading, getSegments, postImports }
}

const countryCodes = [
    { country: "Sweden", alpha2: "SE" },
    { country: "Denmark", alpha2: "DK" },
    { country: "Norway", alpha2: "NO" },
    { country: "Afghanistan", alpha2: "AF" },
    { country: "Åland Islands", alpha2: "AX" },
    { country: "Albania", alpha2: "AL" },
    { country: "Algeria", alpha2: "DZ" },
    { country: "American Samoa", alpha2: "AS" },
    { country: "Andorra", alpha2: "AD" },
    { country: "Angola", alpha2: "AO" },
    { country: "Anguilla", alpha2: "AI" },
    { country: "Antarctica", alpha2: "AQ" },
    { country: "Antigua and Barbuda", alpha2: "AG" },
    { country: "Argentina", alpha2: "AR" },
    { country: "Armenia", alpha2: "AM" },
    { country: "Aruba", alpha2: "AW" },
    { country: "Australia", alpha2: "AU" },
    { country: "Austria", alpha2: "AT" },
    { country: "Azerbaijan", alpha2: "AZ" },
    { country: "Bahrain", alpha2: "BH" },
    { country: "Bahamas", alpha2: "BS" },
    { country: "Bangladesh", alpha2: "BD" },
    { country: "Barbados", alpha2: "BB" },
    { country: "Belarus", alpha2: "BY" },
    { country: "Belgium", alpha2: "BE" },
    { country: "Belize", alpha2: "BZ" },
    { country: "Benin", alpha2: "BJ" },
    { country: "Bermuda", alpha2: "BM" },
    { country: "Bhutan", alpha2: "BT" },
    { country: "Bolivia, Plurinational State of", alpha2: "BO" },
    { country: "Bonaire, Sint Eustatius and Saba", alpha2: "BQ" },
    { country: "Bosnia and Herzegovina", alpha2: "BA" },
    { country: "Botswana", alpha2: "BW" },
    { country: "Bouvet Island", alpha2: "BV" },
    { country: "Brazil", alpha2: "BR" },
    { country: "British Indian Ocean Territory", alpha2: "IO" },
    { country: "Brunei Darussalam", alpha2: "BN" },
    { country: "Bulgaria", alpha2: "BG" },
    { country: "Burkina Faso", alpha2: "BF" },
    { country: "Burundi", alpha2: "BI" },
    { country: "Cambodia", alpha2: "KH" },
    { country: "Cameroon", alpha2: "CM" },
    { country: "Canada", alpha2: "CA" },
    { country: "Cape Verde", alpha2: "CV" },
    { country: "Cayman Islands", alpha2: "KY" },
    { country: "Central African Republic", alpha2: "CF" },
    { country: "Chad", alpha2: "TD" },
    { country: "Chile", alpha2: "CL" },
    { country: "China", alpha2: "CN" },
    { country: "Christmas Island", alpha2: "CX" },
    { country: "Cocos (Keeling) Islands", alpha2: "CC" },
    { country: "Colombia", alpha2: "CO" },
    { country: "Comoros", alpha2: "KM" },
    { country: "Congo", alpha2: "CG" },
    { country: "Congo, the Democratic Republic of the", alpha2: "CD" },
    { country: "Cook Islands", alpha2: "CK" },
    { country: "Costa Rica", alpha2: "CR" },
    { country: "Côte d'Ivoire", alpha2: "CI" },
    { country: "Croatia", alpha2: "HR" },
    { country: "Cuba", alpha2: "CU" },
    { country: "Curaçao", alpha2: "CW" },
    { country: "Cyprus", alpha2: "CY" },
    { country: "Czech Republic", alpha2: "CZ" },
    { country: "Denmark", alpha2: "DK" },
    { country: "Djibouti", alpha2: "DJ" },
    { country: "Dominica", alpha2: "DM" },
    { country: "Dominican Republic", alpha2: "DO" },
    { country: "Ecuador", alpha2: "EC" },
    { country: "Egypt", alpha2: "EG" },
    { country: "El Salvador", alpha2: "SV" },
    { country: "Equatorial Guinea", alpha2: "GQ" },
    { country: "Eritrea", alpha2: "ER" },
    { country: "Estonia", alpha2: "EE" },
    { country: "Ethiopia", alpha2: "ET" },
    { country: "Falkland Islands (Malvinas)", alpha2: "FK" },
    { country: "Faroe Islands", alpha2: "FO" },
    { country: "Fiji", alpha2: "FJ" },
    { country: "Finland", alpha2: "FI" },
    { country: "France", alpha2: "FR" },
    { country: "French Guiana", alpha2: "GF" },
    { country: "French Polynesia", alpha2: "PF" },
    { country: "French Southern Territories", alpha2: "TF" },
    { country: "Gabon", alpha2: "GA" },
    { country: "Gambia", alpha2: "GM" },
    { country: "Georgia", alpha2: "GE" },
    { country: "Germany", alpha2: "DE" },
    { country: "Ghana", alpha2: "GH" },
    { country: "Gibraltar", alpha2: "GI" },
    { country: "Greece", alpha2: "GR" },
    { country: "Greenland", alpha2: "GL" },
    { country: "Grenada", alpha2: "GD" },
    { country: "Guadeloupe", alpha2: "GP" },
    { country: "Guam", alpha2: "GU" },
    { country: "Guatemala", alpha2: "GT" },
    { country: "Guernsey", alpha2: "GG" },
    { country: "Guinea", alpha2: "GN" },
    { country: "Guinea-Bissau", alpha2: "GW" },
    { country: "Guyana", alpha2: "GY" },
    { country: "Haiti", alpha2: "HT" },
    { country: "Heard Island and McDonald Islands", alpha2: "HM" },
    { country: "Holy See (Vatican City State)", alpha2: "VA" },
    { country: "Honduras", alpha2: "HN" },
    { country: "Hong Kong", alpha2: "HK" },
    { country: "Hungary", alpha2: "HU" },
    { country: "Iceland", alpha2: "IS" },
    { country: "India", alpha2: "IN" },
    { country: "Indonesia", alpha2: "ID" },
    { country: "Iran, Islamic Republic of", alpha2: "IR" },
    { country: "Iraq", alpha2: "IQ" },
    { country: "Ireland", alpha2: "IE" },
    { country: "Isle of Man", alpha2: "IM" },
    { country: "Israel", alpha2: "IL" },
    { country: "Italy", alpha2: "IT" },
    { country: "Jamaica", alpha2: "JM" },
    { country: "Japan", alpha2: "JP" },
    { country: "Jersey", alpha2: "JE" },
    { country: "Jordan", alpha2: "JO" },
    { country: "Kazakhstan", alpha2: "KZ" },
    { country: "Kenya", alpha2: "KE" },
    { country: "Kiribati", alpha2: "KI" },
    { country: "Korea, Democratic People's Republic of", alpha2: "KP" },
    { country: "Korea, Republic of", alpha2: "KR" },
    { country: "Kuwait", alpha2: "KW" },
    { country: "Kyrgyzstan", alpha2: "KG" },
    { country: "Lao People's Democratic Republic", alpha2: "LA" },
    { country: "Latvia", alpha2: "LV" },
    { country: "Lebanon", alpha2: "LB" },
    { country: "Lesotho", alpha2: "LS" },
    { country: "Liberia", alpha2: "LR" },
    { country: "Libya", alpha2: "LY" },
    { country: "Liechtenstein", alpha2: "LI" },
    { country: "Lithuania", alpha2: "LT" },
    { country: "Luxembourg", alpha2: "LU" },
    { country: "Macao", alpha2: "MO" },
    { country: "Macedonia, the Former Yugoslav Republic of", alpha2: "MK" },
    { country: "Madagascar", alpha2: "MG" },
    { country: "Malawi", alpha2: "MW" },
    { country: "Malaysia", alpha2: "MY" },
    { country: "Maldives", alpha2: "MV" },
    { country: "Mali", alpha2: "ML" },
    { country: "Malta", alpha2: "MT" },
    { country: "Marshall Islands", alpha2: "MH" },
    { country: "Martinique", alpha2: "MQ" },
    { country: "Mauritania", alpha2: "MR" },
    { country: "Mauritius", alpha2: "MU" },
    { country: "Mayotte", alpha2: "YT" },
    { country: "Mexico", alpha2: "MX" },
    { country: "Micronesia, Federated States of", alpha2: "FM" },
    { country: "Moldova, Republic of", alpha2: "MD" },
    { country: "Monaco", alpha2: "MC" },
    { country: "Mongolia", alpha2: "MN" },
    { country: "Montenegro", alpha2: "ME" },
    { country: "Montserrat", alpha2: "MS" },
    { country: "Morocco", alpha2: "MA" },
    { country: "Mozambique", alpha2: "MZ" },
    { country: "Myanmar", alpha2: "MM" },
    { country: "Namibia", alpha2: "NA" },
    { country: "Nauru", alpha2: "NR" },
    { country: "Nepal", alpha2: "NP" },
    { country: "Netherlands", alpha2: "NL" },
    { country: "New Caledonia", alpha2: "NC" },
    { country: "New Zealand", alpha2: "NZ" },
    { country: "Nicaragua", alpha2: "NI" },
    { country: "Niger", alpha2: "NE" },
    { country: "Nigeria", alpha2: "NG" },
    { country: "Niue", alpha2: "NU" },
    { country: "Norfolk Island", alpha2: "NF" },
    { country: "Northern Mariana Islands", alpha2: "MP" },
    { country: "Norway", alpha2: "NO" },
    { country: "Oman", alpha2: "OM" },
    { country: "Pakistan", alpha2: "PK" },
    { country: "Palau", alpha2: "PW" },
    { country: "Palestine, State of", alpha2: "PS" },
    { country: "Panama", alpha2: "PA" },
    { country: "Papua New Guinea", alpha2: "PG" },
    { country: "Paraguay", alpha2: "PY" },
    { country: "Peru", alpha2: "PE" },
    { country: "Philippines", alpha2: "PH" },
    { country: "Pitcairn", alpha2: "PN" },
    { country: "Poland", alpha2: "PL" },
    { country: "Portugal", alpha2: "PT" },
    { country: "Puerto Rico", alpha2: "PR" },
    { country: "Qatar", alpha2: "QA" },
    { country: "Réunion", alpha2: "RE" },
    { country: "Romania", alpha2: "RO" },
    { country: "Russian Federation", alpha2: "RU" },
    { country: "Rwanda", alpha2: "RW" },
    { country: "Saint Barthélemy", alpha2: "BL" },
    { country: "Saint Helena, Ascension and Tristan da Cunha", alpha2: "SH" },
    { country: "Saint Kitts and Nevis", alpha2: "KN" },
    { country: "Saint Lucia", alpha2: "LC" },
    { country: "Saint Martin (French part)", alpha2: "MF" },
    { country: "Saint Pierre and Miquelon", alpha2: "PM" },
    { country: "Saint Vincent and the Grenadines", alpha2: "VC" },
    { country: "Samoa", alpha2: "WS" },
    { country: "San Marino", alpha2: "SM" },
    { country: "Sao Tome and Principe", alpha2: "ST" },
    { country: "Saudi Arabia", alpha2: "SA" },
    { country: "Senegal", alpha2: "SN" },
    { country: "Serbia", alpha2: "RS" },
    { country: "Seychelles", alpha2: "SC" },
    { country: "Sierra Leone", alpha2: "SL" },
    { country: "Singapore", alpha2: "SG" },
    { country: "Sint Maarten (Dutch part)", alpha2: "SX" },
    { country: "Slovakia", alpha2: "SK" },
    { country: "Slovenia", alpha2: "SI" },
    { country: "Solomon Islands", alpha2: "SB" },
    { country: "Somalia", alpha2: "SO" },
    { country: "South Africa", alpha2: "ZA" },
    { country: "South Georgia and the South Sandwich Islands", alpha2: "GS" },
    { country: "South Sudan", alpha2: "SS" },
    { country: "Spain", alpha2: "ES" },
    { country: "Sri Lanka", alpha2: "LK" },
    { country: "Sudan", alpha2: "SD" },
    { country: "Suriname", alpha2: "SR" },
    { country: "Svalbard and Jan Mayen", alpha2: "SJ" },
    { country: "Swaziland", alpha2: "SZ" },
    { country: "Sweden", alpha2: "SE" },
    { country: "Switzerland", alpha2: "CH" },
    { country: "Syrian Arab Republic", alpha2: "SY" },
    { country: "Taiwan, Province of China", alpha2: "TW" },
    { country: "Tajikistan", alpha2: "TJ" },
    { country: "Tanzania, United Republic of", alpha2: "TZ" },
    { country: "Thailand", alpha2: "TH" },
    { country: "Timor-Leste", alpha2: "TL" },
    { country: "Togo", alpha2: "TG" },
    { country: "Tokelau", alpha2: "TK" },
    { country: "Tonga", alpha2: "TO" },
    { country: "Trinidad and Tobago", alpha2: "TT" },
    { country: "Tunisia", alpha2: "TN" },
    { country: "Turkey", alpha2: "TR" },
    { country: "Turkmenistan", alpha2: "TM" },
    { country: "Turks and Caicos Islands", alpha2: "TC" },
    { country: "Tuvalu", alpha2: "TV" },
    { country: "Uganda", alpha2: "UG" },
    { country: "Ukraine", alpha2: "UA" },
    { country: "United Arab Emirates", alpha2: "AE" },
    { country: "United Kingdom", alpha2: "GB" },
    { country: "United States", alpha2: "US" },
    { country: "United States Minor Outlying Islands", alpha2: "UM" },
    { country: "Uruguay", alpha2: "UY" },
    { country: "Uzbekistan", alpha2: "UZ" },
    { country: "Vanuatu", alpha2: "VU" },
    { country: "Venezuela, Bolivarian Republic of", alpha2: "VE" },
    { country: "Viet Nam", alpha2: "VN" },
    { country: "Virgin Islands, British", alpha2: "VG" },
    { country: "Virgin Islands, U.S.", alpha2: "VI" },
    { country: "Wallis and Futuna", alpha2: "WF" },
    { country: "Western Sahara", alpha2: "EH" },
    { country: "Yemen", alpha2: "YE" },
    { country: "Zambia", alpha2: "ZM" },
    { country: "Zimbabwe", alpha2: "ZW" }
]

export {
    useFetch,
    countryCodes
}