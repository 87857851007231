import {ReactNode, useState} from "react"

import { classNames } from "helpers";
import {BsSearch} from "react-icons/bs";

export type DropdownItemProps = {
    id: number,
    title: string,
    value: string
}

type DropdownBodyProps = {
    renderItem: ( {id, title, value}: DropdownItemProps ) => ReactNode,
    data: DropdownItemProps[],
    bodyDirection?: string,
    bodyStyles?: string,
    bodyWidth?: string,
    bodyHeight?: string,
    noDataText?: string,
    handleClose?: () => void,
    search?: boolean
}

const DropdownBody = ({renderItem, data, bodyDirection, bodyStyles, bodyWidth, bodyHeight, handleClose, noDataText, search}: DropdownBodyProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    if (searchQuery) {
        data = data.filter(x => x.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    
    return (
        <div className={
            classNames(
                "absolute top-full mt-1 bg-white dropdownShadow border border-granitine will-change-filter rounded-default z-10",
                bodyDirection ? bodyDirection + "-0" : "left-0",
                bodyStyles,
                bodyWidth ? bodyWidth : "w-[105%] min-w-[26rem]"
            )
            }>
            {search && 
                <div className="flex border-solid items-center p-4 pb-0">
                    <BsSearch className="w-5 h-5 mr-4 text-gunMetal50" />
                    <div className="p-2 flex w-full border border-gray rounded-default">
                        <input name="q" type="text" autoComplete="off" aria-autocomplete="none" autoCorrect="off" onChange={e => setSearchQuery(e.target.value)} className="border-0 focus:ring-0 apperance-none w-full ml-1 outline-none text-base " placeholder="Name"></input>
                    </div>
                </div>
            }
            <div className={classNames(
                "overflow-auto",
                bodyHeight ? bodyHeight : " h-[20rem]"
            )}>
                {
                    data.map((item: DropdownItemProps, i: number) => {
                        return <div key={item.id}>{renderItem({id: item.id, title: item.title, value: item.value})}</div>
                    })
                }
                {
                    data.length === 0 && noDataText && false ?
                    <p className="text-base font-semibold text-decoGray text-center">{noDataText}</p>
                    : undefined
                }
            </div>
        </div>
    )
}

export default DropdownBody