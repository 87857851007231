import { classNames } from "helpers";
import { useEffect, useState } from "react";
import { Dropdown, DropdownBody, DropdownItem } from "../dropdown/index";
import {DropdownItemProps} from "../dropdown/DropdownBody";

export type SingleItem = {
    title: string;
    value: any;
    id: number;
};

type SimpleDropdownProps = {
    placeholder: string;
    bodyWidth?: string;
    bodyHeight?: string;
    disabled?: boolean;
    loading?: boolean;
    textColor?: string;
    textStyles?: string;
    noDeselect?: boolean;
    selected?: SingleItem;
    data: Array<SingleItem>;
    reset?: any;
    search?: boolean;
    selectFn: (item: SingleItem | null) => void;
};

export const SimpleDropdown2 = (props: SimpleDropdownProps) => {

    return (
        <Dropdown
            disabled={props.disabled}
            placeholder={props.placeholder}
            bodyEaseIn={false}
            loading={props.loading}
            renderBody={(dropdownSettings: any) => (
                <DropdownBody
                    bodyWidth={props.bodyWidth ?? ""}
                    bodyHeight={props.bodyHeight ?? ""}
                    renderItem={(bodySettings: DropdownItemProps) => (
                        <DropdownItem
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={bodySettings.id === props.selected?.id}
                            handleClick={() => {
                                dropdownSettings.handleClose();
                                if (bodySettings.id === props.selected?.id) {
                                    !props.noDeselect && props.selectFn(null);
                                } else {
                                    props.selectFn(bodySettings);
                                }
                            }}
                        />
                    )}
                    data={props.data}
                    search={props.search}
                />
            )}
            styles={"relative w-full outline-none"}
            backgroundColor={"bg-birchwood"}
            textStyles={classNames(
                "truncate overflow-hidden overflow-ellipsis max-w-full ",
                props.textStyles ? props.textStyles : "text-tamahagane"
            )}
            selected={props.selected?.title || ''}
        />
    );
};
