import { XMarkIcon } from "@heroicons/react/20/solid";
import Button from "components/Button";
import { SimpleDropdown, SingleItem } from "components/SimpleDropdown";
import Toolbar, { ToolbarBodySettings } from "components/Toolbar";
import { classNames, getCookie } from "helpers";
import {
    ReactNode,
    ChangeEvent,
    useContext,
    useState,
    useEffect,
    useRef,
} from "react";
import AccountContext from "store/account-context";
import MessageContext from "store/message-context";

type MessageToolbarProps = {
    targetLink: string;
    linkShortCode: string;
};

const MessageBarToolbar = (props: MessageToolbarProps) => {
    const [targetLink, setTargetLink] = useState<string>("");
    const [linkShortCode, setLinkShortCode] = useState<string>("");
    const [useUTMTags, setUseUTMTags] = useState(true);
    const [shortDomain, setShortDomain] = useState<string>("");

    const shortCodeErrorRef = useRef<HTMLParagraphElement | null>(null);
    const targetLinkErrorRef = useRef<HTMLParagraphElement | null>(null);

    const messageCtx = useContext(MessageContext);
    const accountCtx = useContext(AccountContext);

    const handleActionBtn = async () => {
        if (
            !/^[a-zA-Z0-9@:%._\+~#=-]+$/.test(linkShortCode) &&
            shortCodeErrorRef.current
        ) {
            shortCodeErrorRef.current.textContent =
                "Must be at least 1 character long and not contain any spaces.";
            return false;
        } else {
            shortCodeErrorRef.current &&
                (shortCodeErrorRef.current.textContent = "");
        }

        let protocol = "";
        try {
            protocol = new URL(targetLink).protocol;
        } catch {}
        if (
            !["http:", "https:"].includes(protocol) &&
            targetLinkErrorRef.current
        ) {
            targetLinkErrorRef.current.textContent = "Invalid link";
            return false;
        } else {
            targetLinkErrorRef.current &&
                (targetLinkErrorRef.current.textContent = "");
        }

        let postBody: any = {
            short_domain: shortDomain,
            short_code: linkShortCode,
            target_url: targetLink,
        };
        const token = getCookie("token");

        try {
            const rawResponse = await fetch(
                process.env.REACT_APP_API_BASE + "/shortlinks/v2",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(postBody),
                }
            );
            const res = await rawResponse.json();
            if (res.error && res.message.includes("another account")) {
                if (shortCodeErrorRef.current) {
                    shortCodeErrorRef.current.textContent =
                        "That short code is already being used by someone else.";
                }
                return false;
            }
            if (res.error && res.message.includes("recently used")) {
                if (shortCodeErrorRef.current) {
                    shortCodeErrorRef.current.textContent =
                        "You've already used this link in another campaign.";
                }
                return false;
            }
            if (res.error) {
                if (shortCodeErrorRef.current) {
                    shortCodeErrorRef.current.textContent = "Unknown error.";
                }
                return false;
            }
            let shortlink = res.shortlink;
            shortlink.text =
                shortlink.short_domain + "/" + shortlink.short_code;
            shortlink.utm_tagged = true; // Use utm tags by default
            messageCtx.updateLink(shortlink);
        } catch (error) {
            console.log("Error updating link: ", error);
            return false;
        }
        return true;
    };

    const getShortDomains: () => Array<SingleItem> = () => {
        let dropdownData: Array<SingleItem> = [];
        accountCtx.allowedDomains?.forEach((allowedDomain, idx) => {
            dropdownData.push({
                title: allowedDomain,
                value: allowedDomain,
                id: idx,
            });
        });
        if (dropdownData.length === 0) {
            dropdownData.push({
                title: "bogio.link",
                value: "bogio.link",
                id: 0,
            });
        }
        return dropdownData as Array<SingleItem>;
    };

    return (
        <Toolbar
            renderBody={function (
                toolbarBodySettings: ToolbarBodySettings
            ): ReactNode {
                return (
                    <div className="flex flex-col h-full relative">
                        <form
                            action=""
                            onSubmit={(e: any) => e.preventDefault()}
                            className={""}
                        >
                            <div className="flex justify-between items-center w-full">
                                <p className="text-lg font-semibold">
                                    Generate a branded and measurable link
                                </p>
                                <XMarkIcon
                                    className="w-8 h-8 cursor-pointer"
                                    onClick={() =>
                                        toolbarBodySettings.handleClose()
                                    }
                                ></XMarkIcon>
                            </div>
                            <p className="text-base mt-4">
                                Enhance customer engagement and track revenue by
                                activating Google Analytics tracking
                            </p>
                            <div className="flex mt-8 gap-4">
                                <div className="flex flex-col gap-2 w-1/2">
                                    <p className="font-semibold text-base">
                                        Domain
                                    </p>
                                    {(()=>{
                                        const data = getShortDomains();
                                        return <SimpleDropdown
                                            noDeselect={true}
                                            placeholder={""}
                                            preSelect={(item) => item === data[0]}
                                            data={data}
                                            selectFn={function (
                                                item: SingleItem | null
                                            ): void {
                                                item && setShortDomain(item.value);
                                            }}
                                        />
                                    })()}
                                </div>
                                <div className="flex flex-col gap-2 w-1/2">
                                    <p className="font-semibold text-base">
                                        Short code
                                    </p>

                                    <div className="flex flex-col">
                                        <div
                                            className={classNames(
                                                "flex w-full border border-gunMetal25 rounded-default overflow-hidden h-12 items-center"
                                            )}
                                        >
                                            <input
                                                placeholder="code"
                                                ref={
                                                    toolbarBodySettings.firstFieldRef
                                                }
                                                name="q"
                                                type="text"
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>
                                                ) =>
                                                    setLinkShortCode(
                                                        e.currentTarget.value
                                                    )
                                                }
                                                value={linkShortCode}
                                                autoComplete="off"
                                                aria-autocomplete="none"
                                                autoCorrect="off"
                                                className={classNames(
                                                    "focus:ring-0 py-2 border-0 apperance-none w-full outline-none text-base transition-all placeholder:font-semibold placeholder:text-decoGray/70 h-full"
                                                )}
                                            ></input>
                                        </div>
                                        <p
                                            ref={shortCodeErrorRef}
                                            className="text-someRuby"
                                        ></p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mt-8 gap-2">
                                <p className="font-semibold text-base">
                                    Target link
                                </p>
                                <div className="flex flex-col">
                                    <div
                                        className={classNames(
                                            "flex w-full border border-gunMetal25 rounded-default overflow-hidden h-12"
                                        )}
                                    >
                                        <input
                                            placeholder="https://example.com/landing-page"
                                            name="q"
                                            type="text"
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                setTargetLink(
                                                    e.currentTarget.value
                                                )
                                            }
                                            value={targetLink}
                                            autoComplete="off"
                                            aria-autocomplete="none"
                                            autoCorrect="off"
                                            className={classNames(
                                                "focus:ring-0 border-0 apperance-none w-full outline-none text-base transition-all placeholder:font-semibold placeholder:text-decoGray/70 h-full"
                                            )}
                                        ></input>
                                    </div>
                                    <p
                                        ref={targetLinkErrorRef}
                                        className="text-someRuby"
                                    ></p>
                                </div>
                            </div>
                            <div className="border-b-2 mt-12 border-gray"></div>
                            <div className="flex flex-col mt-12">
                                <div className="flex gap-4 items-center">
                                    <input
                                        type="checkbox"
                                        className="rounded border-gray text-tamahagane focus:ring-tamahagane w-6 h-6 cursor-pointer"
                                        checked={useUTMTags}
                                        onChange={(e) => {
                                            setUseUTMTags(e.target.checked);
                                        }}
                                    />
                                    <p className="text-base cursor-pointer font-semibold">
                                        Tracking
                                    </p>
                                </div>
                                <div className="mt-2">
                                    <p className="text-wrap text-sm">
                                        Automatically add Google Analytics (UTM) tracking parameters to the
                                        target link. Will not overwrite any parameters already present in the link.{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="bottom-20 absolute right-0 w-52">
                                <Button
                                    bgColor={"bg-wilmington"}
                                    textStyles="text-white"
                                    handleClick={async () => {
                                        if (await handleActionBtn()) {
                                            toolbarBodySettings.handleClose();
                                        }
                                    }}
                                    text={
                                        props.linkShortCode &&
                                        props.linkShortCode.length
                                            ? "Save"
                                            : "Create"
                                    }
                                ></Button>
                            </div>
                        </form>
                    </div>
                );
            }}
        ></Toolbar>
    );
};

export default MessageBarToolbar;
