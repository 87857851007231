import { useState, useContext, useMemo } from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";

import CampaignContext from "store/campaign-context";

import DropdownSection from "./DropdownSection";

const Dropdown_stacked = (props: any) => {
    const campaignCtx = useContext(CampaignContext);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("");

    const { groupsData } = props;

    const openDropdown = (e: any) => {
        e.currentTarget.parentElement.focus();
        setOpen(oldState => !oldState);
    }

    const closeDropdown = (e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setOpen(false);
        }
    }

    const dropdownSections = useMemo(() => Object.keys(groupsData).sort().map((source: any) => {
        return <DropdownSection title={source} data={groupsData[source]} listType={props.listType} />;
    }), [groupsData]);

    let displaySelected = props.listType == "include" ? Object.keys(campaignCtx.selectedGroups).join(", ") : Object.keys(campaignCtx.selectedBlockGroups).join(", ");
    displaySelected = displaySelected.length > 19 ? displaySelected.substring(0, 19) + "..." : displaySelected;
    return (
        <div className="relative w-full outline-none bg-white z-10" onBlur={closeDropdown} onFocus={(e:any) => console.log("Got focused")} tabIndex={3}>
            <button className="border bg-white font-inherit text-inherit appearance-none flex flex-row justify-between items-center border-granitine py-3 px-3 pr-3 cursor-pointer w-full h-12 outline-none rounded-default" onClick={openDropdown}>
                <p className="text-base">{displaySelected.length < 1 ? props.placeholder : displaySelected}</p>
                {open ? <HiChevronDown /> : <HiChevronRight />}
            </button>
            {
                open &&

                <div className="absolute left-0 right-full bg-white dropdownShadow rounded-default border border-gray w-[23rem] xl:w-[26rem]" >
                    <div className="flex flex-col divide-y divide-gray">
                        {
                            Object.keys(groupsData).length > 0 ? dropdownSections
                            : 
                            <p className="text-base text-decoGray font-semibold p-4">No groups have been imported</p>
                        }
                    </div>

                </div>


            }


        </div>
    )
}
export default Dropdown_stacked