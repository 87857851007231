import React, { createContext, useState, useContext } from 'react';

type ToolbarContextProps = {
  toolbarOpen: boolean,
  setToolbarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ToolbarContext = createContext<ToolbarContextProps | undefined>(undefined);

export const ToolbarProvider: React.FC = ({ children }) => {
  const [toolbarOpen, setToolbarOpen] = useState(false);

  return (
    <ToolbarContext.Provider value={{ toolbarOpen, setToolbarOpen }}>
      {children}
    </ToolbarContext.Provider>
  );
};

export function useToolbarContext() {
  const context = useContext(ToolbarContext);
  if (context === undefined) {
    throw new Error('useToolbarContext must be used within a ToolbarProvider');
  }
  return context;
}