import { useState } from "react";
import { BsSearch } from "react-icons/bs";

const NewDropdown = (props: any) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selected, setSelected] = useState("");

    const handleSearch = (e: any) => {
        setSearchQuery(e.target.value);
    }

    return (

        <div className={`absolute ${props.bodyDirection || "left"}-0 top-full mt-1 max-w-[105%] min-w-[26rem] bg-white dropdownShadow border border-gray will-change-filter rounded-default z-10`}>
            {props.searchField && <div className="flex border-solid items-center p-4">
                <BsSearch className="w-5 h-5 mr-4 text-gunMetal50" />
                <div className="p-2 flex w-full border border-gunMetal25 rounded-default">
                    <input name="q" type="text" autoComplete="off" aria-autocomplete="none" autoCorrect="off" onChange={handleSearch} className="focus:ring-0 border-0 apperance-none w-full ml-1 outline-none text-base transition-all" placeholder={props.searchPlaceholder}></input>
                </div>

            </div>}
            <div className="overflow-auto h-[20rem]">
                {
                    props.data.filter((item: any) => {
                            if (searchQuery === "") {
                                return item
                            }
                            let match = true;
                            if (searchQuery) {
                                if (
                                    searchQuery.length >= 2 && item.text.toLowerCase().includes(searchQuery)
                                ) {
                                } else {
                                    match = false;
                                }

                            }

                            if (match) {
                                return item;
                            }
                    }).map((item: any, i: number) => {
                        return (
                        item && <div className="flex flex-col border-b border-gray" key={item.key}>
                            <button
                                className={`p-4 font-inherit flex items-center text-inherit appearance-none transition-all duration-200 cursor-pointer hover:bg-birchwood/20 `}
                                key={item.key}
                                onClick={() => props.handleClick(i)}
                            >
                                {props.checkbox && <input type={"checkbox"} onChange={() => undefined} checked={props.selected === item.text} className="rounded border-gray text-tamahagane focus:ring-tamahagane col-span-1 self-center w-6 h-6 mr-4" />}
                                <p className="text-left text-base">{props.dataHasId ? item.key + ". " + item.text
                                    : item.text}</p>
                            </button>
                        </div>)

                    })
                }
                {
                    props.data.length === 0 && props.noDataText ?
                    <p className="text-base font-semibold text-decoGray text-center">{props.noDataText}</p>
                    : undefined
                }
            </div>

        </div>
    )
}

export default NewDropdown;