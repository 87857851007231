// This schedulebar is used for beta release
// Refer to FlexScheduleBar for old version

import React, { useContext, useState } from "react";

import CampaignContext from "store/campaign-context";

import './ScheduleBar.css';
import ScheduleDropdownNew from "./ScheduleDropdownNew";
import Button from "components/Button";
import {usePreApproveSanityCheck} from "../section_5/helpers";
import ReviewModal from "../section_5/ReviewModal";
import TimeSelector from "components/TimeSelector";

const FlexScheduleBarBeta = () => {

    const campaignCtx = useContext(CampaignContext);
    const preApproveSanityCheck = usePreApproveSanityCheck();
    const [readyToSend, setReadyToSend] = useState(false);

    return (

        <><ReviewModal open={readyToSend} setOpen={(open: boolean) => setReadyToSend(open)} /><div className='flex flex-col bg-white sectionShadow px-16 py-8 border border-newSilver'>
            <div className="flex flex-row justify-between items-center mb-8">
                <p className="text-lg font-medium text-tamahagane">Scheduling</p>
            </div>

            <div className="defaultSectionWidth mb-4">
                <div className="grid grid-cols-7 gap-x-8 gap-y-4 dropdownContainerWidth">
                    <div className="col-span-4">
                        <ScheduleDropdownNew disabled={campaignCtx.sendNow} />
                    </div>
                    <div className="col-span-2">
                            <TimeSelector disabled={campaignCtx.sendNow} />
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-7 gap-x-8 gap-y-4 dropdownContainerWidth mb-8">
                <div className="col-span-4 flex items-center" >
                    <input id="scheduleForLater" onChange={(e: React.ChangeEvent<HTMLInputElement>) => campaignCtx.updateSendNow(!e.target.checked)} name="schedule" type={"radio"} checked={!campaignCtx.sendNow} className="text-acanthusLeaf outline-0 text-center border border-gunMetal50 font-light mr-2 focus:ring-acanthusLeaf cursor-pointer">
                    </input>
                    <label htmlFor="scheduleForLater" onClick={() => campaignCtx.updateSendNow(false)} className="mr-5 text-base font-semibold cursor-pointer">Schedule for later</label>
                </div>
                <div className="col-span-2 flex items-center">
                    <input id="sendNow" onChange={(e: React.ChangeEvent<HTMLInputElement>) => campaignCtx.updateSendNow(e.target.checked)} name="schedule" type={"radio"} checked={campaignCtx.sendNow} className="text-acanthusLeaf outline-0 text-center border border-gunMetal50 font-light mr-2 focus:ring-acanthusLeaf cursor-pointer">
                    </input>
                    <label htmlFor="sendNow" onClick={() => campaignCtx.updateSendNow(true)} className="text-base font-semibold cursor-pointer">Send now</label>
                </div>
            </div>

            <div className="flex justify-end mb-4">
                <Button handleClick={() => setReadyToSend(preApproveSanityCheck())} text="Confirm" bgColor="bg-wilmington" textColor="white" textStyles="text-sm font-medium" style="w-48 rounded-default " />
            </div>
        </div></>
    )
}

export default FlexScheduleBarBeta;