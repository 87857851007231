import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { forEachChild } from "typescript";

const header: any = (token: string) => {
    return { headers: { Authorization: `Bearer ${token}` } };
};

function getCookie(cookieName: string) {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(name: string, value: string, days?: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function deleteCookie(name: string) {
    setCookie(name, "", -1);
}

const dataFetch = async (url: string, token: string) => {
    const data = await (
        await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    ).json();

    return data;
};

const useData = (url: string) => {
    const [state, setState] = useState();

    useEffect(() => {
        const token = getCookie("token");
        dataFetch(process.env.REACT_APP_API_BASE + url, token).then(
            (data: any) => {
                setState(data);
            }
        );
    }, [url]);

    return { data: state };
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const integrationLookup: any = {
    mailchimp: { url: process.env.REACT_APP_MAILCHIMP_URL },
    klaviyo: { url: process.env.REACT_APP_KLAVIYO_URL },
};

// This hook requires an authorization token when initialized.
const useNewDelete = (authToken: string) => {
    // State to handle the deletion result and any potential errors.
    const [deleteRes, setDeleteRes] = useState<null | any>(null);
    const [error, setError] = useState<null | string>(null);
    const [deleteReqIsLoading, setDeleteReqIsLoading] =
        useState<boolean>(false);

    // Function to handle the DELETE request.
    const sendDeleteRequest = useCallback(
        async (url: string) => {
            setDeleteReqIsLoading(true);
            setError(null);

            try {
                const response = await fetch(url, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                setDeleteRes(responseData);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setDeleteReqIsLoading(false);
            }
        },
        [authToken]
    ); // authToken is a dependency

    return { sendDeleteRequest, deleteRes, error, deleteReqIsLoading };
};

const useDelete = (token: string): ((url: string) => Promise<any>) => {
    const deleteData = useCallback(
        async (url: string) => {
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.json();
        },
        [token]
    );

    return deleteData;
};

const prettyTime = (hour: number, minute: number) => {
    let prettyHour = hour.toString();
    let prettyMinute = minute.toString();
    if (hour < 10) {
        prettyHour = "0" + prettyHour;
    }
    if (minute < 10) {
        prettyMinute = "0" + prettyMinute;
    }
    return `${prettyHour}:${prettyMinute}`;
};

const adjustDateToLocalTime = (dateString: string) => {
    // Date object from the UTC input string
    const inputDate = new Date(dateString + " UTC");

    // Adjust to local time zone
    //const localDate = new Date(inputDate.toLocaleString());

    // Format the local date as a string in the desired format
    const dateFormatOptions: any = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    };
    const localDateString = inputDate.toLocaleDateString(
        "en-SE",
        dateFormatOptions
    );

    return localDateString;
};

function useCancelDispatch() {
    const cancelDispatch = (id: number, callback: (data: any) => void) => {
        const postBody = {
            state: "cancelled",
        };

        let token = getCookie("token");
        fetch(process.env.REACT_APP_API_BASE + "/dispatches/" + id, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        })
            .then((response) => response.json())
            .then((data: any) => {
                if (data.success === true) {
                    callback(data); // Call the provided callback function with the response data
                }
            });
    };

    return cancelDispatch;
}

// A custom hook to make POST requests
const usePostData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null);

    let token = getCookie("token");
    const postData = async (
        url: string,
        data: any,
        callback: (data: any) => void
    ) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data.");
            }

            const result = await response.json();
            setResponseData(result);

            if (callback && typeof callback === "function") {
                callback(result);
            }
        } catch (err: any) {
            setError(err.message || "Something went wrong.");
        } finally {
            setIsLoading(false);
        }
    };

    return { postData, isLoading, error, responseData };
};

// A custom hook to make GET requests
const useGetData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<any>(null);

    let token = getCookie("token");
    const getData = async (url: string, callback: (data: any) => void) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data.");
            }

            const result = await response.json();
            setData(result);

            if (callback && typeof callback === "function") {
                callback(result);
            }
        } catch (err: any) {
            setError(err.message || "Something went wrong.");
        } finally {
            setIsLoading(false);
        }
    };

    return { getData, isLoading, error, data };
};

function useGetParamQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useQueryParams() {
  let navigate = useNavigate();
  let location = useLocation();

  // Append a search parameter to the URL
  const appendQueryParams = (paramKey: string, paramValue: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramKey, paramValue);
    navigate({ 
      pathname: location.pathname, 
      search: searchParams.toString()
    }, { replace: true }); // Use replace to avoid adding a new entry in history stack
  };

  // Remove a search parameter from the URL
  const unsetQueryParams = (paramKey: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramKey);
    navigate({ 
      pathname: location.pathname, 
      search: searchParams.toString()
    }, { replace: true }); // Use replace here as well
  };

  return {
    appendQueryParams,
    unsetQueryParams
  };
}

function extractUTMParameters(url: string) {
  const utmParameters: any = {};
  const urlObj = new URL(url);
  const queryParams = new URLSearchParams(urlObj.search);

  const utmParamsList = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_id'
  ];

  utmParamsList.forEach(param => {
    const value = queryParams.get(param);
    if (value) {
      utmParameters[param] = value;
    }
  });

  return utmParameters;
}

function useInterval(callback: () => boolean, delay: number) {
    const savedCallback = useRef<() => boolean>(() => false);
    const intervalId = useRef<NodeJS.Timeout | null>(null);

    // Save the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            const shouldContinue = savedCallback.current();
            if (!shouldContinue) {
                // If the callback returns false, clear the interval.
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                    intervalId.current = null;
                }
            }
        }

        if (delay !== null) {
            intervalId.current = setInterval(tick, delay);
            return () => {
                // Clear the interval on unmount or when delay changes.
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                }
            };
        }
    }, [delay]);

    // Provide a way to manually clear the interval if needed.
    const clear = () => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
            intervalId.current = null;
        }
    };

    return clear;
}


export {
    getCookie,
    setCookie,
    deleteCookie,
    useData,
    useNewDelete,
    useCancelDispatch,
    useDelete,
    classNames,
    usePostData,
    useGetData,
    adjustDateToLocalTime,
    useGetParamQuery,
    useQueryParams,
    useInterval,
    extractUTMParameters,
    integrationLookup,
    header,
};
