
import { useEffect, useState, useContext, useMemo } from "react";
import { BsChevronRight, BsSearch } from "react-icons/bs";
import { HiChevronDown, HiChevronRight } from "react-icons/hi";

import CampaignContext from "store/campaign-context";

export interface DropdownSectionProps {
    title: string,
    data: SourceTypes,
    listType: ListType
}

const DropdownSection = (props: DropdownSectionProps) => {
    const campaignCtx = useContext(CampaignContext);

    const [segmentOpen, setSegmentOpen] = useState(false);
    const [sourceTypes, setSourceTypes] = useState({} as any);
    const [selectedGroups, setSelectedGroups] = useState({} as { [key: number]: GroupElement })
    const [searchQueryName, setSearchQueryName] = useState("");
    const [searchQueryId, setSearchQueryId] = useState(0);

    let groupsIds = props.listType == "include" ? campaignCtx.groupsIds : campaignCtx.tmpBlock;

    let initialSourceTypesObj: any = {}

    useEffect(() => {
        Object.keys(props.data).forEach(sourceType => {
            initialSourceTypesObj[sourceType] = false;
        });
        setSourceTypes(initialSourceTypesObj);
    }, [])

    const openDropdown = (e: any, type: string) => {
        if (!segmentOpen) {
            setSegmentOpen(true);
            setSearchQueryId(0);
            setSearchQueryName("");
        }
        sourceTypeOpen(true, type);
    }

    const sourceTypeOpen = (open: boolean, type: string) => {
        let newSourceType: any = { ...sourceTypes };
        Object.keys(newSourceType).forEach(element => {
            if (element === type) {
                newSourceType[element] = open;
            } 
        });

        setSourceTypes(newSourceType);
    }

    const closeDropdown = (e: any, type: string) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            sourceTypeOpen(false, type);
        }
    }

    const handleSelectGroup = (group: GroupElement,) => {
        let tmpSelectedGroups = { ...selectedGroups };
        if (group.id in groupsIds) {
            delete tmpSelectedGroups[group.id];
            // remove from context
            campaignCtx.removeGroup(group.id, props.listType)
        } else {
            tmpSelectedGroups[group.id] = group;
            campaignCtx.addGroup(group, props.listType)
            campaignCtx.removeGroup(group.id, props.listType === "exclude" ? "include" : "exclude");
        }
        setSelectedGroups(tmpSelectedGroups);
    }

    const capitalizeFirstLetter = (target: string) => {
        return target.charAt(0).toUpperCase() + target.slice(1);
    }

    const handleSearchName = (e: any) => {
        setSearchQueryName(e.target.value);
    }

    const renderedSourceTypes = useMemo(() => Object.keys(props.data).map((sourceType: any) => {
        return (
            <div className="relative min-h-0 w-full outline-none bg-white " onBlur={(e: any) => closeDropdown(e, sourceType)}  key={sourceType} tabIndex={3}>
                <div className={`hover:bg-birchwood/20 p-4 font-inherit text-inherit appearance-none outline-none flex flex-row justify-between items-center h-12 px-8 py-3 pr-3 cursor-pointer w-full`} onMouseDown={(e: any) => openDropdown(e, sourceType)} tabIndex={4}>
                    <p className="text-base">{capitalizeFirstLetter(sourceType)}</p>
                    <HiChevronRight />
                </div>

                {
                    sourceTypes[sourceType] &&
                    <div className="absolute left-full top-0 bg-white  w-[400px] dropdownShadow border border-gray will-change-filter rounded-default">
                        <div className="flex border-solid items-center p-4">
                            <BsSearch className="w-5 h-5 mr-4 text-gunMetal50" />
                            <div className="p-2 flex w-full border border-gray rounded-default">
                                <input name="q" type="text" autoComplete="off" aria-autocomplete="none" autoCorrect="off" onChange={handleSearchName} className="border-0 focus:ring-0 apperance-none w-full ml-1 outline-none text-base " placeholder="Name, ID, size"></input>
                            </div>

                        </div>
                        <div className="overflow-auto max-h-72 ">

                            {props.data[sourceType].filter((group: GroupElement) => {
                                if (searchQueryName === "") {
                                    return group
                                }
                                let queries = searchQueryName.split(" ");
                                let match = true;
                                queries.forEach(query => {
                                    if (query) {
                                        if (
                                            query.length >= 2 && group.name.toLowerCase().includes(query)
                                            || group.id.toString() === query
                                            || group.size.toString() === query
                                        ) {
                                        } else {
                                            match = false;
                                        }

                                    }

                                })

                                if (match) {
                                    return group;
                                }
                            }).map((group: GroupElement, i: number) => {
                                const name = ((): string => {
                                    const match = group.name.match(/^Klaviyo (?:list|segment) "(.*)"$/);
                                    return match ? match[1].replace(/\\"/g, '"') : group.name;
                                })()
                                return (
                                    <div className="flex flex-col" key={group.id}>
                                        <button
                                            className={`p-4 font-inherit flex items-center text-inherit appearance-none transition-all duration-200 cursor-pointer hover:bg-birchwood/20`}
                                            key={group.id}
                                            onClick={() => handleSelectGroup(group)}
                                        >

                                            <input type="checkbox" className="rounded border-gray text-birchwood focus:ring-tamahagane col-span-1 self-center w-6 h-6 mr-4" onChange={() => undefined} checked={group.id in groupsIds}  />
                                            <p className="text-left text-base">{group.id}. {name} ({group.size})</p>
                                        </button>
                                    </div>
                                )

                            })}
                        </div>

                    </div>
                }
            </div>
        )
    })
        , [props.data, selectedGroups, sourceTypes, searchQueryName, searchQueryId]);

    return (
        <div className="w-full z-50" >
            <div className="font-inherit text-inherit appearance-none flex flex-row justify-between items-center p-3 pr-3 h-12 cursor-pointer w-full">
                <p className="text-base font-bold">{capitalizeFirstLetter(props.title)}</p>
            </div>

            <div className="w-full">
                {
                    renderedSourceTypes
                }
            </div>
        </div>
    )
}

export default DropdownSection;