
import { header } from "helpers";
import React, {
    createContext,
    ReactNode,
    useReducer,
    useMemo,
    useContext
} from "react";

type OptionObject = {type: string, value: string}
type State = {
    id?: OptionObject,
    sources?: OptionObject,
    medium?: OptionObject,
    campaign?: OptionObject,
    term?: OptionObject,
    content?: OptionObject
}

type API = {
    set: (dispatchType: DispatchTypes, value: OptionObject) => void;
}

const initialState: State = {

}

const UTMDataContext = createContext<State>(initialState as State)
const UTMAPIContext = createContext<API>({} as API)

type Action =
    | { type: "SET_SOURCE"; payload: OptionObject }
    | { type: "SET_MEDIUM"; payload: OptionObject }
    | { type: "SET_ID"; payload: OptionObject }
    | { type: "SET_CAMPAIGN"; payload: OptionObject }
    | { type: "SET_TERM"; payload: OptionObject }
    | { type: "SET_CONTENT"; payload: OptionObject }


const reducer = (state: State, action: Action): State => {
    console.log("action", action)
    switch (action.type) {
        case "SET_SOURCE":
            return { ...state, sources: action.payload }
        case "SET_MEDIUM":
            return { ...state, medium: action.payload }
        case "SET_ID":
            return { ...state, id: action.payload }
        case "SET_CAMPAIGN":
            return { ...state, campaign: action.payload }
        case "SET_TERM":
            return { ...state, term: action.payload }
        case "SET_CONTENT":
            return { ...state, content: action.payload }
    }
}

type DispatchTypes = "sources" | "medium" | "id" | "term" | "campaign" | "content";

export const UTMDataProvider = ({ children }: { children: ReactNode }) => {

    const [state, dispatch] = useReducer(reducer, {} as State)

    const api = useMemo(() => {
        const set = (dispatchType: DispatchTypes, value: OptionObject) => {
            switch (dispatchType) {
                case "sources":
                    dispatch({ type: "SET_SOURCE", payload: value })
                    break;
                case "medium":
                    dispatch({ type: "SET_MEDIUM", payload: value })
                    break;
                case "id":
                    dispatch({ type: "SET_ID", payload: value })
                    break;
                case "term":
                    dispatch({ type: "SET_TERM", payload: value })
                    break;
                case "campaign":
                    dispatch({ type: "SET_CAMPAIGN", payload: value })
                    break;
                case "content":
                    dispatch({ type: "SET_CONTENT", payload: value })
                    break;
            }
        }
        return { set }
    }, [])

    return (
        <UTMAPIContext.Provider value={api}>
            <UTMDataContext.Provider value={state}>
                {children}
            </UTMDataContext.Provider>
        </UTMAPIContext.Provider>
    )
}

export const useUTMData = () => useContext(UTMDataContext)
export const useUTMAPI = () => useContext(UTMAPIContext)

export type { DispatchTypes };
