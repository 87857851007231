import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

import Button from 'components/Button';
import SendCampaignBtn from 'components/SendCampaignBtn';
import CampaignContext from 'store/campaign-context';
import MessageContext from 'store/message-context';
import AccountContext from 'store/account-context';
import {useCampaignTime} from "../../../../store/campaign-time-context";

const ReviewModal = (props: any) => {
    const campaignCtx = useContext(CampaignContext);
    const messageCtx = useContext(MessageContext);
    const {dayAndTimeString} = useCampaignTime();
    
    const [approveCampaign, setApproveCampaign] = useState(false);

    const cancelButtonRef = useRef(null);

    let timeString = campaignCtx.sendNow? "immediately" : `at ${dayAndTimeString}`
    

    useEffect(() => {
        setApproveCampaign(false);
    }, [props.open])

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-50 w-3/4 h-full bg-brightWhite" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto ">
                    <div className='absolute top-0 left-0 h-screen w-screen bg-slate-500 opacity-50 overflow-y-hidden'></div>
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-brightWhite p-8 text-left shadow-xl transition-all sm:my-8 sm:p-6 2xl:p-12 xl:w-1/4 md:w-1/2 sm:w-2/3 h-[20rem] border-[3px] border-white ">
                                <div className='flex flex-col justify-between h-full mx-auto w-full'>
                                    <div className='flex flex-col h-full mx-auto w-full items-center'>
                                        <div className='flex w-full'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 text-redPanda">
                                                <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" clipRule="evenodd" />
                                                <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
                                            </svg>
                                            <p className="text-center text-lg font-extrabold ml-3">Are you sure?</p>
                                            <svg onClick={() => props.setOpen(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 absolute top-7 right-6 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <div className='w-full relative h-full mt-4 '>
                                            <div className='flex flex-col w-full'>
                                                <p className="text-base">The campaign will be sent <span className="font-bold">{timeString}</span>.</p>
                                                <p className="text-base">Total cost: <span className="font-bold">{campaignCtx.totalCostPerPart / 100 / 100 * messageCtx.parts}kr</span>.</p>
                                                <div className='flex items-center mt-12 2xl:mt-6'>
                                                    <input type="checkbox" className="rounded border-gray text-tamahagane focus:ring-tamahagane col-span-1 self-center w-6 h-6 mr-3 cursor-pointer" onChange={() => setApproveCampaign(prevVal => !prevVal)} id="" name="" />
                                                    <p className='text-base'>I approve the sending</p>

                                                </div>

                                            </div>
                                            <div className='absolute bottom-0 right-0 '>
                                                <SendCampaignBtn handleClose={() => props.setOpen(false)} text="Confirm" textColor="white" bgColor="bg-wilmington" disabled={!approveCampaign} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ReviewModal