import Button from "components/Button";
import { Dialog, Transition } from "@headlessui/react";
import { useContext, useState, useEffect } from "react";
import AccountContext from "store/account-context";
import { useImportAPI } from "store/import-context";
import CountrycodeDropdown from "./CountrycodeDropdown";
import { useFetch, countryCodes } from "../helpers";
import Dropdown from "../Importer/Dropdown";
import { ExcelImporterProps, ExcelPostData } from "./types";
import { classNames, getCookie } from "helpers";
import GroupDropdown from "./GroupDropdown";
import ImportingView from "./ImportingView";
import AlertContext from "store/alert-context";

const ExcelImporter = (props: ExcelImporterProps) => {
    const alertCtx = useContext(AlertContext);

    const [newGroup, setNewGroup] = useState(true);
    const [postData, setPostData] = useState({} as ExcelPostData);
    const [uploaded, setUploaded] = useState({} as any);

    const handleCountrySelection = (cc: string) => {
        setPostData((oldData: ExcelPostData) => ({
            ...oldData,
            defaultCountry: cc,
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!postData.file) {
            // Do something
            return
        }
        if (!postData.groupName && newGroup) {
            // Do something
            return
        }
        if (!postData.groupId && !newGroup) {
            // Do something
            return
        }
        if (!postData.defaultCountry) {
            // Do something
            return
        }

        const formData = new FormData();

        formData.append("file", postData.file);
        formData.append("default_country", postData.defaultCountry);
        if (postData.groupId) formData.append("group_id", postData.groupId);
        if (postData.groupName) formData.append("group_name", postData.groupName);

        const authToken = getCookie("token");
        // Make the network request
        try {
            const response: any = await fetch(
                process.env.REACT_APP_API_BASE + "/uploads",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                    body: formData,
                }
            );

            if (response.ok) {
                const responseData = await response.json();
                setUploaded({
                    number: responseData.upload.number,
                    name: postData.groupName,
                });
            } else {
                alertCtx.addAlert(
                    "Import failed",
                    "The import failed because of an unkown reason.",
                    "cruelRuby",
                    "OTHER_ERROR"
                );
                props.handleClose();
            }
        } catch (error) {
            alertCtx.addAlert(
                "Import failed",
                "The import failed because of an unkown reason.",
                "cruelRuby",
                "OTHER_ERROR"
            );
            props.handleClose();
        }
    };

    const handleFileInput = (e: any) => {
        if (e.target?.files.length > 0) {
            setPostData((oldData: ExcelPostData) => ({
                ...oldData,
                file: e.target.files[0],
            }));
        }
    };

    const handleNameInput = (e: any) => {
        setPostData((oldData: ExcelPostData) => ({
            ...oldData,
            groupName: e.target.value,
        }));
    };

    const handleGroupSelection = (group: any) => {
        setPostData((oldData: ExcelPostData) => ({
            ...oldData,
            groupId: group.id,
        }));
    };

    return (
        <Dialog.Panel className="flex h-full mx-auto w-full pl-16 relative">
            {!("number" in uploaded) ? (
                <div className="h-28 flex items-center mt-16">
                    <div
                        className="flex items-center h-12 cursor-pointer"
                        onClick={props.back}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-tamahagane"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            />
                        </svg>
                        <p className="ml-2 font-semibold text-base">Back</p>
                    </div>
                </div>
            ) : undefined}
            <Transition.Root
                show={!("number" in uploaded)}
                className={
                    "flex flex-col justify-start items-center h-full pt-16 w-3/6 mx-auto"
                }
            >
                <div className="flex items-center min-h-28 h-28 ">
                    <p className=" text-lg font-semibold mr-4">
                        Sync data from
                    </p>
                    <div className="border border-gunMetal25 rounded-lg py-2 px-6 mr-4 bg-white">
                        <img
                            className="object-contain w-12 h-12 bg-white"
                            src={props.selectedSource.src}
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full max-h-[40rem] z-30">
                    <div className="flex flex-col w-[22rem]">
                        <p className="text-base font-semibold mb-2">
                            Upload a file
                        </p>
                        <input
                            type="file"
                            id="fileUpload"
                            onChange={handleFileInput}
                            className=" file:bg-tamahagane file:text-white file:px-4 file:py-2 file:border-none file:rounded-md file:cursor-pointer file:text-base"
                        ></input>
                    </div>

                    <div className="mt-8 flex justify-between ">
                        <div>
                            <input
                                type="checkbox"
                                name="newGroup"
                                checked={newGroup}
                                onChange={() => undefined}
                                onClick={() => setNewGroup(true)}
                                className="form-checkbox h-6 w-6 rounded-md border-gray text-birchwood focus:ring-tamahagane transition duration-150 ease-in-out mr-2 cursor-pointer"
                            />
                            <label htmlFor="newGroup" className="text-base">
                                Create new group
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="existingGroup"
                                checked={!newGroup}
                                onChange={() => undefined}
                                onClick={() => setNewGroup(false)}
                                className="form-checkbox h-6 w-6 rounded-md border-gray text-birchwood focus:ring-tamahagane transition duration-150 ease-in-out mr-2 cursor-pointer"
                            />
                            <label
                                htmlFor="existingGroup"
                                className="text-base"
                            >
                                Import to existing group
                            </label>
                        </div>
                    </div>
                    {newGroup ? (
                        <div className="mt-8 flex flex-col ">
                            <p className="text-base font-semibold mb-2">
                                Pick a name for the group
                            </p>
                            <input
                                type="text"
                                name=""
                                id=""
                                onChange={handleNameInput}
                                className="w-full border border-gunMetal/20 rounded-default h-12 text-base"
                            />
                        </div>
                    ) : undefined}
                    {!newGroup ? (
                        <div className="mt-8 flex flex-col">
                            <p
                                className={classNames(
                                    "text-base font-semibold",
                                    newGroup && "opacity-50"
                                )}
                            >
                                Select existing group
                            </p>
                            <GroupDropdown
                                disabled={newGroup}
                                setSelection={handleGroupSelection}
                            />
                        </div>
                    ) : undefined}

                    <div className="mt-8 flex flex-col">
                        <p className="text-base font-semibold">
                            The file contains numbers from
                        </p>
                        <CountrycodeDropdown
                            items={countryCodes.map((item: any, i: number) => ({
                                id: i,
                                title: item.country,
                                value: item.alpha2,
                            }))}
                            setSelection={(cc: string) =>
                                handleCountrySelection(cc)
                            }
                        />
                    </div>
                </div>

                <div className={`absolute bottom-20 z-30 right-8 `}>
                    <Button
                        text="Confirm"
                        handleClick={handleSubmit}
                        disabled={
                            postData.file
                                ? false
                                : true
                        }
                        bgColor="bg-wilmington"
                        textColor="white"
                        style="h-12"
                    />
                </div>
            </Transition.Root>
            <Transition
                enterFrom="opacity-0 translate-x-44"
                enterTo="opacity-100 translate-x-0"
                enter="transition-all duration-[700ms] absolute -bottom-0 ease-out"
                leave="transition-all"
                leaveTo="opacity-0"
                leaveFrom="opacity-100"
                className="h-full w-full"
                show={"number" in uploaded}
            >
                <ImportingView
                    uploadData={uploaded}
                    selectedSource={props.selectedSource}
                    handleClose={props.handleClose}
                />
            </Transition>
        </Dialog.Panel>
    );
};

export default ExcelImporter;
