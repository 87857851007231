import { getCookie } from "helpers"
import { ChangeEvent, useEffect, useState } from "react"
import CampaignDispatchListItem from "./CampaignDispatceListItem"

export const CampaignDispatchesList = (props: any) => {

    const { selectedCampaigns, onSelectClicked, onSelectAllClicked } = props
    const tableHeaders = ["ID", "Created", "Name", "Sent", "Delivered", "Revenue", "Click rate", "Placed order" ]

    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        setCampaigns(props.campaigns)
    }, [props.campaigns])

    const isCampaignSelected = (campaign: Dispatch) => {
        const index = selectedCampaigns.findIndex((selectedCampaign: Dispatch) => selectedCampaign._id === campaign._id);
        return index > -1
    }

    const isAllSelected = () => {
        return campaigns.length > 0 && campaigns.length === selectedCampaigns.length
    }

    const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
        onSelectAllClicked()
    }

    return (
        <>
            <table className="min-w-full table-auto overflow-auto ">
                <thead className="border-b border-gray px-2">
                    <tr>
                        <th className="w-4 py-4 pl-2">
                            <div className="flex justify-start w-12">
                                <input type="checkbox" checked={isAllSelected()} onChange={handleCheck} className="rounded border-gray text-tamahagane focus:ring-tamahagane w-6 h-6 text-left cursor-pointer" />
                            </div>
                        </th>
                        {tableHeaders.map((header: string) => <th key={header}>
                            <p className="text-base text-left font-semibold">{header}</p>
                        </th>
                        )}
                    </tr>
                </thead>
                <tbody className="">
                    {campaigns?.map((campaign: Dispatch) => {
                        return (
                            <CampaignDispatchListItem key={campaign._id} campaignDispatch={campaign} isSelected={isCampaignSelected(campaign)} onChecked={onSelectClicked} />
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
