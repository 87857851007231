import { getCookie } from 'helpers';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type DataType = { shortlinks: Array<{ id: number, name: string }> };

// Step 1: Create the Context
export const ShortlinksContext = createContext<DataType | null>(null);

interface DataProviderProps {
    children: ReactNode;
}

// Step 2: Create the Context Provider
export const ShortlinksProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [data, setData] = useState<DataType | null>(null);

    useEffect(() => {
        // Here, fetchData represents any function that fetches your data
        let token = getCookie("token");
        const fetchData = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_BASE + "/shortlinks", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Failed to fetch shortlink: ', error);
            }
        };
        fetchData();
    }, []);

    return (
        <ShortlinksContext.Provider value={data}>
            {children}
        </ShortlinksContext.Provider>
    );
};