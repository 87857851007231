import Button from "components/Button";
import { getCookie } from "helpers";
import { ChangeEvent } from "react";
import { Link } from "react-router-dom";

interface CampaignDispatchListItemProps {
    campaignDispatch: Dispatch;
    isSelected: boolean;
    onChecked: Function;
}

const CampaignDispatchListItem = (props: CampaignDispatchListItemProps) => {
    const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChecked(props.campaignDispatch);
    };

    let {
        accepted = 0,
        delivered = 0,
        failed = 0,
        sent = 0,
    } = props.campaignDispatch?.texts ?? {};
    let clicks = props.campaignDispatch?.clicks ?? 0;
    
    let revenue = props.campaignDispatch.ga_revenue;
    let transactions = props.campaignDispatch.ga_transactions;

    const sentTexts = accepted + delivered + failed + sent;
    const parts = props.campaignDispatch?.parts || {};
    const sentParts = Object.values(parts).reduce((x,acc) => acc+x,0)

    const partsString = props.campaignDispatch.texts ? `${sentParts}` : "N/A"
    const clickRate = delivered > 0 ? clicks / delivered : 0;
    const clickRatePercent = `${(clickRate * 100).toFixed(1)}%`;
    const clickRateString = props.campaignDispatch.clicks? clickRatePercent : 'N/A';
    let currency = props.campaignDispatch?.ga_currency || '';
    const deliveryRate = sentTexts > 0 ? delivered / sentTexts : 0;
    const deliveryRateString =
        sentTexts > 0 ? `${(deliveryRate * 100).toFixed(1)}%` : "N/A";
    const revenueString = revenue? `${revenue.toFixed(0)} ${currency}`: "N/A";
    const placedOrderString = transactions? `${transactions}`: "N/A";

    const dateFormatOptions: any = {
        day: "numeric",
        month: "short",
        year: "numeric",
    };
    let timestamp = props.campaignDispatch._id.toString().substring(0, 8);
    const date = new Date(parseInt(timestamp, 16) * 1000);
    const dateString = date.toLocaleDateString("en-SE", dateFormatOptions);

    return (
        <tr
            className="border-b border-gray py-4 hover:bg-birchwood/20 transition-colors"
            key={props.campaignDispatch._id}
        >
            <td className="w-12 py-4 pl-2">
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={handleCheck}
                    className="rounded border-gray text-tamahagane focus:ring-tamahagane w-6 h-6 cursor-pointer"
                />
            </td>
            <td>
                <p className="text-base w-[4rem] truncate capitalize">
                    {props.campaignDispatch.number}
                </p>
            </td>
            <td>
                <p className="text-base w-[8rem] truncate capitalize">
                    {dateString}
                </p>
            </td>
            <td>
                <Link to={`${props.campaignDispatch._id}`} relative="path" className="text-base w-[16rem] underline">
                    {props.campaignDispatch.name}
                </Link>
            </td>
            <td>
                <p className="text-base w-[8rem] truncate ">
                    {partsString}
                </p>
            </td>
            <td>
                <p className="text-base w-[8rem] truncate ">
                    {deliveryRateString}
                </p>
            </td>
            <td>
                <p className="text-base w-[8rem] truncate ">
                    {revenueString}
                </p>
            </td>
            <td>
                <p className="text-base w-[6rem] truncate">
                    {clickRateString}
                </p>
            </td>
            <td>
                <p className="text-base w-[6rem] truncate">
                    {placedOrderString}
                </p>
            </td>
        </tr>
    );
};

export default CampaignDispatchListItem;
