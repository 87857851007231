import { useRef } from "react";
import { useContext, useEffect } from "react";

import CampaignContext from "store/campaign-context";
import AccountContext from "store/account-context";

import { SimpleDropdown, SingleItem } from "components/SimpleDropdown";

interface GroupsData {
    [key: string]: SourceTypes
}
const FlexSendCampaignBar = () => {

    const campaignCtx = useContext(CampaignContext);
    const accountCtx = useContext(AccountContext);

    const dropdownRef: any = useRef(true)

    const handleNameInput = (e: any) => {
        let inputVal = e.currentTarget.value;
        campaignCtx.updateName(inputVal);
    }

    const handleFromInput = (item: any) => {
        let inputVal = item.value;
        //setTest("nau");
        campaignCtx.updateFrom(inputVal);
    }

    useEffect(() => {
        //dropdownRef.current.focus();
    }, [campaignCtx.from])

    return (
        <div className='flex flex-col bg-white sectionShadow px-16 py-8 gap-4 border border-newSilver'>
            <div className="flex flex-row justify-between items-center" >
                <p className="text-lg font-medium text-tamahagane leading-8">Campaign details</p>
            </div>
            <div className="flex gap-20">
                <div className="flex w-full 2xl:defaultSectionWidth gap-8">
                    <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                        <p className="text-base font-semibold">Name</p>
                        <input type="text" onChange={handleNameInput} value={campaignCtx.name} className="focus:ring-0 apperance-none outline-0 text-base py-3 px-3 border h-12 focus:border-granitine border-granitine rounded-default w-full" tabIndex={0}></input>
                    </div>
                    <div className="flex flex-col gap-2 w-4/12 2xl:inputWidth">
                        <p className="text-base font-semibold">Sender</p>
                        <div className='h-full' key={5}>
                            <SimpleDropdown reset={campaignCtx.reset} placeholder={"Choose sender"} data={accountCtx.allowedSenders.map((allowedSender, i) => {
                                return {
                                    value: allowedSender,
                                    title: allowedSender,
                                    id: Date.now() + i
                                }
                            })}
                            preSelect={(itemMatch: SingleItem) => {
                                return accountCtx.defaultSender === itemMatch.title
                            }}
                             selectFn={(item: SingleItem | null): void => {
                                handleFromInput(item)
                            } } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FlexSendCampaignBar;