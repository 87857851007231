import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {dateToDay, Day, daysAreEqual} from "../dates/helpers";
import AccountContext from "./account-context";

/**
 * Gets all days that campaigns are allowed to be scheduled on.
 * Valid days are:
 * - Either in the current month or next month
 * - Not in the past
 * - In the special case of today, there must still be time
 *   left (i.e. the current time can't be later than 21:30).
 * @param now The current time.
 * @param dispatchAnyTime Allow sending at night.
 * @returns A list of valid days for the given date.
 */
const getValidDays = (now: Date, dispatchAnyTime: boolean) => {
    let days: Day[] = []

    // If the time is not too late, add the current day.
    const hours = now.getHours()
    const minutes = now.getMinutes()
    if (dispatchAnyTime) {
        if (hours < 23 || (hours === 23 && minutes < 30)) {
            days.push(dateToDay(now))
        }    
    }
    else if (hours < 21 || (hours === 21 && minutes < 30)) {
        days.push(dateToDay(now))
    }

    // Add all remaining days in the current month and next month.
    let cur = new Date(now.valueOf());
    cur.setDate(cur.getDate() + 1); // next day
    const validMonths = [now.getMonth(), (now.getMonth() + 1) % 12]
    while (validMonths.includes(cur.getMonth())) {
        days.push(dateToDay(cur))
        cur.setDate(cur.getDate() + 1); // next day
    }
    return days;
}

/**
 * Gets all hours that can be scheduled on for the given day and current time.
 * @param day A day.
 * @param now The current time.
 * @param dispatchAnyTime Allow sending at night.
 * @returns A list of valid hours for the given date.
 */
const getValidHours = (day: Day, now: Date, dispatchAnyTime: boolean) => {
    let allHours =  Array(24).fill('').map((_, i) => i);
    let hours = dispatchAnyTime? allHours : allHours.filter(x => x >= 8 && x <= 21)
    if (daysAreEqual(day, dateToDay(now))) {
        hours = hours.filter(x => x > now.getHours() || (x === now.getHours() && now.getMinutes() <= 30))
    }
    return hours;
}

/**
 * Gets all minutes that can be scheduled on for the given day, hour and current time.
 * @param day A day.
 * @param hour An hour.
 * @param now The current time.
 * @returns A list of valid minutes for the given date.
 */
const getValidMinutes = (day: Day, hour: number, now: Date) => {
    let minutes = [0, 15, 30, 45]
    if (daysAreEqual(day, dateToDay(now))) {
        if (now.getHours() === hour) {
            minutes = minutes.filter(x => x >= now.getMinutes() + 15)
        } else if (now.getHours() === hour - 1 && now.getMinutes() + 15 > 60) {
            minutes.shift();
        }
    }
    return minutes
}

type State = {
    day: Day,
    hour: number,
    minutes: number,
    validDays: Day[],
    validHours: number[],
    validMinutes: number[],
}

type CampaignTimeProviderProps = {
    dispatchAnyTime: boolean
}

export const CampaignTimeProvider: React.FC<CampaignTimeProviderProps> = ({ children, dispatchAnyTime }) => {
    const [now, setNow] = useState(new Date());
    const [state, setState] = useState(() => {
        const initialDays = getValidDays(now, dispatchAnyTime);
        const initialHours = getValidHours(initialDays[0], now, dispatchAnyTime);
        const initialMinutes = getValidMinutes(initialDays[0], initialHours[0], now);
        const initialState: State = {
            validDays: initialDays,
            validHours: initialHours,
            validMinutes: initialMinutes,
            day: initialDays[0],
            hour: initialHours[0],
            minutes: initialMinutes[0],
        }
        return initialState;
    });
    
    const {validDays, validHours, validMinutes} = state;
    const {day, hour, minutes} = state;
    const {year, month, dayOfMonth} = day;
    const yearString = year.toString();
    const monthString = (month + 1).toString().padStart(2, '0');
    const dayOfMonthString = dayOfMonth.toString().padStart(2, '0');
    const hourString = hour.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    const dayString = `${yearString}-${monthString}-${dayOfMonthString}`;
    const timeString = `${hourString}:${minutesString}`;
    const dayAndTimeString = `${dayString} ${timeString}`;
    const date = new Date(year, month, dayOfMonth, hour, minutes);
    const utcString = (() => {
        let s = '';
        s += date.getUTCFullYear();
        s += '-';
        s += date.getUTCMonth() + 1;
        s += '-';
        s += date.getUTCDate();
        s += ' ';
        s += date.getUTCHours();
        s += ':';
        s += date.getUTCMinutes();
        return s;
    })()

    const setDay = (newSelectedDay: Day) => {
        setState(oldValue => {
            if (daysAreEqual(oldValue.day, newSelectedDay)) return oldValue;
            const validHours = getValidHours(newSelectedDay, now, dispatchAnyTime);
            const selectedHour = Math.max(validHours[0], 8);
            const validMinutes = getValidMinutes(newSelectedDay, selectedHour, now);
            const selectedMinutes = validMinutes[0];
            return {
                ...oldValue,
                day: newSelectedDay,
                hour: selectedHour,
                minutes: selectedMinutes,
                validHours,
                validMinutes,
            }
        });
    };

    const setHour = (newSelectedHour: number) => {
        setState(oldValue => {
            if (oldValue.hour === newSelectedHour) return oldValue;
            const validMinutes = getValidMinutes(oldValue.day, newSelectedHour, now);
            const selectedMinutes = validMinutes[0];
            return {
                ...oldValue,
                hour: newSelectedHour,
                minutes: selectedMinutes,
                validMinutes,
            }
        });
    };
    
    const setMinutes = (newSelectedMinutes: number) => {
        setState(oldValue => {
            if (oldValue.minutes === newSelectedMinutes) return oldValue;
            return {
                ...oldValue,
                minutes: newSelectedMinutes,
            }
        });
    };

    // When "now" changes, make sure the selected day/hour/minutes are still valid.
    // If not, reset them to the earliest valid time.
    useEffect(() => {
        setState(oldValue => {
            const validDays = getValidDays(now, dispatchAnyTime);
            const dayStillValid = validDays.some(x => daysAreEqual(x, oldValue.day));
            const selectedDay = dayStillValid? oldValue.day : validDays[0];
           
            const validHours = getValidHours(selectedDay, now, dispatchAnyTime);
            const hourStillValid = dayStillValid && validHours.includes(oldValue.hour);
            const selectedHour = hourStillValid? oldValue.hour : validHours[0];
           
            const validMinutes = getValidMinutes(selectedDay, selectedHour, now);
            const minutesStillValid = hourStillValid && validMinutes.includes(oldValue.minutes);
            const selectedMinutes = minutesStillValid? oldValue.minutes : validMinutes[0];

            return {
                validDays,
                validHours,
                validMinutes,
                day: selectedDay,
                hour: selectedHour,
                minutes: selectedMinutes,
            }
        });
    }, [now]);
    
    // Used to update "now" every minute
    const timeoutId = useRef(0);
    const intervalId = useRef(0);

    // Sets the value of "now" to the current date. Every minute, on the minute.
    useEffect(() => {
        const now = new Date();
        const initialDelay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

        // Wait until the start of the next minute, then set up the tick interval.
        timeoutId.current = window.setTimeout(() => {
            const fn = () => setNow(new Date());
            fn();
            intervalId.current = window.setInterval(fn, 60000);
        }, initialDelay);

        return () => {
            clearInterval(timeoutId.current);
            clearInterval(intervalId.current);
        };
    }, []);
    
    return (
        <CampaignTimeContext.Provider
            value={{
                validDays,
                validHours,
                validMinutes,
                day,
                hour,
                minutes,
                dayString,
                timeString,
                dayAndTimeString,
                utcString,
                date,
                setDay,
                setHour,
                setMinutes,
            }}
        >
            {children}
        </CampaignTimeContext.Provider>
    );
};

type ContextProps = {
    validDays: Day[],
    validHours: number[],
    validMinutes: number[],
    day: Day,
    hour: number,
    minutes: number,
    dayString: string,
    timeString: string,
    dayAndTimeString: string,
    utcString: string,
    date: Date,
    setDay: (date: Day) => void,
    setHour: (hour: number) => void,
    setMinutes: (minute: number) => void,
}

const CampaignTimeContext = createContext<ContextProps | undefined>(undefined);

export const useCampaignTime = () => {
    const context = useContext(CampaignTimeContext);
    if (context === undefined) {
        throw new Error("useCampaignTime must be used within a CampaignTimeContext");
    }
    return context;
};
