import { classNames } from "helpers"

type DropdownItemProps = {
    id: number,
    title: string,
    handleClick: () => void,
    checkbox?: boolean,
    checkboxChecked?: boolean,
    noDivider?: boolean
}

const DropdownItem = ({ id, title, checkbox, handleClick, checkboxChecked, noDivider }: DropdownItemProps) => {
    return (
        <div className={
            classNames(
                "flex flex-col ",
                !noDivider && "border-b border-gray"
            )
        } key={id}>
            <button
                className={`p-4 font-inherit flex items-center appearance-none transition-all duration-200 cursor-pointer hover:bg-birchwood/20  `}
                key={id}
                onClick={() => handleClick()}
            >
                {
                    checkbox &&
                    <input
                        type={"checkbox"}
                        onChange={() => undefined}
                        checked={checkboxChecked}
                        className="rounded border-gray text-tamahagane focus:ring-darkAqua col-span-1 self-center w-6 h-6 mr-4" />
                }
                <p className="text-left text-base">{title}</p>
            </button>
        </div>
    )
}

export default DropdownItem