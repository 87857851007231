
import { XCircleIcon } from '@heroicons/react/20/solid'
import Button from 'components/Button';
import './index.css';

type RemovedAlertProps = {
    undoFn: () => void,
    removeText: string
}

export default function RemovedAlert({ removeText, undoFn }: RemovedAlertProps) {
    return (
        <div className="border-l-4 border-[3px] border-white bg-brightWhite p-4 pr-8 removedAlert z-10 w-full">
            <div className="flex flex-col gap-4">
                <div className='flex items-center'>

                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-6 w-6 text-tamahagane" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <p className="text-base text-tamahagane font-medium">
                            {removeText} 
                        </p>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='w-full bg-white border-gray border-[0.5px] rounded-md'>
                        <div className='bg-redPanda w-full remove-progress h-8 rounded-md'></div>
                    </div>
                    <Button handleClick={undoFn} style={"w-[12rem] px-8 h-10 cursor-pointer"} bgColor="bg-tamahagane" textColor='white' text={'Cancel'}></Button>
                </div>
            </div>
        </div>
    )
}