import {
    ChangeEvent,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";

import AccountContext from "store/account-context";
import CampaignContext from "store/campaign-context";
import MessageContext from "store/message-context";
import TemplateContext from "store/template-context";
import MessageBarDropdown from "./MessageBarDropdown";

import Button from "components/Button";
import SendTestSMSBtn from "./SendTestSMSBtn";
import { classNames, getCookie } from "helpers";
import phoneImg from "./phone.png";
import { ShortlinksContext } from "store/shortlink-context";
import { useToolbarContext } from "store/toolbar-context";
import Toolbar, { ToolbarBodySettings } from "components/Toolbar";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { SmsInfoType, smsTypeAndCount } from "./helpers";
import MessageBarToolbar from "./MessageBarToolbar";

const FlexMessageBar = () => {
    const messageCtx = useContext(MessageContext);
    const campaignCtx = useContext(CampaignContext);
    const templateCtx = useContext(TemplateContext);
    const accountCtx = useContext(AccountContext);
    const shortlinksCtx = useContext(ShortlinksContext);
    const { setToolbarOpen } = useToolbarContext();

    const [smsInfo, setSmsInfo] = useState<SmsInfoType>({
        charCount: 0,
        parts: 0,
        maxLengthForParts: 0,
        specialChars: [],
        isUnicode: false,
    });
    const [selectedTemplate, setSelectedTemplate] = useState("");

    const shortlinkRef: any = useRef(true);

    useEffect(() => {
        let token = getCookie("token");
        fetch(process.env.REACT_APP_API_BASE + "/templates", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                templateCtx.updateTemplates(data.templates);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const handleShortLinkSelect = (link: any) => {
        // Link contains all data
        messageCtx.updateLink(link);
        shortlinkRef.current.focus(); // When the context get's updated, the dropdown loses focus. Therefore we refocus it.
    };

    const handleMessageInput = (e?: any) => {
        e?.preventDefault();
        let raw = e ? e.target.value : messageCtx.message;
        let parsed = raw;
        if (messageCtx.link.hasOwnProperty("target_url")) {
            parsed = parsed.replaceAll("%link%", messageCtx.link.text);
        }
        let newSmsInfo = smsTypeAndCount(parsed);
        setSmsInfo(newSmsInfo);

        messageCtx.updateMessage(raw, newSmsInfo.parts);
    };

    const handleTemplateSelect = (templateItem: any) => {
        let message = "";

        if (templateItem) {
            let template: any = templateCtx.templates[templateItem.key];
            message = template.text;
            if (messageCtx.link.text) {
                message = message.replaceAll("%link%", messageCtx.link.text);
            }
            console.log("Template: ", template.description);
            setSelectedTemplate(template.description);
        } else {
            setSelectedTemplate("");
        }
        let newSmsInfo = smsTypeAndCount(message);
        setSmsInfo(newSmsInfo);

        messageCtx.updateMessage(message, newSmsInfo.parts);
    };

    useEffect(() => {
        setSelectedTemplate("");
    }, [campaignCtx.reset]);

    useEffect(() => {
        handleMessageInput();
    }, [messageCtx.link]);

    const renderMessage = (message: string, link: string) => {
        if (link) {
            message = message.replace(/%link%/g, link);
        }        
        return message;
    };

    return (
        <>
            <MessageBarToolbar
                targetLink={messageCtx.link.target_url}
                linkShortCode={messageCtx.link.short_code}
            />
            <div className="flex sectionShadow border border-newSilver">
                <div className="flex flex-col px-16 pt-8 pb-16 w-full 3xl:w-[70%]  bg-white border-r border-gray">
                    <div className="defaultSectionWidth">
                        <div className="mb-8 flex justify-between items-center">
                            <p className="text-lg font-medium text-tamahagane leading-8">
                                {campaignCtx.name
                                    ? campaignCtx.name
                                    : "Untitled campaign"}
                            </p>
                            <HiPencilAlt className="w-7 h-7 text-birchwood" />
                        </div>

                        <div className="mb-4 relative rounded-default h-[26rem] border-solid border focus:border-gunMetal25 border-gunMetal25 overflow-auto">
                            <textarea
                                onChange={handleMessageInput}
                                value={messageCtx.message}
                                className={classNames(
                                    "ring-0 focus:ring-0 apperance-none resize-none text-sm text-gunMetal text-clip outline-0 py-2 px-3 h-3/4 w-full border-none"
                                )}
                                tabIndex={5}
                            ></textarea>
                            {messageCtx.link.short_code ? (
                                <div className=" bg-birchwood bottom-0 absolute w-full p-2">
                                    <div className="flex justify-between w-full">
                                        <p className="font-semibold text-white text-sm">
                                            Apply link by typing:{" "}
                                            <span className="text-white font-normal text-sm ">
                                                %link%
                                            </span>
                                        </p>
                                        <div className="flex">
                                            <p className="text-white text-sm">
                                                {messageCtx.link.short_domain +
                                                    "/" +
                                                    messageCtx.link
                                                        .short_code}{" "}
                                                -
                                            </p>
                                            <button
                                                className="appearance-none ml-2"
                                                onClick={() =>
                                                    setToolbarOpen(true)
                                                }
                                            >
                                                <p className="text-white text-sm font-semibold">
                                                    Edit
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : undefined}
                        </div>

                        <table className="table-auto w-full mb-10">
                            <thead>
                                <tr>
                                    <th className="">
                                        <div className="flex">
                                            <p className="text-sm whitespace-pre font-semibold">
                                                Characters
                                            </p>
                                            <div className="w-26 flex">
                                                <p
                                                    className={`text-sm opacity-70 font-normal ml-1 `}
                                                >
                                                    {`${smsInfo.charCount} / ${smsInfo.maxLengthForParts}`}
                                                </p>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="">
                                        <div className="flex">
                                            <p className="text-sm font-semibold">
                                                SMS
                                            </p>
                                            <p className="text-right ml-2 text-sm font-normal opacity-70">
                                                {campaignCtx.totalSize
                                                    ? smsInfo.parts *
                                                      campaignCtx.totalSize
                                                    : "-"}
                                            </p>
                                        </div>
                                    </th>
                                    <th className="">
                                        <p className="text-sm font-semibold min-w-[11rem]">
                                            Cost{" "}
                                            <span className="text-sm font-normal opacity-70 w-12">
                                                {campaignCtx.totalSize
                                                    ? `${(
                                                          (campaignCtx.totalCostPerPart /
                                                              100 /
                                                              100) *
                                                          smsInfo.parts
                                                      ).toFixed(2)} ${
                                                          accountCtx.billingCurrency
                                                      }`
                                                    : "-"}
                                            </span>
                                        </p>
                                    </th>
                                    <th className="">
                                        <p className="text-sm font-semibold col-span-1 truncate">
                                            Special chars
                                            <span className="font-normal text-sm opacity-70 ">
                                                {" "}
                                                {smsInfo.specialChars.length ===
                                                    0 && "None"}
                                                {smsInfo.specialChars.length <=
                                                    3 &&
                                                    smsInfo.specialChars.join(
                                                        ""
                                                    )}
                                                {smsInfo.specialChars.length >
                                                    3 &&
                                                    smsInfo.specialChars
                                                        .slice(0, 3)
                                                        .join("") +
                                                        " +" +
                                                        (smsInfo.specialChars
                                                            .length -
                                                            3)}
                                            </span>
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className="h-12"></div>
                        <div className="absolute bottom-16">
                            <div className="flex gap-4">
                                <div className="flex justify-between">
                                    <Button
                                        handleClick={() => setToolbarOpen(true)}
                                        text={"Add link"}
                                        bgColor="bg-birchwood"
                                        textColor="white"
                                        textStyles="font-semibold"
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white 3xl:w-[30%] md:w-[40%] flex flex-col items-center px-8 relative lg:w-full">
                    <div className="phoneImg">
                        <img src={phoneImg} alt="" className="mt-8" />
                        <div className=" absolute mt-16 top-0 text-center w-full">
                            <div className="flex flex-col items-center">
                                <div className="border-b border-gray w-9/12 pb-4 pt-6">
                                    <p className="text-center text-sm mt-0.5 text-black">
                                        {campaignCtx.from
                                            ? campaignCtx.from
                                            : "Sender"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="absolute top-32 w-full ">
                            <div className="w-4/5 mx-auto rounded-xl rounded-br-none mt-3">
                                <p
                                    className={`text-xs leading-tight break-words p-3 whitespace-pre-wrap max-h-96 overflow-auto custom-scrollbar ${
                                        !messageCtx.message && "italic"
                                    } text-tamahagane`}
                                >
                                    {messageCtx.message
                                        ? renderMessage(
                                              messageCtx.message,
                                              messageCtx.link.text
                                          )
                                        : "No message to preview."}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-16 absolute">
                        <SendTestSMSBtn
                            text="Preview"
                            style="rounded-default hover:bg-decoGray mx-auto bg-tamahagane h-12 w-48 flex items-center"
                            message={messageCtx.message}
                            link={messageCtx.link}
                        ></SendTestSMSBtn>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlexMessageBar;
