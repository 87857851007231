import { useEffect, useState } from "react";
import { ActivationForm } from "./ActivationForm";
import { ActivationSuccessContainer } from "./ActivationSuccessContainer";
import { IntegrationMetas } from "data/IntegrationMetas";
import { GAPropertyForm } from "./GAPropertyForm";

export const ActivationStatus = {
    FORM: 0,
    SUCCESS: 1,
    ERROR: -1
}

const ActivationRoute = () => {

    const [status, setStatus] = useState(ActivationStatus.FORM)
    const [integrationName, setIntegrationName] = useState<string | null>()
    const [integrationKey, setIntegrationKey] = useState<string | null>()
    const [features, setFeatures] = useState<string[] | null>()

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const name = queryParams.get("integrationName");
        const key = queryParams.get("integrationKey");
        const encodedString = queryParams.get('features');
        if (encodedString != null) {
            const decodedString = decodeURIComponent(encodedString);
            setFeatures(JSON.parse(decodedString));
        }
        setIntegrationName(name)
        setIntegrationKey(key)
    }, [])

    const updateStatus = (status: number) => {
        setStatus(status)
        if (status === ActivationStatus.SUCCESS) {
            window.opener.postMessage('featureActivated', '*');
        }
    }

    if (integrationName === IntegrationMetas.GoogleAnalytics.name && (status !== ActivationStatus.SUCCESS) ) {
        return (
            <>
                <GAPropertyForm onStatusChanged={updateStatus} updateFeature={setFeatures} />
            </>
        )
    } else {
        return (
            <>
                {integrationName === IntegrationMetas.Mailchimp.name && <ActivationSuccessContainer integrationName={integrationName} features={features} />}
                {status === ActivationStatus.FORM && <ActivationForm integrationName={integrationName} integrationKey={integrationKey} onStatusChanged={updateStatus} />}
                {status === ActivationStatus.SUCCESS && <ActivationSuccessContainer integrationName={integrationName} features={features} />}
                {status === ActivationStatus.ERROR && <p>Error, something went wrong</p>}
            </>
        )
    }
}

export default ActivationRoute
