import { getCookie } from 'helpers';
import React, {useState, useEffect, ReactNode, createContext, useContext} from 'react';

type State = {
    dispatches: Array<Dispatch>,
    loading: boolean,
    error: any,
    refreshLatest: () => void,
    updateCampaign: (_id: string, updateData: any) => void,
}

const initialState: State = {
    dispatches: [],
    loading: true,
    error: null,
    refreshLatest: () => {},
    updateCampaign: (_id: string, updateData: any) => {},
}

const DispatchContext = createContext<State>(initialState as State)


export const DispatchContextProvider = ({ children }: { children: ReactNode }) => {
    const [data, setData] = useState<Array<Dispatch>>([]);
    const [loading, setLoading] = useState(true);

    const refreshCampaigns = (pageNumber: number = 0, recursive: boolean = true) => {
        console.log("Refreshing all campaigns.")
        let token = getCookie("token");
        fetch(process.env.REACT_APP_API_BASE + "/dispatches/v2?page=" + pageNumber + "&per_page=20", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => response.json())
            .then((dispatchesRes) => {
                
                if (dispatchesRes?.dispatches && dispatchesRes.dispatches.length > 0) {
                    setData((prev: any) => {
                        let dispatches = [...(prev || []), ...dispatchesRes.dispatches];
                        dispatches = dispatches.filter((v, i, a) => a.map(e => e.number).indexOf(v.number) === i);
                        dispatches.sort((a, b) => b.number - a.number)
                        return dispatches
                    });
                    if (recursive) {
                        refreshCampaigns(pageNumber + 1);
                    }
                } else {
                    setLoading(false)
                }
            });
    }
    
    const refreshLatest = () => {
        refreshCampaigns(0, false);
    }

    const updateCampaign = (_id: string, updatedData: any) => {
        setData((prevCampaigns: any) =>
            prevCampaigns.map((prevCampaign: any) =>
                prevCampaign._id === _id ? { ...prevCampaign, ...updatedData } : prevCampaign
            )
        );
    }
    
    useEffect(() => {
        refreshCampaigns(0);
    }, []);

    return (
        <DispatchContext.Provider value={{ dispatches: data, loading, error: null, refreshLatest, updateCampaign }}>
            {children}
        </DispatchContext.Provider>
    );
};

export default DispatchContext;