import { Transition } from '@headlessui/react';
import { classNames, setCookie } from 'helpers';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import './index.css';


type SignInFormProps = {
    failed: boolean,
    handleLogin: (data: { email: string, password: string }) => Promise<{ result: any }>,
}

// Have signin functionality here except for the actual http request
export default function SignInForm(props: SignInFormProps) {
    const [showTokenField, setShowTokenField] = useState(false);
    const [error, setError] = useState({ email: false, password: false, message: "" });
    const [animateDiv, setAnimateDiv] = useState("");

    const emailRef: any = useRef();
    const passwordRef: any = useRef();
    const tokenRef: any = useRef();

    const handleSubmit = async (e: any) => {
        setAnimateDiv("");
        e.preventDefault();
        // Do api call to /login
        // using fetch
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const token = tokenRef.current?.value || "";
        const data = { email, password };

        if (email === "token") {
            setShowTokenField(true);
            emailRef.current.value = "";
            return;
        }

        if (token) {
            setCookie("token", token);
            window.location.reload();
            return;
        }

        try {
            const response = await props.handleLogin(data);

            let errors = response.result?.message || "";
            if (errors.includes("email", "password")) {
                setError((oldVal: any) => ({ ...oldVal, email: true, message: "Invalid email/password combination" }))
                setAnimateDiv("shake-div");
            } else if (errors.includes("email")) {
                setError((oldVal: any) => ({ ...oldVal, password: true, message: "Missing email" }))
                setAnimateDiv("shake-div");
            } else if (errors.includes("password")) {
                setError((oldVal: any) => ({ ...oldVal, password: true, message: "Missing password" }))
                setAnimateDiv("shake-div");
            }

            if (response.result.success) {
                setCookie("token", response.result.token);
                window.location.reload();
            }
        } catch (error) {
            console.error("Error occurred:", error);
        }
    }
    return (
        <Transition
            appear={true}
            show={true}
            enter="duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl ">
                <div className="bg-white py-10 px-4 drop-shadow-lg sm:rounded-lg sm:px-16">
                    <form className='space-y-8' onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-base font-medium text-tamahagane">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    autoComplete="email"
                                    className={
                                        classNames(
                                            "block w-full appearance-none rounded-md border px-3 py-4 placeholder-gray-400 shadow-sm focus:border-tamahagane/70 focus:outline-none focus:ring-tamahagane/70 focus:text-left sm:text-base",
                                            error.email ? "border-redPanda" : "border-birchwood "
                                        )
                                    }
                                    ref={emailRef}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-base font-medium text-tamahagane">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    className={classNames(
                                        "block w-full appearance-none rounded-md border px-3 py-4 placeholder-gray-400 shadow-sm focus:border-tamahagane/70 focus:outline-none focus:ring-tamahagane/70 sm:text-base",
                                        error.password ? "border-redPanda" : "border-birchwood "
                                    )}
                                    ref={passwordRef}
                                />
                            </div>
                        </div>

                        {
                            showTokenField ?
                                <div>
                                    <label htmlFor="token" className="block text-base font-medium text-tamahagane">
                                        Token
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="token"
                                            name="token"
                                            type="text"
                                            autoComplete="off"
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-tamahagane focus:outline-none focus:ring-tamahagane sm:text-sm"
                                            ref={tokenRef}
                                        />
                                    </div>
                                </div>
                                : undefined
                        }
                        <div>
                            <button
                                className={classNames(
                                    "flex w-full justify-center rounded-md border border-transparent bg-birchwood py-3 px-4 text-base font-medium text-white drop-shadow-md  hover:bg-birchwood focus:outline-none focus:ring-2 focus:ring-tamahagane focus:ring-offset-2",
                                    animateDiv
                                )}
                            >
                                Sign in
                            </button>
                        </div>
                    </form>

                </div>
            </div>
            <div className='h-12 p-4'>

                <Transition
                    appear={true}
                    show={error.message.length > 0}
                    enter="duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className='max-w-md mx-auto'>
                        <p className='text-lg text-red-500 text-center normal-case'>{error.message}</p>
                    </div>
                </Transition>
            </div>
        </Transition>
    )
}