import { CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import AlertContext from "store/alert-context";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const NewToast = () => {

    const alertCtx = useContext(AlertContext);

    return (
        <div className="fixed top-24 right-14 z-50 xl:w-1/5 md:w-1/3">
            <div className="flex flex-col w-full">
                {
                    alertCtx.alerts.map((toast: any, i: number) => {
                        return (
                            <div
                                className={classNames(
                                    "drop-shadow-2xl py-3 px-6 text-white mb-2 relative rounded-lg",
                                    "bg-brightWhite border-white border-[3px]"
                                )}
                                key={toast.id}
                                id="toast"
                            >
                                <div className={"flex flex-row justify-between"}>
                                    <div className="flex items-center">
                                        {
                                            toast.status === "error" &&
                                            <XCircleIcon className="h-6 w-6 text-redPanda" aria-hidden="true" />
                                        }
                                        {
                                            toast.status === "success" &&
                                            <CheckCircleIcon className="h-6 w-6 text-acanthusLeaf" aria-hidden="true" />
                                        }

                                        <p className={classNames(
                                            "ml-2",
                                            "text-lg text-tamahagane font-medium",
                                        )}>{toast.title}</p>

                                    </div>
                                </div>
                                <div className="mt-2 flex">
                                    <p className={classNames(
                                        "pl-1",
                                        "text-base text-tamahagane",
                                    )}>{toast.description}</p>
                                </div>

                                <button className="absolute top-4 right-4" onClick={() => alertCtx.discardAlert(toast.alertType)}>
                                        <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                                </button>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default NewToast;