import { header } from "helpers";
import React, {
    createContext,
    ReactNode,
    useReducer,
    useMemo,
    useContext
} from "react";

type NewImports = {
    _id: string,
    name: string,
    result: string | null,
    state: "pending" | "success" | "failed"
}

type State = {
    imports: NewImports[] | any,
    pendingImports: NewImports[] | any,
    loading: boolean,
    error: any,
    requestAttempts: { [key: string]: number },
    failedImports: NewImports[] | any
}

// List of different groups to poll
type API = {
    pollImports: (newImports: NewImports[], integration: IntegrationItem) => void,
    getFinishedImports: () => NewImports[]
}

const initialState: State = {
    imports: [],
    pendingImports: [],
    loading: true,
    error: null,
    requestAttempts: {},
    failedImports: []
}

const ImportDataContext = createContext<State>(initialState as State)
const ImportAPIContext = createContext<API>({} as API)

type Action =
    | { type: "POLLING_NEW_IMPORTS"; payload: any }
    | { type: "NEW_IMPORT_DONE"; payload: any }
    | { type: "NEW_IMPORT_FAILED"; payload: any }
    | { type: "POLLING_FAILED"; payload: any }
    | { type: "EMPTY_IMPORTS" }
    | { type: "REQUEST_ATTEMPT_INC"; payload: any }
    | { type: "EMPTY_FAILED_IMPORTS" }

type Dispatch = (action: Action) => void;

const reducer = (state: State, action: Action): State => {
    let newPendingImports = [];
    switch (action.type) {
        case "POLLING_NEW_IMPORTS":
            return {
                ...state,
                loading: true,
                pendingImports: [...state.pendingImports, ...action.payload]
            }
        case "NEW_IMPORT_DONE":
            // Remove from pendingImports here
            newPendingImports = state.pendingImports.filter((pendingImport: any) => pendingImport._id !== action.payload._id);
            action.payload["isNew"] = true;
            return {
                ...state,
                pendingImports: newPendingImports,
                loading: false,
                imports: [...state.imports, action.payload]
            }
        case 'NEW_IMPORT_FAILED':
            newPendingImports = state.pendingImports.filter((pendingImport: any) => pendingImport._id !== action.payload._id);
            return { ...state, loading: false, pendingImports: newPendingImports, failedImports: [...state.failedImports, action.payload] };
        case 'POLLING_FAILED':
            newPendingImports = state.pendingImports.filter((pendingImport: any) => pendingImport._id !== action.payload._id);
            return { ...state, loading: false, pendingImports: newPendingImports, failedImports: [...state.failedImports, action.payload], error: "POLLING_FAILED" };
        case "EMPTY_IMPORTS":
            return {
                ...state,
                loading: false,
                imports: initialState.imports
            }
        case "REQUEST_ATTEMPT_INC":
            return {
                ...state,
                requestAttempts: { ...state.requestAttempts, [action.payload.id]: action.payload.requestAttempts }
            }
        case "EMPTY_FAILED_IMPORTS":
            return {
                ...state,
                loading: false,
                imports: initialState.imports
            }
    }
}

const pollData = async (dispatch: Dispatch, newImports: NewImports[], integration: IntegrationItem) => {

    dispatch({ type: 'POLLING_NEW_IMPORTS', payload: newImports });

    let integrationUrl: string = integration["url"]
    let integrationKey: string = integration["key"]

    await Promise.all(newImports.map((newImport: NewImports) => {
        new Promise((resolve, reject) => {
            let requestAttempts = 0
            const pollInterval = setInterval(() => {
                // Remove request attempts for now
                //if (requestAttempts > 7) {
                //    dispatch({ type: "POLLING_FAILED" })
                //    reject()
                //    clearInterval(pollInterval)
                //}
                fetch(integrationUrl + "/import/" + newImport["_id"] + "?v=2", header(integrationKey))
                    .then((r: any) => r.json()).then((res: any) => {
                        if (res.state === "success") {
                            clearInterval(pollInterval)
                            resolve(res)
                            dispatch({ type: 'NEW_IMPORT_DONE', payload: res })
                        }
                        else if (res.state === "failed") {
                            dispatch({ type: 'NEW_IMPORT_FAILED', payload: res })
                            clearInterval(pollInterval)
                            resolve(res)
                        }
                    }).catch((e: any) => {
                        console.log("Error: ", e)
                        if (requestAttempts > 5) {
                            dispatch({ type: 'POLLING_FAILED', payload: newImport })
                            clearInterval(pollInterval)
                        }
                    });
                dispatch({ type: 'REQUEST_ATTEMPT_INC', payload: { id: newImport["_id"], requestAttempts } })
                requestAttempts++
            }, 3500)
        })
    }))


};

export const ImportDataProvider = ({ children }: { children: ReactNode }) => {

    const [state, dispatch] = useReducer(reducer, initialState as State)

    const api = useMemo(() => {
        const pollImports = (newImports: NewImports[], integration: IntegrationItem) => {
            pollData(dispatch, newImports, integration)
        }
        const getFinishedImports = () => {
            let finishedImports = state.imports;
            dispatch({ type: "EMPTY_IMPORTS" })
            return finishedImports;
        }
        const getFailedImports = () => {
            let failedImports = state.failedImports;
            dispatch({ type: "EMPTY_FAILED_IMPORTS" })
            return failedImports;
        }

        return { pollImports, getFinishedImports, getFailedImports }
    }, [state.imports, state.failedImports])

    return (
        <ImportAPIContext.Provider value={api}>
            <ImportDataContext.Provider value={state}>
                {children}
            </ImportDataContext.Provider>
        </ImportAPIContext.Provider>
    )
}

export const useImportData = () => useContext(ImportDataContext)
export const useImportAPI = () => useContext(ImportAPIContext)