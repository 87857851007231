import {getCookie} from 'helpers';
import React, {useState, useEffect, createContext, useCallback} from 'react';

type CalendarItem = {
    id: string,
    title: string
    dateRange: string
    startDate: Date,
    endDate: Date
    description: string
}

const ALL_ITEMS: CalendarItem[] = [
    {
        id: 'earth-day-2024',
        title: 'Earth Day',
        dateRange: 'April 22',
        description: 'Expect messages focusing on sustainability to perform well. This is a good opportunity to promote your brand\'s eco-friendly products.',
        startDate: new Date(2024, 3, 22),
        endDate: new Date(2024, 3, 23),
    },
    {
        id: 'payday-april-se-2024',
        title: 'Payday (SE)',
        dateRange: 'April 25',
        description: 'Payday means people spend more money, on average, but it\'s easy to get lost in the noise. Smaller purchases might be better to promote in the middle of the month.',
        startDate: new Date(2024, 3, 25),
        endDate: new Date(2024, 3, 26),
    },
    {
        id: 'valborg-2024',
        title: 'Walpurgis Night (Valborg)',
        dateRange: 'April 30',
        description: 'Most people will be looking forward to warmer weather and being outdoors, as well as making plans for the summer.',
        startDate: new Date(2024, 3, 30),
        endDate: new Date(2024, 4, 1),
    },
    {
        id: 'payday-may-se-2024',
        title: 'Payday (SE)',
        dateRange: 'May 24',
        description: 'Payday means people spend more money, on average, but it\'s easy to get lost in the noise. Smaller purchases might be better to promote in the middle of the month.',
        startDate: new Date(2024, 4, 24),
        endDate: new Date(2024, 4, 25),
    },
    {
        id: 'national-day-se-2024',
        title: 'National Day (SE)',
        dateRange: 'June 6',
        description: 'National Day falls on a Thursday, meaning many will take extra time off for a long weekend.',
        startDate: new Date(2024, 5, 6),
        endDate: new Date(2024, 5, 7),
    },
    {
        id: 'graduation-2024',
        title: 'Graduation',
        dateRange: 'June 8 – June 17',
        description: 'Remember to market well in advance since people look forward to and plan for this.',
        startDate: new Date(2024, 5, 8),
        endDate: new Date(2024, 5, 17),
    },
    {
        id: 'summer-2024',
        title: 'Summer campaigns',
        dateRange: 'July 1 – July 31',
        description: 'Take advantage of people being on vacation and needing entertainment.',
        startDate: new Date(2024, 6, 1),
        endDate: new Date(2024, 6, 31),
    },
];

type UserCalendarItem = CalendarItem & {
    isNew: boolean
}

type State = {
    calendarItems: UserCalendarItem[],
    markAllAsSeen: () => void
}

const initialState: State = {
    calendarItems: [],
    markAllAsSeen: () => {}
}

export const CalendarItemsContext = createContext<State>(initialState);

const validItems = ALL_ITEMS.filter(({startDate, endDate})=> {
    const now = new Date();
    let nowPlus30 = new Date();
    nowPlus30.setDate(now.getDate() + 30);
    return startDate <= nowPlus30 && endDate > now; 
});

export const CalendarItemsContextProvider = (props: any) => {
    
    const [calendarItems, setCalendarItems] = useState<UserCalendarItem[]>([]);

    
    const markAllAsSeen = useCallback(() => {
        const unseen = calendarItems.filter(x => x.isNew);
        if (unseen.length === 0) return;
        
        const newCalendarItems: UserCalendarItem[] = validItems.map(x => ({...x, isNew: false}) as UserCalendarItem);
        setCalendarItems(newCalendarItems);
        
        (async () => {
            const url = process.env.REACT_APP_API_BASE + `/calendar_items/seen`;
            const token = getCookie("token");
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const ids = unseen.map(x => x.id);
            const body = JSON.stringify({ids})
            const resp = await fetch(url, {method: 'POST', headers, body});
            await resp.json();
            const newCalendarItems: UserCalendarItem[] = validItems.map(x => ({...x, isNew: false}) as UserCalendarItem);
            setCalendarItems(newCalendarItems);
        })()
    }, [calendarItems]);
    
    useEffect(() => {
        const getSeenIds = async () => {
            const ids = validItems.map(x => x.id).join(',');
            const url = process.env.REACT_APP_API_BASE + `/calendar_items/seen?ids=${ids}`;
            const token = getCookie("token");
            const headers = {Authorization: `Bearer ${token}`}
            const resp = await fetch(url, {headers});
            const respObj = await resp.json();
            return respObj['ids'] as string[];
        }
        
        (async () => {
            const seen = new Set(await getSeenIds());
            const newCalendarItems = validItems.map(x => ({...x, isNew: !seen.has(x.id)}));
            setCalendarItems(newCalendarItems);
        })();
    }, []);
    
    
    return (
        <CalendarItemsContext.Provider value={{
            calendarItems,
            markAllAsSeen,
        }}>
            {props.children}
        </CalendarItemsContext.Provider>
    );
};

export default CalendarItemsContext;
