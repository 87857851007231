import React from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import GroupRoute from './routes/GroupRoute';
import CampaignRoute from './routes/CampaignRoute';
import DashboardRoute from './routes/DashboardRoute';
import IntegrationsRoute from './routes/IntegrationsRoute';
import ActivationRoute from 'routes/IntegrationsRoute/ActivationRoute';
import CampaignDispatchesRoute from 'routes/CampaignDispatchesRoute';
import LogoutRoute from 'routes/LogoutRoute';

import { AccountContextProvider } from './store/account-context';
import { AlertContextProvider } from './store/alert-context';
import { GroupDataProvider } from './store/group-context';
import { DispatchContextProvider } from 'store/dispatch-context';
import {StatsContextProvider} from "./store/stats-context";
import { GAProvider } from 'store/ga-context';
import { ToolbarProvider } from 'store/toolbar-context';
import SettingsRoute from 'routes/SettingsRoute';
import IndividualCampaignRoute from 'routes/IndividualCampaignRoute';
import { ProgressBarProvider } from 'store/progressbar-context';
import { ImportDataProvider } from 'store/import-context';
import { ShortlinksProvider } from 'store/shortlink-context';
import FlowsRoute from "./routes/FlowsRoute";
import {FlowsContextProvider} from "./store/flows-context";
import NewFlowRoute from "./routes/FlowsRoute/create";
import EditFlowRoute from "./routes/FlowsRoute/edit";
import UpcomingContext from "./store/calendar-items-context";
import {CalendarItemsContextProvider} from "./store/calendar-items-context";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <DashboardRoute />
      },
      {
        path: "/create-campaign",
        element: <CampaignRoute />
      },
      {
        path: "/campaign-dispatches",
        element: <CampaignDispatchesRoute />
      },
      {
        path: "/campaign-dispatches/:id",
        element: <IndividualCampaignRoute />
      },
      {
        path: "/groups",
        element: <GroupRoute />
      },
      {
        path: "/integrations",
        element: <IntegrationsRoute />
      },
      {
          path: "/flows",
          element: <FlowsRoute />
      },
      {
        path: "/flows/new",
        element: <NewFlowRoute />
      },
      {
        path: "/flows/:id/edit",
        element: <EditFlowRoute />
      },
      {
        path: "/logout",
        element: <LogoutRoute />
      }
    ]
  },
  {
    path: "/integrations/activation",
    element: <ActivationRoute />
  }
], {
  basename: "/"
})

ReactDOM.render(
  <React.StrictMode>
    <AccountContextProvider>
      <GAProvider>
        <AlertContextProvider>
          <GroupDataProvider>
            <DispatchContextProvider>
              <StatsContextProvider>
                <ImportDataProvider>
                  <ProgressBarProvider>
                    <ToolbarProvider>
                        <ShortlinksProvider>
                            <FlowsContextProvider>
                                <CalendarItemsContextProvider>
                                    <RouterProvider router={router}/>
                                </CalendarItemsContextProvider>
                            </FlowsContextProvider>
                        </ShortlinksProvider>
                    </ToolbarProvider>
                  </ProgressBarProvider>
                </ImportDataProvider>
              </StatsContextProvider>
            </DispatchContextProvider>
          </GroupDataProvider>
        </AlertContextProvider>
      </GAProvider>
    </AccountContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
