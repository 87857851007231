import {MouseEvent, useEffect, useRef, useState} from 'react'
import {ChevronLeftIcon, ChevronRightIcon,} from '@heroicons/react/20/solid'
import {HiChevronDown, HiChevronRight} from "react-icons/hi";
import {useCampaignTime} from 'store/campaign-time-context';
import {Day, daysAreEqual, getCalendarPages} from "../../../../dates/helpers";


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}


function ScheduleDropdownNew(props: any) {
    const {validDays, day: selectedDay, dayString: selectedDayString, setDay} = useCampaignTime();
    const pages = getCalendarPages(validDays[0], validDays[validDays.length-1]);

    const [open, setOpen] = useState(false);
    const [visiblePage, setVisiblePage] = useState(pages[0]);
    const dropdownRef = useRef(null as any);

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    const closeDropdown = (e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            setOpen(false);
        }
    }

    const openDropdown = (_: any) => {
        setOpen(oldState => !oldState);
    }

    const isSelected = (day: Day) => daysAreEqual(day, selectedDay);
    const isValidDay = (day: Day) => validDays.some(x => daysAreEqual(day, x));
    const isInCurrentMonth = (day: Day) => {
        return visiblePage.daysInMonth.some(x => daysAreEqual(day, x));
    };

    const hasPrevMonth = pages[0].id !== visiblePage.id
    const hasNextMonth = pages[pages.length-1].id !== visiblePage.id

    const nextMonth = (e?: MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        
        setVisiblePage(oldValue => {
            const idx = pages.findIndex(x => x.id === oldValue.id);
            return pages[idx+1]
        });
    }

    const prevMonth = (e?: MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        setVisiblePage(oldValue => {
            const idx = pages.findIndex(x => x.id === oldValue.id);
            return pages[idx-1]
        });
    }
    
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [])
    
    return (

        <div className="relative w-full min-w-fit h-full bg-white" onBlur={closeDropdown} ref={dropdownRef} >
            {
                <button disabled={props.disabled} className={`cursor-pointer ${props.disabled && "cursor-not-allowed opacity-50 bg-neutral-100"} border font-inherit text-inherit appearance-none outline-none flex flex-row justify-between items-center border-gunMetal25 rounded-default py-3 px-3 h-12 w-full `} onClick={openDropdown} >
                    <p className="text-base ">{selectedDayString}</p>
                    {open ? <HiChevronDown /> : <HiChevronRight />}
                </button>
            }
            {
                open &&
                <div className='bg-white bottom-full mb-2 z-20 w-[30rem] absolute px-8 py-8 border border-gray rounded-default drop-shadow-lg'>
                    <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
                        <div className="flex items-center text-gray-900">
                            { hasPrevMonth &&
                            <button
                                type="button"
                                className="-m-1.5 flex absolute left-10 items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                                onClick={prevMonth}
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
                            </button>
                            }
                            <div className="flex-auto font-semibold text-lg">{visiblePage.name}</div>
                            { hasNextMonth &&
                            <button
                                type="button"
                                className="-m-1.5 flex absolute right-10 items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                                onClick={nextMonth}
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRightIcon className="h-8 w-8" aria-hidden="true" />
                            </button>
                            }
                        </div>
                        <div className='border border-gray rounded-lg overflow-hidden mt-4'>
                            <div className="py-2 grid grid-cols-7 text-xs leading-6 text-white bg-birchwood ring-1 ring-gray gap-px">
                                <div>M</div>
                                <div>T</div>
                                <div>W</div>
                                <div>T</div>
                                <div>F</div>
                                <div>S</div>
                                <div>S</div>
                            </div>
                            <div className="isolate grid grid-cols-7  bg-white text-sm ring-1 ring-gray">
                                {visiblePage.days.map((day: Day, dayIdx: number) => (
                                    <button
                                        key={dayIdx}
                                        type="button"
                                        className={classNames(
                                            'hover:bg-gray-100 focus:z-10 divide-x divide-gray mb-1',
                                            (!isValidDay(day) || (!isInCurrentMonth(day)))? 'bg-gunMetal70' : 'bg-white',
                                            (day === selectedDay) && ' font-normal',
                                            !isSelected(day) && isInCurrentMonth(day) && 'text-gray-900 font-light',
                                            (!isValidDay(day) || (!isSelected(day) && !isInCurrentMonth(day))) && 'text-gunMetal25 font-light',
                                            dayIdx === visiblePage.days.length - 7 && 'rounded-bl-lg',
                                            dayIdx === visiblePage.days.length - 1 && 'rounded-br-lg',
                                        )}
                                        onClick={() => {
                                            if (!isValidDay(day)) return;
                                            setDay(day);
                                            if (day.year > visiblePage.year || (day.year === visiblePage.year && day.month > visiblePage.month)) {
                                                nextMonth();
                                            } else if (day.year < visiblePage.year || (day.year === visiblePage.year && day.month < visiblePage.month)) {
                                                prevMonth();
                                            }
                                        }}
                                    >
                                        <time
                                            className={classNames(
                                                'mx-auto flex items-center justify-center rounded-full text-sm w-full h-full py-3',
                                            isSelected(day) ? 'border-2 border-birchwood rounded-full mx-2': 'border-2 border-transparent'
                                            )}
                                        >
                                            {day.dayOfMonth}
                                        </time>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


export default ScheduleDropdownNew;