import { ReactNode, useState } from "react"

import { classNames } from "helpers";
import { BsSearch } from "react-icons/bs";

type DropdownItemProps = {
    id: number,
    title: string,
    value: string
}

type SearchableDropdownBodyProps = {
    renderItem: ({ id, title, value }: DropdownItemProps) => ReactNode,
    data: DropdownItemProps[],
    searchPlaceholder: string,
    bodyDirection?: string,
    zIndex?: string
    maxHeight?: string
}

const SearchableDropdownBody = ({ renderItem, data, bodyDirection, searchPlaceholder, zIndex, maxHeight}: SearchableDropdownBodyProps) => {
    const [searchQuery, setSearchQuery] = useState("")

    const handleSearch = (e: any) => {
        setSearchQuery(e.target.value);
    }

    return (
        <div className={
            classNames(
                "absolute top-full mt-1 w-[105%] min-w-[26rem] bg-white dropdownShadow border border-granitine will-change-filter rounded-default",
                zIndex ? zIndex : "z-10",
                bodyDirection ? bodyDirection + "-0" : "left-0"
            )
        }>
            <div className="flex border-solid items-center p-4">
                <BsSearch className="w-5 h-5 mr-4 text-gunMetal50" />
                <div className="p-2 flex w-full border border-gunMetal25 rounded-default">
                    <input name="q" type="text" autoComplete="off" aria-autocomplete="none" autoCorrect="off" onChange={handleSearch} className="focus:ring-0 border-0 apperance-none w-full ml-1 outline-none text-base transition-all" placeholder={searchPlaceholder}></input>
                </div>
            </div>
            <div className={
                classNames(
                    "overflow-auto h-[20rem]",
                    maxHeight && maxHeight
                )
            }>
                {
                    data.filter((item: any) => {
                        if (searchQuery === "") {
                            return item
                        }
                        let match = true;
                        if (searchQuery) {
                            if (
                                searchQuery.length >= 2 && item.title.toLowerCase().includes(searchQuery)
                            ) {
                            } else {
                                match = false;
                            }
                        }
                        if (match) {
                            return item;
                        }
                    }).map((item: DropdownItemProps, i: number) => {
                        return renderItem({ id: i, title: item.title, value: item.value })                    
                    })
                }
            </div>
        </div>
    )
}

export default SearchableDropdownBody