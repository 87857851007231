import {classNames} from "helpers";
import "./index.css";

type StatCardProps = {
    title: string,
    value: string,
    icon: React.ReactNode,
    diff?: string,
    diffDesc?: string,
    diffIcon?: 'up' | 'down',
    handleClick?: () => void
}

const StatCard = (props: StatCardProps) => {
    return (
        <div
            onClick={props.handleClick ? () => props.handleClick && props.handleClick() : () => undefined}
            className={
                classNames(
                    "flex flex-col col-span-1 px-6 py-4 bg-white rounded-lg gap-4 statCardShadow",
                    props.handleClick ? "cursor-pointer statCardOnClick" : ""
                )
            }
        >
            <div className="flex justify-between items-center">
                <p className="text-base font-semibold">{props.title}</p>
                <div className="text-birchwood">
                    {props.icon}
                </div>
            </div>
            <p className="text-lg font-extralight">{props.value}</p>
            <div className="flex items-end">
                {props.diffIcon &&
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3}
                         stroke="currentColor" className="w-6 h-6 text-decoGray">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"/>
                    </svg>
                }
                {props.diff &&
                    <p className="text-base font-semibold text-decoGray leading-none mr-4">{props.diff}</p>
                }
                {props.diffDesc &&
                    <p className="text-base font-light leading-none">{props.diffDesc}</p>
                }
            </div>
        </div>
    )
}

export default StatCard;