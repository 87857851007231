import { type DateObject } from "components/RangeCalendar";

export function getLastNDays(n: number, startingDate?: DateObject): { startDate: DateObject | null, endDate: DateObject | null} {

    if(n == -1) {
        return {startDate: null, endDate: null}
    }

    let startingPoint = startingDate ? new Date(startingDate.year, startingDate.month - 1, startingDate.day) : new Date(); // Get the current date
    startingPoint.setDate(startingPoint.getDate() + 1);

    let endDate = {
        year: startingPoint.getFullYear(),
        month: startingPoint.getMonth() + 1, // In JavaScript, months are 0-based.
        day: startingPoint.getDate()
    };
    

    // N days ago.
    let startDateObj = startingPoint;
    startDateObj.setDate(startingPoint.getDate() - n);
    let startDate = {
        year: startDateObj.getFullYear(),
        month: startDateObj.getMonth() + 1, // In JavaScript, months are 0-based.
        day: startDateObj.getDate()
    };

    return { endDate, startDate};
}

export function prettyDate(dateObj: DateObject) {
    if (dateObj) {
        return new Date(dateObj?.year, dateObj?.month-1, dateObj.day).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
        })
    }
}