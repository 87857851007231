import { useContext, useEffect, useState, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import AccountContext from './store/account-context';
import { useGroupAPI } from './store/group-context';

import SideBar from 'components/SideBar';
import NewToast from './components/NewToast';
import BogioLogo from './bogio_logo.png'

import {classNames, deleteCookie, getCookie, setCookie} from './helpers';
import { GAContext } from 'store/ga-context';
import { Transition } from '@headlessui/react';
import SignInForm from 'components/SignInForm';

const RestoreScrollWrapper = ({ children }: any) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

function App() {
  const accountCtx = useContext(AccountContext);
  const groupAPICtx = useGroupAPI();
  const GACtx = useContext(GAContext);

  const [authorized, setAuthorized] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(true);

  const emailRef: any = useRef();
  const passwordRef: any = useRef();
  const tokenRef: any = useRef();

  if (window.location.href.includes("authid")) {
    let pathSplit = window.location.href.split("=");
    let token = pathSplit[pathSplit.length - 1].trim();
    setCookie("token", token);
    window.location.href = window.location.origin;
  }

  useEffect(() => {
    const fetchAccount = async () => {
      let token = getCookie("token");
      const accountData = await fetch(process.env.REACT_APP_API_BASE + "/account", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let accountRes = await accountData.json();
      if (accountRes.error && accountRes.error === 403) {
        document.cookie = 'token=; Max-Age=0';
        deleteCookie("token");
        setAuthorized(false);
        if (window.location.pathname !== '/') {
            window.location.href = '/'
        }
      } else {
        setAuthorized(true);

        const userData = await fetch(process.env.REACT_APP_API_BASE + "/user", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const userRes = await userData.json();
        accountRes.account["user"] = userRes.user;
        accountCtx.loadData(accountRes.account);
      }
      setLoading(false);
    }
    fetchAccount().then();
  }, [reFetch]);

  useEffect(() => {
    if (accountCtx.integrations) {
      let token = getCookie("token");
      groupAPICtx.fetchGroups(token);
      GACtx?.fetchMeta(accountCtx.integrations);
    }
  }, [accountCtx.integrations]);

  async function handleSubmit(data: { email: string, password: string }): Promise<{ result: any }> {
    const response = await fetch(process.env.REACT_APP_API_BASE + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const result = await response.json();
    return { result };
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <p className='text-lg'>Loading...</p>
      </div>
    )
  }

  if (!authorized) {
    return (
        <>
          <div className="min-h-screen flex box-border bg-brightWhite flex-col justify-between sm:px-6 lg:px-8">
            <div>
              <div className="justify-center sm:mx-auto sm:w-full -w-md mt-20 w-full">
                <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo" className="m-auto w-32 mb-12"/>
                <h2 className="text-center text-4xl tracking-tight text-tamahagane font-semibold">Sign in to your account</h2>
              </div>

              <SignInForm failed={false} handleLogin={handleSubmit}/>
              <p className="text-center tracking-tight font-semibold text-sm">app.bogio.tech is currently
                invite-only</p>
            </div>
            <div className="p-12 md:pl-20 text-center md:text-left">
              <p className="mb-4 font-semibold">Don't worry, your invitation will arrive soon.</p>
              <a href="https://app.jojka.com">
                <button
                    className="pl-12 pr-12 justify-center rounded-md border border-transparent bg-tamahagane py-3 px-4 text-base font-normal text-white drop-shadow-md focus:outline-none focus:ring-2 focus:ring-tamahagane focus:ring-offset-2"
                >
                  Back to Jojka
                </button>
              </a>
            </div>
          </div>
        </>
    )
  }

  return (
    <div className='flex h-full w-full bg-whiteSmoke'>

      {/*<Notification />*/}
      <NewToast></NewToast>
      {/*       <Toast></Toast> */}
      <SideBar></SideBar>
      <div className='h-full w-full bg-whiteSmoke flex flex-col items-center min-w-[970px] mb-40'>
        <RestoreScrollWrapper>
          <Outlet /> {/* Main content of each file rendered here */}
        </RestoreScrollWrapper>
      </div>
    </div>
  );
}

export default App;

