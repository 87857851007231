import React from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';

const data = [
    { date: '2023-01-01', revenue: 200 },
    { date: '2023-01-10', revenue: 100 },
    { date: '2023-01-15', revenue: 300 },
    { date: '2023-01-25', revenue: 400 },
    // Add more data points as needed
];

const RevenueView = () => {
    return (
        <ResponsiveContainer
            width={"100%"}
            height={400}
        >
            <ComposedChart
                width={500}
                height={400}
                data={data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="category" dataKey="date" name='date' tickFormatter={(str) => {
                    const date = new Date(str);
                    return date.toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: "numeric"  });
                }} />
                <YAxis type="number" dataKey="revenue" name='Revenue' unit='Tkr' >
                    <Label value="Total Revenue" position="left" angle={-90}></Label>
                </YAxis>
                <Tooltip formatter={(value, name) => [value.toString() + " Tkr", name.toString().slice(0, 1).toUpperCase() + name.toString().slice(1,)]} />
                <Line dataKey="revenue" stroke="#2B303B" />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default RevenueView;