export type Day = {
    year: number,
    month: number,
    dayOfMonth: number
}

export const dateToDay = (dt: Date): Day => ({
    year: dt.getFullYear(),
    month: dt.getMonth(),
    dayOfMonth: dt.getDate()
})

export const dayToDate = (date: Day) => {
    return new Date(date.year, date.month, date.dayOfMonth)
}

export const daysAreEqual = (a: Day, b: Day) => {
    if (!a && !b) return true;
    if (!a || !b) return false;
    if (a.year !== b.year) return false;
    if (a.month !== b.month) return false;
    return a.dayOfMonth === b.dayOfMonth;
}

/**
 * Represents a page in a calendar. Contains the days of the
 * month, padded with days from the previous and next months
 * so that the days list always starts on a Monday and ends
 * on a Sunday.
 */
type CalendarPage = {
    id: string,
    name: string,
    year: number,
    month: number,
    days: Day[],
    daysInMonth: Day[],
}

/**
 * Get the calendar page for the given year and month.
 * @param year
 * @param month
 */
const getCalendarPage = (year: number, month: number): CalendarPage => {
    let firstInMonth = dayToDate({year, month, dayOfMonth: 1});
    let firstMonday = new Date(firstInMonth.valueOf());
    firstMonday.setDate(firstMonday.getDate() - (firstMonday.getDay() + 6) % 7);


    let days: Day[] = []
    let daysInMonth: Day[] = []
    let cur = firstMonday;
    while (cur < firstInMonth) {
        const day = dateToDay(cur);
        days.push(day);
        cur.setDate(cur.getDate() + 1); // next day
    }
    while (cur.getMonth() === month) {
        const day = dateToDay(cur);
        days.push(day)
        daysInMonth.push(day)
        cur.setDate(cur.getDate() + 1); // next day
    }
    while (cur.getDay() !== 1) {
        const day = dateToDay(cur);
        days.push(day);
        cur.setDate(cur.getDate() + 1); // next day
    }

    const id = `${year}-${month.toString().padStart(2, '0')}`;
    const name = firstInMonth.toLocaleString('en-US', {month: 'long'});
    return {id, name, year, month, days, daysInMonth}
}

/**
 * Get all calendar pages between the given start and end
 * dates. For example, if start="Jun 12" and end="Aug 3"
 * then the pages for June, July and August would be returned.
 * @param start
 * @param end
 */
export const getCalendarPages = (start: Day, end: Day) => {
    const endDate = dayToDate(end);
    let pages: CalendarPage[] = []
    let curDate = dayToDate(start);
    while (curDate < endDate) {
        pages.push(getCalendarPage(curDate.getFullYear(), curDate.getMonth()))
        curDate.setDate(1);
        curDate.setMonth(curDate.getMonth() + 1)
    }
    return pages
}