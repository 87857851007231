import { DateObject } from "components/RangeCalendar";
import { ReactNode, useContext, useEffect, useState } from "react";
import StatCard from "../StatCard";
import StatGraph, { GraphDataPoint } from "../StatGraph";
import StatsContext from "../../../store/stats-context";
import { countryCodes } from "routes/GroupRoute/CreateModal/helpers";
import topCountriesImg from "topCountries.png";
import { SimpleDropdown, SingleItem } from "components/SimpleDropdown";

type DeliveryViewProps = {
    statCards?: Array<string>,
}

type SelectedStat = {
    title: string,
    data: Array<GraphDataPoint>,
    graphType: "linechart",
    id: string,
    dataUnit: string,
    totalData: any,
    icon: ReactNode
};

const deliveryViewCards: Array<SelectedStat> = [
    {
        title: "Total Volume",
        data: [
            { date: '2023-01-01', data: 200 },
            { date: '2023-02-10', data: 100 },
            { date: '2023-02-15', data: 300 },
            { date: '2023-02-20', data: 450 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 700 },
        ],
        graphType: "linechart",
        dataUnit: "Sent SMS",
        id: "totalvolume",
        totalData: "56.256",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>
    },
    {
        title: "Delivered",
        data: [
            { date: '2023-01-01', data: 800 },
            { date: '2023-02-10', data: 200 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "Delivered SMS",
        id: "delivered",
        totalData: "56.156 (98.3%)",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
        </svg>

    },
    {
        title: "Unique Contacts reached",
        data: [
            { date: '2023-01-01', data: 1000 },
            { date: '2023-01-15', data: 2000 },
            { date: '2023-01-20', data: 3000 },
            { date: '2023-01-25', data: 4000 },
            { date: '2023-02-05', data: 5000 },
            { date: '2023-02-10', data: 6000 },
            { date: '2023-02-15', data: 7000 },
            { date: '2023-02-20', data: 8000 },
            { date: '2023-02-21', data: 9000 },
            { date: '2023-02-28', data: 1000 },
        ],
        graphType: "linechart",
        dataUnit: "Unique Contacts reached",
        id: "uniquereached",
        totalData: "43.123",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd" />
        </svg>

    },
    {
        title: "Smart Sending",
        data: [
            { date: '2023-01-01', data: 100 },
            { date: '2023-01-15', data: 200 },
            { date: '2023-01-20', data: 300 },
            { date: '2023-01-25', data: 400 },
            { date: '2023-02-05', data: 500 },
            { date: '2023-02-10', data: 300 },
            { date: '2023-02-15', data: 400 },
            { date: '2023-02-20', data: 150 },
            { date: '2023-02-21', data: 800 },
            { date: '2023-02-28', data: 500 },
        ],
        graphType: "linechart",
        dataUnit: "SEK saved",
        id: "smartsending",
        totalData: "3.164 SEK",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path
                d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
            <path fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                clipRule="evenodd" />
        </svg>

    },
    {
        title: "Unsubscribe rate",
        data: [
            { date: '2023-01-01', data: 50 },
            { date: '2023-01-15', data: 30 },
            { date: '2023-01-20', data: 10 },
            { date: '2023-02-05', data: 20 },
            { date: '2023-02-10', data: 10 },
            { date: '2023-02-15', data: 4 },
            { date: '2023-02-20', data: 2 },
            { date: '2023-02-21', data: 3 },
        ],
        graphType: "linechart",
        dataUnit: "Unsubscribes",
        id: "unsubscribed",
        totalData: "N/A",
        icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
            <path
                d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
        </svg>
        )
    }
]

type TopCountryItem = {
    name: string,
    total: number,
    success: number
}

const DeliveryView = (props: DeliveryViewProps) => {
    const { stats } = useContext(StatsContext);
    const [selectedStat, setSelectedStat] = useState<SelectedStat>();
    const [topCountries, setTopCountries] = useState<TopCountryItem[]>([]);

    useEffect(() => {
        setSelectedStat(deliveryViewCards[0]);
    }, [stats]);

    useEffect(() => {
        if (stats) {
            let countries = Object.keys(stats.summary.countries).map((key: string) => {
                return { name: key, total: stats.summary.countries[key].texts.total, success: stats.summary.countries[key].texts.delivered }
            });
            countries.sort((a: any, b: any) => b.total - a.total);

            const top10Items = countries.slice(0, 10);

            setTopCountries(top10Items)
        }
    }, [stats])

    return (
        <div className="flex flex-col">
            {selectedStat === deliveryViewCards[0] &&
                <TotalVolumeGraph />
            }
            {selectedStat && selectedStat !== deliveryViewCards[0] &&
                <div className="bg-white p-4 rounded-lg sectionShadow">
                    <p className="text-base font-semibold">{selectedStat.title}</p>
                    {<StatGraph type={selectedStat.graphType} data={selectedStat.data} yDataName={selectedStat.title}
                        yDataUnit={selectedStat.dataUnit} />}
                </div>
            }

            <div className="flex gap-8 mt-4">
                <div className="grid grid-cols-5 gap-8 w-full">
                    <TotalVolumeCard handleClick={() => setSelectedStat(deliveryViewCards[0])} />
                    <DeliveredCard handleClick={() => setSelectedStat(deliveryViewCards[0])} />
                    <CostsCard handleClick={() => setSelectedStat(deliveryViewCards[0])} />
                    <UniquesCard handleClick={() => setSelectedStat(deliveryViewCards[0])} />
                    {deliveryViewCards.slice(4).map((statCard: any, index) =>
                        <StatCard title={statCard.title} value={statCard.totalData}
                            icon={statCard.icon} handleClick={() => setSelectedStat(statCard)} />
                    )}
                </div>
            </div>
            <div className=" border-t-2  border-gray w-full mb-8 mt-14 rounded-full"></div>
            <div className="flex mt-4 bg-white rounded-lg justify-between p-8 sectionShadow">
                <div className="flex flex-col w-1/2 py-4 px-8">
                    <p className="text-lg font-semibold mb-6">Top 10 Countries by SMS traffic</p>
                    <div className="flex flex-col gap-4 w-3/4">
                        <div className="w-full grid grid-cols-2 border-b border-gray">
                            <p className="text-base font-semibold">Country</p>
                            <p className="text-base font-semibold">Volume</p>
                        </div>
                        {topCountries.length ? topCountries.map((country: TopCountryItem) =>
                            <div className="w-full grid grid-cols-2 border-b border-gray">
                                <p className="text-base">{countryCodes.find((item: any) => item.alpha2 === country.name)?.country ?? "-"}</p>
                                <p className="text-base"><span className="text-base ">{country.total} ({(country.total / stats.summary.all.texts.total * 100).toFixed(1)}%)</span></p>
                            </div>
                        )
                        :
                        <p className="text-base font-semibold text-decoGray">No data available yet.</p>
                    }
                    </div>
                </div>
                <div className="flex flex-col pr-36">
                    <div className="w-1/4 self-end mb-6 invisible"> {/** Visibility hidden */}
                        <SimpleDropdown textStyles="truncate overflow-hidden overflow-ellipsis max-w-full font-semibold text-tamahagane" placeholder={"Pick location"} preSelect={(item: any) => item.value === "europe"} data={[{ title: "Europe", id: 1, value: "europe" }]} selectFn={function (item: SingleItem | null): void {
                            return undefined
                        }} />
                    </div>
                    <img className="" src={topCountriesImg} alt="" />
                </div>
            </div>
        </div>
    )
}

type StatCardProps = {
    handleClick?: () => void
}


const TotalVolumeGraph = (props: StatCardProps) => {
    const { stats } = useContext(StatsContext);
    const [data, setData] = useState<Array<GraphDataPoint>>([]);

    useEffect(() => {
        const data = stats.splits.map(x => ({
            date: x.start.toLocaleDateString('en-CA'),
            data: x.stats.all.parts.total,
        }));
        setData(data)
    }, [stats]);

    return (
        <div className="bg-white p-4 rounded-lg sectionShadow">
            <p className="text-base font-semibold">Total Volume</p>
            <StatGraph type="linechart" data={data} yDataName="Total Volume"
                yDataUnit="Sent SMS" />
        </div>
    )
}

const TotalVolumeCard = (props: StatCardProps) => {
    const { stats } = useContext(StatsContext);
    const statCard = deliveryViewCards[0];

    return (
        <StatCard title={"Total Volume"} value={stats.summary.all.parts.total.toLocaleString()}
            icon={statCard.icon} handleClick={props.handleClick} />
    )
}

const CostsCard = (props: StatCardProps) => {
    const { stats } = useContext(StatsContext);
    const statCard = deliveryViewCards[0];
    const costs = (stats.summary.all.total_cost || 0) / 100 / 100;
    return (
        <StatCard title={"Costs"} value={`${costs.toLocaleString()} SEK`}
            icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
            </svg>
            } handleClick={props.handleClick} />
    )
}

const DeliveredCard = (props: StatCardProps) => {
    const { stats } = useContext(StatsContext);
    const statCard = deliveryViewCards[1];

    const [delivered, setDelivered] = useState<number>(0);
    const [ratio, setRatio] = useState<string>("0.00 %");
    useEffect(() => {
        const _delivered = stats.summary.all.parts.delivered;
        const _total = stats.summary.all.parts.total;
        const ratio = _total > 0 ? (_delivered / _total) : 0;
        const percentage = (ratio * 100).toFixed(2) + '%';
        setDelivered(_delivered);
        setRatio(percentage);
    }, [stats]);

    return (
        <StatCard title={"Delivered"} value={`${delivered.toLocaleString()} (${ratio})`}
            icon={statCard.icon} handleClick={props.handleClick} />
    )
}

const UniquesCard = (props: StatCardProps) => {
    const { stats } = useContext(StatsContext);
    const statCard = deliveryViewCards[4];
    const uniques = (stats.summary.unique_recipients);
    return (
        <StatCard title={"Total reach"} value={uniques.toString()}
            icon={statCard.icon} handleClick={props.handleClick} />
    )
}

export default DeliveryView