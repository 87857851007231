
import { classNames } from 'helpers';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import "./index.css";

const MenuItem = (props: any) => {
    const [hideText, setHideText] = useState(true);

    const { Icon, containerClasses, active, link, open, title } = props;
    if (link) {
        return(
        <Link to={link} preventScrollReset={false}>
            <div className={classNames(
                "flex items-center h-16 cursor-pointer w-full",
                active ? "bg-tamahagane text-white " : "hover:bg-birchwood/20 text-gunMetal",
                containerClasses
            )}>
                <div className='flex pl-6 pr-4 items-center cursor-pointer'>
                    <Icon className='w-6 h-6 block m-auto fill-currentColor' />
                </div>
                {open && <p className='text-base cursor-pointer capitalize title-text' >{title}</p>}
            </div>
        </Link>
        )
    } else {
        return(
        <div className={classNames(
                "flex items-center h-16 w-full cursor-pointer",
                !active && "hover:bg-tamahagane hover:text-white text-gunMetal ",
                active && "bg-tamahagane text-white ",
                containerClasses
        )}>
            <div className='pl-6 pr-4 flex items-center cursor-pointer'>
                <Icon className='w-6 h-6 block m-auto fill-currentColor' />
            </div>
                {open && <p className='text-base cursor-pointer capitalize title-text'>{title}</p> }
        </div> 
        )
    }
}

export default MenuItem