type OverviewRowProps = {
    campaignName: string,
    size: string,
    deliveryRate: string,
    clickRate: string
}

export default function OverviewRow(props: OverviewRowProps) {
    return (
        <div className="grid grid-cols-[1fr,auto,auto,auto] gap-x-2 py-2 border-b border-gray">
            <p className="text-base ">{props.campaignName}</p>
            <p className="w-[88px] text-base ">{props.size}</p>
            <p className="w-[88px] text-base ">{props.deliveryRate}</p>
            <p className="w-[88px] text-base ">{props.clickRate}</p>
        </div>
    )
}