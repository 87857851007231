import {createContext, useContext, useEffect, useState} from 'react';
import AccountContext from "./account-context";

export type FlowDraft = {
    'flow_id': string,
    'flow_message_id': string,
    'has_metric': boolean,
    'metric_id': string,
    'days_delay': number,
    'trigger_time': string,
    'name': string,
    'message': string,
    'sender': string,
    'cc': string,
}

export type Flow = FlowDraft & {
    '_id': string,
    'number': number,
    'metric_name': string,
    'connection_id': string,
    'created_at': string,
    'total_size': number,
    'total_cost': number,
    'trigger_count': number,
    'last_activation': string | undefined,
}

export type Metric = {
    id: string,
    name: string,
}

export type KlaviyoFlow = {
    id: string,
    name: string,
}

type KlaviyoAction = {
    id: string,
}

export type KlaviyoMessage = {
    id: string,
    name: string,
}

const FlowsContext = createContext({
    metrics: [] as Metric[],
    flows: [] as Flow[],
    klaviyoFlows: [] as KlaviyoFlow[],
    loading: true,
    hasKlaviyoConnection: false,
    deleteMany: (flows: Flow[]) : Promise<void> => Promise.resolve(),
    create: (flow: FlowDraft) : Promise<void> => Promise.resolve(),
    edit: (_id: string, flow: FlowDraft) : Promise<void> => Promise.resolve(),
    getMessages: (flow: KlaviyoFlow) : Promise<KlaviyoMessage[]> => Promise.resolve([]),
});

export function FlowsContextProvider(props: any) {
    const {_id: accountId, integrations} = useContext(AccountContext);
    const [loading, setLoading] = useState(true);
    const [hasKlaviyoConnection, setHasKlaviyoConnection] = useState(false);
    const [metrics, setMetrics] = useState<Metric[]>([]);
    const [klaviyoFlows, setKlaviyoFlows] = useState<KlaviyoFlow[]>([]);
    const [flows, setFlows] = useState<Flow[]>([]);

    const deleteOne = async (flow: Flow)=> {
        const key: string = integrations?.find((x: any) => x.integration_id === "klaviyo")?.key;
        if (!key) return;
        const url = `${process.env.REACT_APP_KLAVIYO_URL}/flows/${flow['_id']}`
        const headers = {'Authorization': `Bearer ${key}`};
        const response = await fetch(url, {method: 'delete', headers});
        await response.text();
    }
    
    const deleteMany = async (flows: Flow[])=> {
        const batchSize = 5;
        for (let i = 0; i < flows.length; i += batchSize) {
            const batch = flows.slice(i, i + batchSize);
            await Promise.all(batch.map(flow => deleteOne(flow)));
        }
        setFlows(prev => Array.from(prev).filter(f => !flows.includes(f)));
    }

    const create = async (flow: FlowDraft)=> {
        const key: string = integrations?.find((x: any) => x.integration_id === "klaviyo")?.key;
        if (!key) return;
        const url = `${process.env.REACT_APP_KLAVIYO_URL}/flows/v2`
        const headers = {
            'Authorization': `Bearer ${key}`,
            'Content-Type': 'application/json'
        };
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(flow)
        });
        const body = await response.text();
        if (!response.ok) {
            throw new Error(body);
        }
        setLoading(true);
    }

    const edit = async (_id: string, flow: FlowDraft)=> {
        const key: string = integrations?.find((x: any) => x.integration_id === "klaviyo")?.key;
        if (!key) return;
        const url = `${process.env.REACT_APP_KLAVIYO_URL}/flows/${_id}/v2`
        const headers = {
            'Authorization': `Bearer ${key}`,
            'Content-Type': 'application/json'
        };
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(flow)
        });
        const body = await response.text();
        if (!response.ok) {
            throw new Error(body);
        }
        setLoading(true);
    }

    const getActions = async (flow: KlaviyoFlow)=> {
        const key: string = integrations!.find((x: any) => x.integration_id === "klaviyo")!.key;
        const url = `${process.env.REACT_APP_KLAVIYO_URL}/flows/${flow.id}/flow_actions`
        const headers = {'Authorization': `Bearer ${key}`};
        const res = await fetch(url, {headers});
        const parsed = await res.json();
        if (parsed.error) {
            throw new Error(parsed.error);
        }
        return parsed.actions as KlaviyoAction[];
    }
    
    const getMessages = async (flow: KlaviyoFlow)=> {
        const key: string = integrations!.find((x: any) => x.integration_id === "klaviyo")!.key;
        const actions = await getActions(flow);
        let allMessages: KlaviyoMessage[] = [];
        for (const action of actions) {
            const url = `${process.env.REACT_APP_KLAVIYO_URL}/flow_actions/${action.id}/flow_messages`
            const headers = {'Authorization': `Bearer ${key}`};
            const res = await fetch(url, {headers});
            const parsed = await res.json();
            if (parsed.error) {
                throw new Error(parsed.error);
            }
            const messages = parsed.messages as KlaviyoMessage[];
            if (messages.length) {
                allMessages.push(...messages);
            }
        }
       return allMessages;
    }
    

    useEffect(() => {
        if (!accountId || !loading) return;
        
        const key: string = integrations?.find((x: any) => x.integration_id === "klaviyo")?.key;
        if (!key) {
            setLoading(false);
            return;
        }
        
        const fetchFlows = async () => {
            const url = `${process.env.REACT_APP_KLAVIYO_URL}/flows/v2`;
            const headers = {'Authorization': `Bearer ${key}`};
            const accountData = await fetch(url, {headers});
            let res = await accountData.json();
            if (res.error && res.error === 403) {
                return false;
            }
            return res;
        }

        (async () => {
            try {
                const res = await fetchFlows();
                setMetrics(res['metrics'] as Metric[]);
                setFlows(res['flows'] as Flow[]);
                setKlaviyoFlows(res['klaviyo_flows'] as KlaviyoFlow[]);
                setHasKlaviyoConnection(true);
                setLoading(false);
            } catch (e) {
                setHasKlaviyoConnection(false);
                setLoading(false);
            }
        })()
        
    }, [accountId, integrations, loading]);
    

    const context = {
        metrics,
        flows,
        klaviyoFlows,
        hasKlaviyoConnection,
        loading,
        deleteMany,
        create,
        edit,
        getMessages,
    };

    return (
        <FlowsContext.Provider value={context}>
            {props.children}
        </FlowsContext.Provider>
    )
}

export default FlowsContext;