
import { useState } from "react";
import { BsChevronDown, BsChevronRight, BsMapFill } from "react-icons/bs";
import NewDropdown from "components/NewDropdown";
import { Dropdown, DropdownBody, DropdownItem } from "dropdown"
import SearchableDropdownBody from "dropdown/SearchableDropdownBody";

type CountrycodeItem = {
    id: number,
    title: string,
    value: string
}

export interface CountrycodeDropdownProps {
    setSelection: Function,
    items: CountrycodeItem[],
}

const CountrycodeDropdown = (props: CountrycodeDropdownProps) => {
    const [selected, setSelected] = useState({} as CountrycodeItem)

    const selectClick = (newSelection: CountrycodeItem) => {
        if (newSelection.value === selected.value) {
            setSelected({} as CountrycodeItem);
        } else {
            setSelected(newSelection);
        }
    }

    return (
        <Dropdown
            placeholder={"Choose country"}
            bodyEaseIn={false}
            renderBody={(dropdownSettings: any) =>
                <SearchableDropdownBody
                    renderItem={(bodySettings: any) =>
                        <DropdownItem
                            key={bodySettings.id}
                            id={bodySettings.id}
                            title={bodySettings.title}
                            checkbox={true}
                            checkboxChecked={bodySettings.title === selected.title}
                            handleClick={() => {
                                selectClick(bodySettings)
                                props.setSelection(bodySettings.value)
                                dropdownSettings.handleClose()
                            }} />
                    }
                    data={props.items}
                    bodyDirection="right"
                    searchPlaceholder="Search"
                    maxHeight="max-h-48"
                />
            }
            selected={selected.title}
            headerButtonStyles={"text-between border-granitine bg-birchwood rounded-md"}
            textStyles={"font-semibold p-2 truncate text-ellipsis text-left text-white"}
        />
    )


}
export default CountrycodeDropdown