import { SimpleDropdown, SingleItem } from 'components/SimpleDropdown';
import {useCampaignTime} from "../../store/campaign-time-context";


type TimeSelectorProps = {
    disabled?: boolean
}

const TimeSelector = (props: TimeSelectorProps) => {
    const {validHours, validMinutes, hour: selectedHour, minutes: selectedMinutes, setHour, setMinutes} = useCampaignTime();

    const hoursItems: Array<SingleItem> = validHours.map((hour, idx) => ({
        title: hour.toString().padStart(2, '0'),
        value: hour,
        id: idx,
    }));

    const minutesItems: Array<SingleItem> = validMinutes.map((minutes, idx) => ({
        title: minutes.toString().padStart(2, '0'),
        value: minutes,
        id: idx,
    }));
    
    return (
        <div className='flex items-center h-full'>
            <SimpleDropdown
                disabled={props.disabled}
                bodyWidth="w-28"
                bodyHeight="h-60"
                placeholder="Hour"
                noDeselect={true}
                textStyles="truncate overflow-hidden overflow-ellipsis max-w-full text-tamahagane mr-2 "
                data={hoursItems}
                reset={hoursItems}
                preSelect={(item: SingleItem) => item.value === selectedHour}
                selectFn={(item: SingleItem | null) => { item && setHour(item.value) }}
            />
            <p className='text-lg mx-2'>:</p>
            <SimpleDropdown
                disabled={props.disabled}
                bodyWidth="w-28"
                bodyHeight="h-60"
                placeholder="Minute"
                noDeselect={true}
                textStyles="truncate overflow-hidden overflow-ellipsis max-w-full text-tamahagane mr-2 "
                data={minutesItems}
                reset={minutesItems}
                preSelect={(item: SingleItem) => item.value === selectedMinutes}
                selectFn={(item: SingleItem | null) => {item && setMinutes(item.value)}}
            />
        </div>
    );
}

export default TimeSelector;