import HeaderBar from "components/HeaderBar";
import React, {useContext, useEffect, useState} from "react";
import FlowsContext, {Flow} from "../../store/flows-context";
import {DotsLoader} from "../../components/DotsLoader";
import {Link} from "react-router-dom";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Button from "../../components/Button";
import RemovedAlert from "../GroupRoute/RemovedAlert";

TimeAgo.addDefaultLocale(en)

const FlowsRoute = () => {
    const {flows, metrics, loading, deleteMany, hasKlaviyoConnection} = useContext(FlowsContext);
    const [selected, setSelected] = useState<Set<Flow>>(new Set());
    const [pendingDeletion, setPendingDeletion] = useState<Set<Flow>>(new Set());
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const visible = flows.filter(f => !pendingDeletion.has(f));

    const toggleSelected = (flow: Flow) => {
        setSelected(old => {
            let updated = new Set(old);
            if (updated.has(flow)) {
                updated.delete(flow)
            } else {
                updated.add(flow)
            }
            return updated;
        });
    }

    const toggleSelectAll = () => {
        setSelected(prev => {
            const items = prev.size < flows.length ? flows : [];
            return new Set(items);
        });
    }

    const markSelectedForDeletion = () => {
        setPendingDeletion(selected);
        setSelected(new Set());
    }

    useEffect(() => {
        if (pendingDeletion.size) {
            const toDelete = Array.from(pendingDeletion);
            const id = setTimeout(() => {
                (async () => {
                    await deleteMany(toDelete);
                    setPendingDeletion(new Set());
                    setTimeoutId(null);
                })();
            }, 7000);
            setTimeoutId(id);
            return () => clearTimeout(id);
        }
    }, [pendingDeletion]);

    const onClickCancel = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        setPendingDeletion(new Set());
    }

    return (
        <>
            <HeaderBar title="Flows"/>

            <div className="h-full w-full min-h-screen pageBeginMargin">
                <div className="w-10/12 mx-auto">
                    
                {loading &&
                    <div className="flex justify-center items-center h-[4rem]">
                        <DotsLoader/>
                    </div>
                }
    
                {!loading && visible.length === 0 &&
                    <div className="flex justify-center align-middle">
                        <div className="bg-white max-w-[64rem] p-40 text-center flex flex-col items-center space-y-8">
                            {hasKlaviyoConnection && <>
                            <div className="border-[1px] border-gunMetal25 p-6 size-24 rounded-md">
                                <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    {/* Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}
                                    <path d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
                                </svg>
                            </div>
                            
                            <p className="text-sm font-semibold max-w-128">Adding SMS to your Klaviyo flows is an efficient way to generate more revenue.</p>
                            <Link to="new" relative="path">
                                <Button text="Add SMS to a flow" handleClick={() => {}} bgColor="bg-birchwood" textColor="white" style="px-16 h-12 w-48"/>
                            </Link>
                            </>}
                            {!hasKlaviyoConnection && <>
                                <div className="border-[1px] border-gunMetal25 p-6 size-24 rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        {/*<!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->*/}
                                        <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                                    </svg>
                                </div>

                                <p className="text-sm font-semibold max-w-128">Access to flows requires an activated Klaviyo integration</p>
                                <p className="max-w-128">If you use Klaviyo and want to unlock this section, connect your account in the "Integrations" page.</p>
                                <Link to="/integrations">
                                    <Button text="Show integrations" bgColor="bg-birchwood" textColor="white" style="px-16 h-12 w-48"/>
                                </Link>
                            </>}
                        </div>
                    </div>
                }

                {!loading && visible.length > 0 && <>
                        <div className="min-w-[72rem] px-2">

                            <div className="flex mb-4">
                                <div className="flex gap-8">
                                    <Link to="new" relative="path">
                                        <Button text="Create" handleClick={() => {
                                        }} bgColor="bg-birchwood" textColor="white" style="px-16 h-12 w-48"/>
                                    </Link>
                                </div>
                                <div
                                    className={`${selected.size ? "opacityVisible" : "opacityInvisible"} flex ml-auto gap-8`}>
                                    <Button text="Delete" handleClick={markSelectedForDeletion} bgColor="bg-briquette"
                                            textColor="white" style="px-16 h-12 w-48"/>
                                </div>
                            </div>

                            <table className="min-w-full table-auto overflow-auto ">
                                <thead className="border-b border-gray px-2">
                                <tr>
                                    <th className="w-4 py-4 pl-2">
                                        <div className="flex justify-start w-12">
                                            <input
                                                type="checkbox"
                                                checked={selected.size === flows.length}
                                                disabled={visible.length === 0 || pendingDeletion.size > 0}
                                                onChange={toggleSelectAll}
                                                className="rounded border-gray text-tamahagane focus:ring-tamahagane w-6 h-6 text-left cursor-pointer"
                                            />
                                        </div>
                                    </th>

                                    <th><p className="text-base text-left font-semibold">Created</p></th>
                                    <th><p className="text-base text-left font-semibold">Name</p></th>
                                    <th><p className="text-base text-left font-semibold">SMS sent</p></th>
                                    <th><p className="text-base text-left font-semibold">Total cost</p></th>
                                    <th><p className="text-base text-left font-semibold">Last activation</p></th>
                                </tr>
                                </thead>
                                <tbody>
                                {visible.map(flow => {
                                    const lastActivationDate = flow.last_activation ? new Date(flow.last_activation) : null;
                                    const timeAgo = new TimeAgo('en-US')


                                    return (
                                        <tr
                                            className="border-b border-gray py-4 hover:bg-birchwood/20 transition-colors"
                                            key={flow._id}
                                        >
                                            <td className="w-12 py-4 pl-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selected.has(flow)}
                                                    onChange={() => toggleSelected(flow)}
                                                    className="rounded border-gray text-tamahagane focus:ring-tamahagane w-6 h-6 cursor-pointer"
                                                />
                                            </td>
                                            <td>
                                                <p className="text-base w-[8rem] truncate capitalize">
                                                    {flow.created_at.split(' ')[0]}
                                                </p>
                                            </td>
                                            <td>
                                                <Link to={`${flow._id}/edit`} relative="path">
                                                    <p className="text-base underline w-[16rem]">
                                                        {flow.name}
                                                    </p>
                                                </Link>
                                            </td>
                                            <td>
                                                <p className="text-base w-[8rem] truncate ">
                                                    {flow.total_size}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text-base w-[8rem] truncate ">
                                                    {`${flow.total_cost / 100 / 100} SEK`}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text-base w-[12rem] truncate ">
                                                    {lastActivationDate ? timeAgo.format(lastActivationDate) : 'Never'}
                                                </p>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="fixed top-24 right-20 w-1/5">
                            {pendingDeletion.size > 0 &&
                                <RemovedAlert key="delete-flows" undoFn={onClickCancel}
                                              removeText={`Removed ${pendingDeletion.size} flow${pendingDeletion.size > 1 ? 's' : ''}`}/>
                            }
                        </div>
                </>}
                </div>
            </div>
        </>
    )
}

export default FlowsRoute
