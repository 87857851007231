import { IntegrationMetas } from 'data/IntegrationMetas';
import { getCookie, header } from 'helpers';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import AccountContext from './account-context';
import {parseErrorBarsOfAxis} from "recharts/types/util/ChartUtils";

type GAContextData = {
    connection: ConnectionsResponse | null,
    active: boolean | null,
    fetchMeta: (integrations: Array<any>) => void
};

// Step 1: Create the Context
export const GAContext = createContext<GAContextData | null>(null);

interface DataProviderProps {
    children: ReactNode;
}

type ConnectionsResponse = {
    email?: string,
    id?: string,
    integration_key?: string,
    ga_account_id: string,
    ga_property_id: string,
    name?: string,
}

// Step 2: Create the Context Provider
export const GAProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [meta, setMeta] = useState<IntegrationMeta | null>(null);
    const [active, setActive] = useState<boolean | null>(null);
    const [connection, setConnection] = useState<ConnectionsResponse | null>(null);

    const {integrations} = useContext(AccountContext);
    const GAIntegration = integrations?.find((integration: any) => integration.integration_id === IntegrationMetas.GoogleAnalytics.id)
    

    const fetchMeta = async () => {
        try {
            const metaResponse = await fetch(process.env.REACT_APP_GA_URL + '/meta', header(GAIntegration.key));
            if (!metaResponse.ok) {
                throw new Error('Network response was not ok');
            }
            const meta = await metaResponse.json();
            console.log("Meta: ", meta)
            console.log("Key: ", GAIntegration)
            setMeta(meta);

            if (!(meta.connections && meta.connections.length > 0)) {
                setActive(false)
                return
            }
            const connectionsResponse = await fetch(process.env.REACT_APP_GA_URL + `/connections/${meta.connections[0]}`, header(GAIntegration.key));
            if (!connectionsResponse.ok) {
                throw new Error('Network response was not ok');
            }
            const connections = await connectionsResponse.json();
            setConnection(connections.connection);
            setActive(true);
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    return (
        <GAContext.Provider value={{ connection, active, fetchMeta }}>
            {children}
        </GAContext.Provider>
    );
};