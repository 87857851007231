import { useState } from 'react';
import Button from 'components/Button';
import { ActivationStatus } from '.';
import errorIcon from 'assets/error_icon.png';
import { IntegrationMetas } from 'data/IntegrationMetas';

export const KlaviyoActivationForm = (props: any) => {

    const { onStatusChanged, integrationKey } = props
    const [key, setKey] = useState("");
    const [keyName, setKeyName] = useState("");
    const [keyError, setKeyError] = useState("");
    const [keyNameError, setKeyNameError] = useState("");
    const [apiError, setApiError] = useState("");

    const handleKeyInput = (value: string) => {
        setKey(value)
        if (value.length > 0 && keyError.length > 0) {
            setKeyError("")
        }
    }

    const handleKeyNameInput = (value: string) => {
        setKeyName(value)
        if (value.length > 0 && keyNameError.length > 0) {
            setKeyNameError("")
        }
    }

    const activateIntegration = () => {

        setApiError("")

        if (key.length > 0 && keyName.length > 0) {

            const body = {
                "klaviyo_key": key,
                "connection_name": keyName
            }
          
            const authUrl = IntegrationMetas.Klaviyo.authUrl
            const headers = new Headers({
                'Authorization': `Bearer ${integrationKey}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Host': `${IntegrationMetas.Klaviyo.host}`
            });

            fetch(authUrl, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: headers
            })
                .then(response => {
                    if (response.ok) {
                        onStatusChanged(ActivationStatus.SUCCESS);
                    } else {
                        response.text().then(error => setApiError(error))
                    }
                })
                .catch(error => {
                    onStatusChanged(ActivationStatus.ERROR);
                });
        } else {
            if (key.length === 0) {
                setKeyError("Mandatory field")
            }

            if (keyName.length === 0) {
                setKeyNameError("Mandatory field")
            }
        }
    };

    const openUrl = () => {
        window.open("https://www.klaviyo.com/login?next=/account#api-keys-tab", "_blank");
    }

    const getInputClassName = (error: string) => {
        if (error.length > 0) {
            return "border border-red-500 focus:ring-0 apperance-none outline-0 text-base w-full rounded-default h-12 mt-2 "
        } else {
            return "border border-granitine focus:ring-0 apperance-none outline-0 text-base w-full rounded-default h-12 mt-2 "
        }
    }

    return (
        <div>
            <p className='text-base text-tamahagane'>To import contacts and create utomations, we have to access your Klaviyo account. To do this, we need a private API key that you creat on Klaviyo.</p>
            <br />
            {apiError.length > 0 && (
                <div className="flex">
                    <img src={errorIcon} alt="Error Icon" className="mr-2 h-8" />
                    <p className="text-base text-tamahagane">{apiError}</p>
                    <br/>
                    <br/>
                </div>
            )}
            <p className='font-bold text-base text-tamahagane'>Please enter a Private API key from Klaviyo: </p>
            <input type="text" onChange={(e: any) => { handleKeyInput(e.target.value); }}
                className={getInputClassName(keyError)} />
            {keyError.length > 0 && <p className='text-red-500 text-sm mt-1'>{keyError}</p>}
            {keyError.length === 0 && <br />}
            {keyError.length === 0 && <br />}
            <p className='font-bold text-base text-tamahagane'>Give the key a name:</p>
            <input type="text" onChange={(e: any) => { handleKeyNameInput(e.target.value); }}
                className={getInputClassName(keyNameError)} />
            {keyNameError.length > 0 && <p className='text-red-500 text-sm mt-1'>{keyNameError}</p>}
            {keyNameError.length === 0 && <br />}
            {keyNameError.length === 0 && <br />}
            <p className='text-base text-tamahagane'>You can find your API keys and create new ones in the account page on the Klaviyo dashboard. Click "Private API" button below to get there directly (you might have to sign in).</p>
            <br />
            <br />
            <div className='flex flex justify-end'>
                <Button text="Private API" bgColor="bg-tamahagane" textColor="white" style="px-8 h-12" handleClick={() => { openUrl() }} />
                <Button text="Submit" bgColor="bg-decoGray" textColor="white" style="px-8 h-12 ml-4" handleClick={() => { activateIntegration(); }} />
            </div>
        </div>);

};
