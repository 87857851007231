import HeaderBar from "components/HeaderBar";
import { useContext, useEffect, useState } from "react";
import { CampaignDispatchesList } from "./CampaignDispatchesList";
import Button from "components/Button";
import { Tooltip } from 'react-tooltip'
import DateFilter from "components/DateFilter";
import { DateObject } from "components/RangeCalendar";
import { UpdateRangeFn } from "components/RangeCalendar/helpers";
import { DotsLoader } from "components/DotsLoader";
import DispatchContext from "store/dispatch-context";
import {getLastNDays} from "../../components/DateFilter/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetParamQuery, useQueryParams } from "helpers";

const CampaignsDispatchesRoute = () => {

    const { dispatches: allCampaigns, loading: refreshing } = useContext(DispatchContext);

    const [comparedCampaigns, setComparedCampaigns] = useState<Dispatch[]>([]);
    const [filteredCampaigns, setFilteredCampaigns] = useState<Dispatch[]>([]);
    const [visibleCampaigns, setVisibleCampaigns] = useState<Dispatch[]>([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState<Dispatch[]>([]);

    const [searchText, setSearchText] = useState<String>("");
    const [startDate, setStartDate] = useState<DateObject | null>();
    const [endDate, setEndDate] = useState<DateObject | null>();
    const [showAll, setShowAll] = useState(false);

    let paramQuery = useGetParamQuery()
    const { appendQueryParams, unsetQueryParams } = useQueryParams();


    useEffect(() => {
        let filtered = comparedCampaigns.length > 0 ? comparedCampaigns : allCampaigns;
        // Sort the campaigns
        filtered = filtered.sort((a: Dispatch, b: Dispatch) => b.number - a.number);

        // Filter by search text
        let searchParams = paramQuery.get("search") || ""
        
        if (searchParams.trim().length > 0) {
            filtered = filtered.filter((v) => {
                return v.name.toLowerCase().includes(searchParams.toLowerCase())
            });
        }

        // Filter by date
        if (startDate && endDate) {
            filtered = filtered.filter((v: Dispatch) => {
                // We want to use the creation date instead of the scheduled date, which we can get through _id.
                const timestamp = v._id.toString().substring(0, 8);
                const campaignDate = new Date(parseInt(timestamp, 16) * 1000);
                const filterStartDate = new Date(startDate?.year, startDate?.month - 1, startDate.day);
                const filterEndDate = new Date(endDate?.year, endDate?.month - 1, endDate.day);
                return campaignDate >= filterStartDate && campaignDate <= filterEndDate;
            })
        }
        if (searchParams !== searchText) {
            setSearchText(searchParams)
        }
        setFilteredCampaigns(filtered)
        setVisibleCampaigns((!showAll && filtered.length > 15) ? filtered.slice(0, 15) : filtered)
    }, [searchText, startDate, endDate, allCampaigns, comparedCampaigns, showAll])


    const handleRangeSelection: UpdateRangeFn = (dates) => {
        setStartDate(dates.startDate);
        setEndDate(dates.endDate);
    }

    useEffect(() => {
        handleRangeSelection(getLastNDays(180));
    }, [])

    const onSelectClicked = (campaign: Dispatch) => {
        const prev = selectedCampaigns;
        const updated = prev.includes(campaign) ? prev.filter(v => (v !== campaign)) : prev.concat(campaign);
        setSelectedCampaigns(updated)
        if (updated.length === 0) {
            setComparedCampaigns([])
        }
    }

    const onSelectAllClicked = () => {
        if (selectedCampaigns.length === filteredCampaigns.length) {
            setSelectedCampaigns([]);
            setComparedCampaigns([]);
        } else {
            setSelectedCampaigns(filteredCampaigns);
        }
    }

    const handleSearchInput = (e: any) => {
        if (!e.target.value.length) {
            unsetQueryParams("search");
        } else {
            appendQueryParams("search", e.target.value);
        }
        setSearchText(e.target.value)
    }
    return (
        <>
            <HeaderBar title="Campaigns" />

            <div className="h-full w-full min-h-screen pageBeginMargin">
                <div className="w-10/12 mx-auto">
                    <div className="min-w-[72rem] px-2">
                        <div className="flex pb-8">
                            <div
                                className="bg-white flex items-center w-[12rem] border border-granitine rounded-default pr-3 h-12">
                                <input type="text" placeholder="Search"
                                    onChange={handleSearchInput}
                                    defaultValue={paramQuery.get("search") || ""}
                                    className="border-0 focus:ring-0 apperance-none outline-0 text-base py-3 px-3 w-full rounded-default placeholder:text-slate-400 h-full" />
                            </div>
                            <div className="ml-8"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Select campaigns to compare."
                                data-tooltip-place="bottom">
                                <Button text="Compare"
                                    disabled={selectedCampaigns.length === 0}
                                    style="px-8 border border-granitine bg-white text-base font-semibold hover:bg-white"
                                    handleClick={() => setComparedCampaigns(selectedCampaigns)} />
                            </div>
                            <Tooltip id="my-tooltip" />

                            <div className="ml-auto">
                                <DateFilter updateRangeFn={handleRangeSelection} rangeStart={startDate}
                                    rangeEnd={endDate} preSelectFilter={"all"} />
                            </div>

                        </div>

                        <CampaignDispatchesList
                            campaigns={visibleCampaigns}
                            selectedCampaigns={selectedCampaigns}
                            onSelectClicked={onSelectClicked}
                            onSelectAllClicked={onSelectAllClicked} />
                        {allCampaigns.length > 0 && refreshing &&
                            <div className="flex justify-center items-center h-[4rem]">
                                <DotsLoader />
                            </div>}
                        {!refreshing && <div className="flex justify-center items-center h-[4rem]" />}
                        {(!(searchText.length > 0) && !endDate) && allCampaigns.length === 0 && !refreshing ?
                            <p className="text-base font-semibold text-decoGray text-center">No campaigns yet</p>
                            : undefined
                        }
                        {(searchText.length > 0 || endDate ) && filteredCampaigns.length === 0 ?
                            <p className="text-base font-semibold text-decoGray text-center">No campaigns available for this selection</p>
                            : undefined
                        }
                        {allCampaigns.length > 0 &&
                            <div className="flex justify-center w-full items-center">
                                {showAll && filteredCampaigns.length > 15 &&
                                    <Button text="Show less"
                                        handleClick={() => setShowAll(false)}
                                        bgColor="bg-birchwood"
                                        textColor="white"
                                        style="px-16 h-12" />
                                }
                                {!showAll && visibleCampaigns.length < filteredCampaigns.length &&
                                    <Button text={`Show all (${filteredCampaigns.length})`}
                                        handleClick={() => setShowAll(true)}
                                        bgColor="bg-birchwood"
                                        textColor="white"
                                        style="px-16 h-12" />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignsDispatchesRoute
