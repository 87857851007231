import React, {useContext, useEffect} from "react";
import CalendarItemsContext from "../../store/calendar-items-context";

const CalendarDropdown = () => {
    const {calendarItems, markAllAsSeen} = useContext(CalendarItemsContext);
    const calendarIsEmpty = !calendarItems.length;

    useEffect(() => {
        return () => {
            markAllAsSeen();
        }
    }, [markAllAsSeen]);
    
    return (
        <div className="bg-white p-4 border border-granitine rounded-default relative">
            <div className="absolute -translate-x-1/2 left-1/2 top-[-7px] w-0 h-0 border-b-granitine border-l-[7px] border-l-transparent border-r-[7px] border-r-transparent border-b-[7px]"></div>
            <div className="absolute -translate-x-1/2 left-1/2 top-[-5px] w-0 h-0 border-b-white border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent border-b-[5px]"></div>
            <p className="text-base font-semibold">Upcoming dates</p>
            {calendarItems.map(({title, dateRange, description, isNew}, idx) => {
                    const isLast = (idx === calendarItems.length-1);
                    return (
                        <div key={idx} className={`pb-2 pt-2 border-b-granitine border-0 ${isLast? '' : ' mb-2 border-b-[1px]'}`}>
                            <div>
                                <div className="flex justify-between">
                                    <p className="font-semibold">{title}</p>
                                    {isNew &&
                                        <div className="flex items-center justify-center">
                                            <div className="size-3 rounded-full bg-cruelRuby"></div>
                                        </div>
                                    }
                                </div>
                                <p className="text-xs">{dateRange}</p>
                            </div>
                            <p className="mt-1">{description}</p>
                        </div>
                    )
                }
            )}
            {calendarIsEmpty &&
                <p>We'll let you know of important upcoming <span className="break-keep">e-commerce</span> dates here.</p>
            }
        </div>
    );
}

export default CalendarDropdown;