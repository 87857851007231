import { header } from "helpers";
import React, {
    createContext,
    ReactNode,
    useReducer,
    useMemo,
    useContext
} from "react";

type Group = {
    [key: string]: SourceTypes
}

type State = {
    groups: Group[] | null,
    loading: boolean,
    error: any
}

type API = {
    fetchGroups: (token: string) => void
    addGroups: (groups: Group[]) => void,
    removeIsNew: (groupId: number) => void,
    removeGroup: (groupId: number) => void,
    insertGroup: (groupData: Group) => void,
}

const initialState: State = {
    groups: null,
    loading: true,
    error: null
}

const GroupDataContext = createContext<State>(initialState as State)
const GroupAPIContext = createContext<API>({} as API)

type Action =
    | { type: "FETCHING_GROUPS"; }
    | { type: "ADD_GROUPS"; payload: Group[] }
    | { type: "FETCH_GROUPS_SUCCESS"; payload: any }
    | { type: "FETCH_GROUPS_FAILURE"; payload: any }
    | { type: "REMOVE_ISNEW"; payload: any }
    | { type: "REMOVE_GROUP"; payload: any }
    | { type: "INSERT_GROUP"; payload: any }

type Dispatch = (action: Action) => void;

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "FETCHING_GROUPS":
            return {
                ...state,
                loading: true
            }
        case "FETCH_GROUPS_SUCCESS":
            return {
                ...state,
                loading: false,
                groups: action.payload
            }
        case 'FETCH_GROUPS_FAILURE':
            return { ...state, loading: false, error: action.payload };
        case "ADD_GROUPS":
            let newGroups = state.groups ? [...state.groups, ...action.payload] : [...action.payload]
            return {
                ...state,
                loading: false,
                groups: newGroups
            }
        case "REMOVE_ISNEW":
            let newGroup: any = state.groups?.map((group: Group) => {
                if (group.id === action.payload) {
                    return { ...group, isNew: false }
                }
                return group
            })

            return {
                ...state,
                loading: false,
                groups: newGroup
            }
        case "REMOVE_GROUP":
            let filteredGroup: any = state.groups?.filter((group: Group) => group.id !== action.payload)
            return {
                ...state,
                groups: filteredGroup
            }
        case "INSERT_GROUP":
            let insertGroups = [...(state.groups ?? []), action.payload]
            return {
                ...state,
                groups: insertGroups
            }
    }
}

const fetchData = async (dispatch: Dispatch, token: string) => {
    dispatch({ type: 'FETCHING_GROUPS' });

    let segintData: any = {};

    let { groups: groupsData }: any = await fetch(process.env.REACT_APP_API_BASE + "/groups", header(token)).then((r: any) => r.json());

    groupsData.forEach((element: any, i: number) => {
        if (!element.source) {
            element.source = 'bogio';
            element.source_type = 'list';
        }
        if (element.id in segintData) {
            groupsData[i] = { ...element, ...segintData[element.id] }
        }
    });

    dispatch({ type: 'FETCH_GROUPS_SUCCESS', payload: groupsData });
};

export const GroupDataProvider = ({ children }: { children: ReactNode }) => {

    const [state, dispatch] = useReducer(reducer, {} as State)

    const api = useMemo(() => {
        const fetchGroups = (token: string) => {
            fetchData(dispatch, token)
        }
        const addGroups = (groups: Group[]) => {
            dispatch({ type: 'ADD_GROUPS', payload: groups });
        }
        const removeIsNew = (groupId: number) => {
            dispatch({ type: 'REMOVE_ISNEW', payload: groupId });
        }
        const removeGroup = (groupId: number) => {
            dispatch({ type: 'REMOVE_GROUP', payload: groupId });
        }
        const insertGroup = (groupData: Group) => {
            dispatch({ type: 'INSERT_GROUP', payload: groupData });
        }
        return { fetchGroups, addGroups, removeIsNew, removeGroup, insertGroup}
    }, [])

    return (
        <GroupAPIContext.Provider value={api}>
            <GroupDataContext.Provider value={state}>
                {children}
            </GroupDataContext.Provider>
        </GroupAPIContext.Provider>
    )
}

export const useGroupData = () => useContext(GroupDataContext)
export const useGroupAPI = () => useContext(GroupAPIContext)