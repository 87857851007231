const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

const pollurls = [
  process.env.REACT_APP_MAILCHIMP_URL + "/ongoingimports",
  process.env.REACT_APP_KLAVIYO_URL + "/imports"
]

const formatLastCampaign = (time: number) => {
  let unix_timestamp = time;
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  let date = new Date(unix_timestamp);
  // Hours part from the timestamp
  let hours = date.getHours();
  // Minutes part from the timestamp
  let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  // Seconds part from the timestamp
  let day: any = date.getDate();
  day = day < 10 ? "0" + day : day;

  let month: any = months[date.getMonth()];
  month = month < 10 ? "0" + month : month;

  // Will display time in 10:30:23 format
  return `${day} ${month} ${date.getFullYear()}`

}

function printUnixTime(unixTime: number) {
  const date = new Date(unixTime * 1000); // Convert to milliseconds by multiplying by 1000

  const day = date.getDate();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Leading zero for minutes and hours if needed
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

const demoRows = [
  {
    id: 208,
    last_campaign: "2023-01-05",
    name: "Shopify segment \"Customers who haven't purchased\"",
    size: 56784,
    source_type: "segment",
    source: "shopify",
    mixed: ["mailchimp", "shopify"],
    sync: {
      last_sync: 1672912986
    }
  },
  {
    id: 2208,
    last_campaign: "2023-01-01",
    name: "Klaviyo list \"Customers who haven't purchased\"",
    size: 148,
    source_type: "list",
    source: "klaviyo",
    mixed: ["Klaviyo", "shopify"],
    sync: {
      last_sync: 1672653786
    }
  },
  {
    id: 2201,
    last_campaign: "2023-01-05",
    name: "Klaviyo segment \"Customers who haven't purchased\"",
    size: 1702,
    source_type: "segment",
    source: "klaviyo",
    sync: {
      last_sync: 1672740186
    }
  },
  {
    id: 2109,
    last_campaign: "2023-01-02",
    name: "Mailchimp segment \"Customers who haven't purchased\"",
    size: 854,
    source_type: "segment",
    source: "mailchimp",
    mixed: ["Mailchimp"],
    sync: {
      last_sync: 1672567386
    }
  },
  {
    id: 2089,
    last_campaign: "2022-12-25",
    name: "Click no checkout",
    size: 78859,
    source_type: "segment",
    source: "jojka",
    mixed: ["mailchimp", "klaviyo", "shopify"],
    sync: {
      last_sync: 1672912986
    }
  },
  {
    id: 3080,
    last_campaign: "2023-01-05",
    name: "Shopify segment \"Customers who haven't purchased\"",
    size: 170,
    source_type: "segment",
    source: "shopify",
    mixed: ["shopify", "klaviyo"],
    sync: {
      last_sync: 1672912986
    }
  },
  {
    id: 2222,
    last_campaign: "2023-01-05",
    name: "Shopify segment \"Customers who haven't purchased\"",
    size: 67,
    source_type: "segment",
    source: "shopify",
    mixed: ["shopify", "klaviyo"],
    sync: {
      last_sync: 1672831404
    }
  },
  {
    id: 2232,
    last_campaign: "2023-01-06",
    name: "No click users",
    size: 135068,
    source_type: "segment",
    source: "jojka",
    mixed: ["mailchimp", "emarsys"],
    sync: {
      last_sync: 1672831404
    }
  },
  {
    id: 2282,
    last_campaign: "2023-01-06",
    name: "Shopify segment",
    size: 33,
    source_type: "segment",
    source: "jojka",
    mixed: ["shopify", "klaviyo"],
    sync: {
      last_sync: 1672831404
    }
  },
  {
    id: 2132,
    last_campaign: "2023-01-06",
    name: "No click users",
    size: 14384,
    source_type: "segment",
    source: "jojka",
    mixed: ["shopify", "klaviyo"],
    sync: {
      last_sync: 1672831404
    }
  },
]

const groupTypes = [
  { id: 1, title: "Segment", value: "segment" },
  { id: 2, title: "List", value: "list" },
]

export {
  printUnixTime,
  formatLastCampaign,
  pollurls,
  demoRows,
  groupTypes,
}
