// This is used for beta release

import { ReactNode, useState } from "react";
import { BsChevronLeft, BsFillInfoCircleFill, BsXLg } from "react-icons/bs";
import { MdHelpOutline } from "react-icons/md";
import { useContext, useEffect } from "react";

import CampaignContext from "store/campaign-context";

import Dropdown_stacked from "./Dropdown_stacked";
import Dropdown from "components/Dropdown";
import { Dropdown as NewDropdown } from "dropdown";
import { classNames, getCookie } from "helpers";
import BatchDropdown from "../section_4/BatchDropdown";
import GADropdown from "./GADropdown";
import { GAContext } from "store/ga-context";
import { Tooltip } from "react-tooltip";

interface GroupsData {
    [key: string]: SourceTypes
}
const FlexSendToBarBeta = () => {
    const campaignCtx = useContext(CampaignContext);
    const GACtx = useContext(GAContext);

    const [groups, setGroups] = useState([] as any);
    const [groupsData, setGroupsData] = useState({} as GroupsData);
    const [isBatchChecked, setIsBatchChecked] = useState(false);
    const [isGAChecked, setIsGAChecked] = useState(false);


    const handleBatchCheckboxChange = () => {
        if (Object.keys(campaignCtx.groupsIds).length === 1) {
            setIsBatchChecked(!isBatchChecked);
            campaignCtx.updateUseBatching(!isBatchChecked);
        }
    };

    const handleGACheckboxChange = () => {
        setIsGAChecked(oldVal => !oldVal);
    };

    useEffect(() => {
        if ((Object.keys(campaignCtx.groupsIds).length !== 1) && isBatchChecked) {
            setIsBatchChecked(false);
            campaignCtx.updateUseBatching(false);
        }
    }, [campaignCtx.groupsIds])

    useEffect(() => {
        setIsBatchChecked(false);
        campaignCtx.updateUseBatching(false);
    }, [campaignCtx.reset])

    useEffect(() => {
        let token = getCookie("token");
        fetch(process.env.REACT_APP_API_BASE + "/groups", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.json()).then(data => {
            data = data.groups;
            campaignCtx.declareGroups(data);
            const groups = data.filter((group: any) => group.size > 0);
            const sources = {} as any;

            for (const group in groups) {
                if (Object.prototype.hasOwnProperty.call(data, group)) {
                    const source = groups[group]["source"] ? groups[group]["source"] : "other";
                    const source_type = groups[group]["source_type"] ? groups[group]["source_type"] : "groups";
                    if (!(source in sources)) {
                        sources[source] = {};
                    }
                    if (!(source_type in sources[source])) {
                        sources[source][source_type] = [];
                    }
                    sources[source][source_type].push(groups[group]);
                }
            }
            console.log("Groups: ", data)
            console.log("Groups: ", groupsData)
            setGroupsData(sources);
        })
    }, [])

    return (
        <div className='flex flex-col bg-white sectionShadow px-16 py-8 gap-4 border border-newSilver'>
            <div className="">
                <div className="flex flex-row justify-between items-center" >
                    <p className="text-lg font-medium text-tamahagane leading-8">Recipients</p>
                </div>
            </div>
            <div className="flex gap-20">
                <div className="flex  w-full gap-8">
                    <div className="flex flex-col w-4/12 xl:inputWidth">
                        <p className="text-base text-gunMetal font-semibold mb-2">Send to</p>
                        <Dropdown_stacked placeholder="Choose recipients" items={groups} listType={"include"} groupsData={groupsData}></Dropdown_stacked >
                        <div className="flex mt-8 justify-left items-center opacity-50"
                        >

                            <input disabled={true} type="checkbox" className="rounded border-gray text-birchwood focus:ring-tamahagane col-span-1 self-center w-6 h-6 mr-2 cursor-not-allowed"
                                data-tooltip-id={"smartSendingComingSoon"}
                                data-tooltip-content="This feature is coming soon"
                                data-tooltip-place="bottom"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2 text-birchwood">
                                <path fillRule="evenodd" d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                            </svg>
                            <p className="text-base font-semibold leading-none">Smart sending</p>
                        </div>
                        <Tooltip id="smartSendingComingSoon" />
                    </div>
                    <div className="flex flex-col gap-2 w-4/12 xl:inputWidth">
                        <p className="text-base text-gunMetal font-semibold">Don't send to</p>
                        <Dropdown_stacked placeholder="Exclude recipients" items={groups} listType={"exclude"} groupsData={groupsData}></Dropdown_stacked >
                    </div>
                </div>

            </div>

            <div className="border border-iron border-x-0 border-b-0 mt-4 mb-6">{/* Divider */}</div>

            <div>
                <div>
                    <div>
                        <div>
                            <p className="text-base text-gunMetal font-semibold mb-6">Batch division</p>
                            <div className="flex items-center"
                                data-tooltip-id={(Object.keys(campaignCtx.groupsIds).length !== 1) ? "batch-tooltip" : ""}
                                data-tooltip-content="Ensure that you have selected exactly one group."
                                data-tooltip-place="bottom"
                            >
                                <input
                                    type="checkbox"
                                    id="myCheckbox"
                                    checked={isBatchChecked}
                                    onChange={handleBatchCheckboxChange}
                                    disabled={(Object.keys(campaignCtx.groupsIds).length !== 1)}
                                    className={classNames(
                                        "form-checkbox h-6 w-6 rounded-md border-gray text-birchwood focus:ring-tamahagane transition duration-150 ease-in-out mr-2 cursor-pointer",
                                        Object.keys(campaignCtx.groupsIds).length !== 1 && "cursor-not-allowed"
                                    )}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 rotate-90 mr-4 text-birchwood">
                                    <path d="M15 3.75H9v16.5h6V3.75zM16.5 20.25h3.375c1.035 0 1.875-.84 1.875-1.875V5.625c0-1.036-.84-1.875-1.875-1.875H16.5v16.5zM4.125 3.75H7.5v16.5H4.125a1.875 1.875 0 01-1.875-1.875V5.625c0-1.036.84-1.875 1.875-1.875z" />
                                </svg>

                                <div className={
                                    classNames(
                                        "flex flex-1 space-x-3",
                                        (Object.keys(campaignCtx.groupsIds).length > 1 || !isBatchChecked) && "cursor-not-allowed opacity-50 "
                                    )
                                }
                                >
                                    <div className="w-64">
                                    {(() => {
                                        const len = (campaignCtx.totalSize < 2)? 1 : Math.min(campaignCtx.totalSize -1, 9);
                                        const sizes = Array(len).fill(0).map((_, i) => i+2)
                                        const items = sizes.map(x => ({ text: String(x), key: x }))
                                        return <Dropdown
                                            checkbox={true}
                                            disabled={Object.keys(campaignCtx.groupsIds).length !== 1 || !isBatchChecked}
                                            selected={String(campaignCtx.batchCount)}
                                            setSelection={(value: any) => campaignCtx.updateBatchCount(value.key)}
                                            placeholder="Batch count"
                                            items={items} />
                                    })()}
                                    </div>
                                    <div className="w-64">
                                    {(() => {
                                        const values = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
                                        const items = values.map(x => ({ text: String(x), key: x }))
                                        return <Dropdown
                                            checkbox={true}
                                            disabled={Object.keys(campaignCtx.groupsIds).length !== 1 || !isBatchChecked}
                                            selected={String(campaignCtx.batchDelay)}
                                            setSelection={(value: any) => campaignCtx.updateBatchDelay(value.key)}
                                            placeholder=""
                                            items={items} />
                                    })()}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {Object.keys(campaignCtx.groupsIds).length !== 1 && <Tooltip id="batch-tooltip" />}
                    <p className="mt-6">Ensure a smooth user experience and control your traffic by dividing your target audience into smaller batches.</p>
                </div>
            </div>
        </div>
    )

}

export default FlexSendToBarBeta;